import React, {Component} from 'react';
import css_classes from "../inner.module.css"

class PSection3 extends Component {
    render() {
        return (
            <div className={css_classes.section_3}>
                <div className={'am_container'}>
                    <div className={css_classes.section_3_title}>Welcome to the ‘greatest’ science & music festival in the world!</div>
                    <div className={'flex '+css_classes.section_3_inner}>
                        <div className={css_classes.section_3_left}>
                            <p className={css_classes.section_3_p}>
                                Dear starmus friends, </p>
                            <p className={css_classes.section_3_p}>Since its inception, starmus has brought together the
                            world’s greatest scientific and artistic minds, aiming
                            to inspire and educatethe next generation of
                            explorers. </p>
                            <p className={css_classes.section_3_p}>Until now, we have interrogated the
                            mysteries of the universe, looking outwards into
                            Space. </p><p className={css_classes.section_3_p}>Now in Bratislava, starmus looks inwards,
                            focusing our gaze on possibly the most amazing
                            planet in the universe.</p><p className={css_classes.section_3_p}> as living beings, we are part of
                            the cosmos. The very atoms in our bodies were forged
                            in the birthing of the universe and in the deaths of
                            stars. Now we and our parent planet face a critical
                            moment, our fate intimately linked to our own
                            activities. Starmus vii will take a timely look at the
                            present state of the biosphere, and will examine the
                            future prospects of our beautiful 'Blue Dot'.</p>
                            <div className={css_classes.color_blue+' '+css_classes.section_3_left_bottom}>Welcome to STARMUS EARTH!</div>
                        </div>
                        <div className={css_classes.section_3_right}>
                            <div className={css_classes.color_orange}> BRIAN MAY & GARIK ISRAELIAN</div>
                            <div className={css_classes.color_grey}> STARMUS CO-FOUNDERS</div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default PSection3;