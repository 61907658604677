import React, {Component} from "react";
import LocationMainBanner from "./components/LocationMainBanner";
import AboutArmenia from "./components/AboutArmenia";
import HotelsArmenia from "./components/HotelsArmenia";
import FooterSlider from "./components/FooterSlider";
import css_classes from "../WhyArmenia/style.module.css";
import img1 from "../WhyArmenia/images/image_1.jpg";
import img2 from "../WhyArmenia/images/image_2.png";
import TestimonialItem from "../components/TestimonialItem";
import Configs from "../configs";

export default class LocationPage extends Component{
  constructor(props) {
    super();
    this.state = {about_armenia_data:[]}
  }

  componentDidMount() {
    this.getData();
  }
  getData = (category_id=3)=> {
    fetch(`${Configs.api_url}data/get-testimonials?category_id=`+8)
        .then(response => response.json())
        .then(data => {
              this.setState({
                about_armenia_data: data.data,
              });
            }
        );
  }
  render() {
    let content = [];
    for (let item_info of this.state.about_armenia_data) {
      content.push(<TestimonialItem info={item_info}/>)
    }
    return <React.Fragment>
      <div className={"am_wrapper page_main"}>
        {/*<WaitForLoading/>*/}

        <LocationMainBanner/>
        <AboutArmenia/>
        <HotelsArmenia/>
        <FooterSlider/>
        <div className={'section_60 section_light_blue '+css_classes.info_section}>
          <div className={'why_armenia_text '+css_classes.section_text_left}>
            <p>The sixth Starmus, one of the world-scale science and art festivals will take place in
              September 2022 in Yerevan, Armenia, to celebrate science communication with world- class
              scientists, artists and astronauts Every nation, big or small, can contribute to
              establishing peace on the Earth and beyond it.</p>
            <p>One of the most ancient cultures in the world, Armenia has always focused on creating and
              constructing, despite innumerable challenges.</p>
          </div>
          <div className={css_classes.section_img_right}>
            <img src={img1}/>
          </div>
        </div>
        <div className={'section_60 '+css_classes.info_section}>
          <div className={css_classes.section_img_left}>
            <img src={img2}/>
          </div>
          <div className={'why_armenia_text '+css_classes.section_text_right}>
            <p>Armenia: building a peaceful future through complicated times.</p>

            <p>Armenia: the cradle of civilisation striving for peace. Civilisation is based on peace,
              science and arts.</p>

            <p>Starmus is going to Armenia to celebrate the 50th anniversary of MARS 3 and MARINER 9
              missions and to promote the idea of peaceful cooperation on Earth and in Space.</p>

            <p>Building a peaceful and sustainable civilisation requires the effort of all nations, big
              or small, and Armenia is a perfect example of an ancient culture which has endured many
              hardships maintaining its values, creativity and longing for peace and progress.</p>

            <p>Civilisation is peace.</p>
            <p>Welcome to one of the most ancient civilisations on Earth!</p>
          </div>
        </div>
        <div className={css_classes.links_block}>

          <p className={css_classes.links_block_p}><a target={'_blank'} href={'https://www.economist.com/leaders/2018/12/22/the-economists-country-of-the-year-2018'} className={css_classes.links_block_a}>The Economist’s country of the year 2018</a></p>
          <p className={css_classes.links_block_p}><a target={'_blank'} href={'https://www.forbes.com/sites/wadeshepard/2020/01/31/welcome-to-the-worlds-next-tech-hub-armenia/#13ae83a44192'} className={css_classes.links_block_a}>Welcome To The World’s Next Tech Hub: Armenia</a></p>
          <p className={css_classes.links_block_p}><a target={'_blank'} href={'https://www.nationalgeographic.com/travel/features/ten-places-that-deserve-more-travelers/'} className={css_classes.links_block_a}>Ten Places That Deserve More Travelers</a></p>
          <p className={css_classes.links_block_p}><a target={'_blank'} href={'https://armenpress.am/eng/news/1003758.html'} className={css_classes.links_block_a}>Yerevan included in National Geographic 2020 Cool List for travel</a></p>
        </div>
        <div className={'section_60 am_container text_center'}>

          <div className={'row flex_wrap '+css_classes.data_list}>
            {content}
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}
