import React, {Component} from 'react';
import '../css/about_starmus.css'
import ScrollDown from "../../components/Scroll/ScrollDown";
import StarmusLogo from '../../assets/images/starmus-logo.png';


class AboutStarmus extends Component {
  render() {
    return (
      <section className="section parallax about-starmus-section">
        <div className='container'>
          <div className='about-starmus-block starmus-block'>
            <div className='about-starmus-container'>

              <h1 className="starmus-about-h1 starmus-h1">
                What is Starmus ?
              </h1>
              <div className='starmus-block-text'>
                <div className='about-starmus-text-block'>
                  <p className={'starmus-about-p starmus-p'}>
                    Starmus is a global festival of science communication and art that brings
                    together the most brilliant minds on the planet
                  </p>
                </div>
                <div className='about-starmus-text-block'>
                  <p className={'starmus-about-p starmus-p'}>
                    Our aim is to inspire and educate the next generation of explorers and
                    regenerate the spirit of discovery
                  </p>
                </div>
                <div className='about-starmus-text-block'>
                  <p className={'starmus-about-p starmus-p'}>
                    Starmus combines art, music and science to enhance the science communication
                  </p>
                </div>
                <div className='about-starmus-text-block'>
                  <p className={'starmus-about-p starmus-p'}>
                    We want to engage humanity in the biggest questions of our time

                  </p>
                </div>
              </div>
            </div>
          </div>
          <ScrollDown to='mars-video-section' containerId="main"/>
        </div>
      </section>
    )
  }
};

export default AboutStarmus;