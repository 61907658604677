import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import program_css from "../program.module.css";
import inner_css from "../inner.module.css";
import welcome_img from "../images/welcom_img.jpg"

class Welcome extends Component {
    render() {
        return (
            <div>
                <div className={css_classes.welcome_bg}>
                    <div className={css_classes.welcome_inner}>
                        <div className={program_css.title_bar}>
                            <div className={inner_css.ripa_container + ' ' + program_css.title_bar_inner}>
                                <div className={program_css.title_bar_big}> STARMUS VII OPENING CONCERT</div>
                                <div className={program_css.title_bar_small}>
                                    20:45
                                </div>
                            </div>
                        </div>
                        <div className={css_classes.welcome_content}>
                            <div className={inner_css.ripa_container+' '+inner_css.welcome_content_inner}>
                                <div className={css_classes.welcome_text} >
                                    "Bridge from the Future" featuring Jean-Michel Jarre
                                    Special Guest appearance
                                    by Brian May
                                </div>
                                <div className={css_classes.welcome_text2}>
                                    *STARMUS ticket holders will have a privileged access to the concert
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className={program_css.camp_main}>
                    <div className={program_css.title_bar}>
                        <div className={inner_css.ripa_container + ' ' + program_css.title_bar_inner}>
                            <div className={program_css.title_bar_big}> STARMUS CAMP</div>
                            <div className={program_css.title_bar_small}>
                                Main Square, Bratislava
                            </div>
                        </div>
                    </div>
                    <div className={program_css.title_bar}>
                        <div className={inner_css.ripa_container + ' ' + program_css.title_bar_inner}>
                            <div>
                                <div className={program_css.welcome_event}><span className={program_css.welcome_event_time}>17:00-20:00</span>STARMUS Film Screening</div>
                                <div className={program_css.welcome_event}><span className={program_css.welcome_event_time}>20:00-22:00</span>Concert Live Feed to the Main Square Screen</div>
                            </div>

                        </div>
                    </div>
                    <div className={program_css.lent}></div>
                </div>
            </div>

        );
    }
}

export default Welcome;
