import React, {Component} from "react";
import css_classes from '../style.module.css';
class Section2 extends Component{
    constructor(props) {
        super();
        this.name=props.name
        this.data= props.data
    }
    render(props){
        return <div className={css_classes.section2}>
            <div className={'am_container'}>
                <div className={" row "+css_classes.section2_container}>
                    <div className={'col6 '+css_classes.section2_left}></div>
                    <div className={'col6 '+css_classes.section2_content}>
                        <div className={"section_title "+css_classes.section2_title}>{this.data.title}</div>
                        <div className={css_classes.section2_text} dangerouslySetInnerHTML={{__html:this.data.content}}></div>
                    </div>
                </div>
            </div>

        </div>;
    }
}
export default Section2;