import React from 'react'
import Carousel from "react-multi-carousel";
import {LeftArrow, RightArrow} from "../../components/Arrows/StarmusArrows";
import ScrollDown from "../../components/Scroll/ScrollDown";
import "react-multi-carousel/lib/styles.css";
import '../css/races_to_mars.css'


const Race = ({name, owner, launchDate, orbiatalInsertion,landingDate, cost, type, first}) => {
  let orbital_insertion='';
  if(orbiatalInsertion){
    orbital_insertion = <li className='race-to-marc-component-item'>
      <div className='race-attribute-container'>
        <p className='race-attribute starmus-p'>Orbital Insertion:</p>
        <div className='empty-space'/>
        <p className='race-attribute starmus-p'><strong>{orbiatalInsertion}</strong></p>
      </div>
    </li>
  }
  let landing_date='';
  if(landingDate){
    landing_date = <li className='race-to-marc-component-item'>
      <div className='race-attribute-container'>
        <p className='race-attribute starmus-p'>Landing date:</p>
        <div className='empty-space'/>
        <p className='race-attribute starmus-p'><strong>{landingDate}</strong></p>
      </div>
    </li>
  }

  return (
    <ul className='race-to-marc-component'>
      <li className='race-to-marc-component-item'>
        <p className='race-name starmus-p'>{name}</p>
      </li>
      <li className='race-to-marc-component-item'>
        <div className='race-attribute-container'>
          <p className='race-attribute starmus-p'>Owner: </p>
          <div className='empty-space'/>
          <p className='race-attribute starmus-p'><strong>{owner}</strong></p>
        </div>
      </li>
      <li className='race-to-marc-component-item'>
        <div className='race-attribute-container'>
          <p className='race-attribute starmus-p'>Launch date: </p>
          <div className='empty-space'/>
          <p className='race-attribute starmus-p'><strong>{launchDate}</strong></p>
        </div>
      </li>
      {orbital_insertion}
      {landing_date}
      <li className='race-to-marc-component-item'>
        <div className='race-attribute-container'>
          <p className='race-attribute starmus-p'>Type: </p>
          <div className='empty-space'/>
          <p className='race-attribute starmus-p'>
            <strong>{type}
            </strong>
          </p>
        </div>
      </li>
      <li className='race-to-marc-component-item'>
        <div className='race-attribute-container'>
          <p className='race-attribute starmus-p'>The first: </p>
          <div className='empty-space'/>
          <p className='race-attribute starmus-p'>
            <strong>{first}</strong>
          </p>
        </div>
      </li>
    </ul>
  )
};

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  }
};


class RacesToMars extends React.Component {
  render() {
    return (
      <section className='races-to-mars-block-section'>
        <div className='container'>
          <div className='races-to-mars-block starmus-block'>
            <div className="carousel-block">
              <div className='container'>
                <div className='mars-awakens-header'>
                  <div className="race-to-mars-text">
                    <h1 className="starmus-h1">Race to MARS</h1>
                  </div>
                  <div className="carousel-buttons">
                    <LeftArrow onClick={() => {
                      this.Carousel.previous()
                    }}/>
                    <div className='empty-space'/>
                    <RightArrow onClick={() => {
                      this.Carousel.next()
                    }}/>
                  </div>
                </div>
              </div>
              <Carousel
                ref={(el) => (this.Carousel = el)}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                keyBoardControl={true}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                itemClass="carousel-item-padding-40-px"
                arrows={false}
                renderButtonGroupOutside={true}
              >
                <Race name="Mars 2020"
                      owner="NASA"
                      launchDate="30 July 2020"
                      landingDate=" 18  February, 2021"
                      cost="$2.7 billion"
                      type="Rover and helicopter"
                      first="Heavier-than-air flight on another planet"/>
                <Race name="Tianwen-1"
                      owner="China National Space Administration"
                      launchDate="22 July 2020"
                      orbiatalInsertion='10 February, 2021'
                      cost="Unknown"
                      type="Orbiter and rover"
                      first="Chinese mission to Mars"/>
                <Race name="ExoMars"
                      owner="ESA and Roscosmos"
                      launchDate=""
                      cost="$1.45 billion"
                      type="Rover"
                      first="Two-meter drill into the surface"/>
                <Race name="Hope Mars Mission"
                      owner="UAE Space Agency"
                      launchDate="19 July 2020"
                      orbiatalInsertion='9 February, 2021'
                      cost="$200 million. UAE has spent $5.4 billion so far on its space program."
                      type="Orbiter"
                      first="Space exploration mission by the UAE"/>
              </Carousel>
            </div>
            <ScrollDown to='life-on-mars-section' containerId="main"/>
          </div>
        </div>
      </section>
    )
  }
};

export default RacesToMars