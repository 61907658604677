import React, {Component} from "react";
import css_classes from '../style.module.css';
import {Switcher} from "../../components/Switcher/Switcher";
import Figure from "../../components/Figure";
import Configs from "../../configs";
import TemplateVideo from "../../Festival/Components/TemplateVideo";

class Section6 extends Component {


    constructor(props) {
        super();
        this.state = {switcher_buttons: [], winners_list: []}
    }

    componentDidMount() {
        this.getData();
    }

    getData = (festival_id = 0) => {
        fetch(`${Configs.api_url}data/get-persons?type=winner&festival_id=` + festival_id)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        switcher_buttons: data.data.festivals,
                        winners_list: data.data.persons,
                        video: data.data.video
                    });
                }
            );
    }
    switchYear = (festival_id) => {
        this.getData(festival_id);
    }

    render(props) {
        let winners_list = [];
        for (let winner of this.state.winners_list) {
            winners_list.push(<Figure info={winner}/>)
        }
        let switcher = '';
        if (this.state.switcher_buttons.length) {
            switcher = <Switcher keys={this.state.switcher_buttons} switcherFunction={this.switchYear}></Switcher>
        }
        let video = '';
        if (this.state.video) {
            let content = {text: this.state.video}
            video = <div key={this.state.video} className={css_classes.medal_video_wrap}><TemplateVideo
                content={content}></TemplateVideo></div>;
        }
        return <div className={css_classes.section5}>

            <div className="section_title black text_center">STEPHEN HAWKING MEDAL WINNERS</div>
            {switcher}
            {video}
            <div className={"am_container  " + css_classes.section6}>
                <div className={"row " + css_classes.winners_list}>
                    {winners_list}
                </div>
            </div>
        </div>;
    }
}

export default Section6;