import styles from "../style.module.css";
import React from 'react';

export default function Section2() {
    const sections=[
        {
            img:require("../images/s_2_1.jpg"),
            video:require("../images/s_2_1.mp4"),
            text:"Burning fossil fuels, cutting down forests and farming livestock are increasingly influencing the climate and the earth's temperature."
        },
        {
            img:require("../images/s_2_2.jpg"),
            text:"Climate scientists overwhelmingly agree that human activities are the primary cause of climate change."
        },
        {
            img:require("../images/s_2_3.jpg"),
            video:require("../images/s_2_3.mp4"),
            text:"Scientists generally regard the later part of the 19th century as the point at which human activity started influencing the climate."
        },
        {
            img:require("../images/s_2_4.jpg"),
            text:"More than 1 million species are at risk of extinction by climate change. This is caused mostly by human activity."
        }
    ];
    const content = sections.map((item,index) => {
        let bg =<img className={styles.section_2_col_bg} src={item.img}/>;
        if(item.video){
            bg = <video className={styles.section_2_col_bg} muted autoPlay loop poster={item.img}>
                  <source src={item.video} type="video/mp4" />
            </video>
        }
        return <div className={styles.section_2_col}  key={'s7_s_2_'+index} >
            {bg}
            <div className={styles.section_2_col_text}>
                <div>{item.text}</div>
            </div>
        </div>
    })
    return (
            <div className={styles.section_2}>
                {content}
            </div>
    );
}