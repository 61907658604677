import React, {Component,useEffect} from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import PageNotFound from "./NotFound";

class Content extends Component {
    constructor(props) {
        super(props);
        this.updateMenuClass = props.updateMenuClass;
    }
    static propTypes = {
        location: PropTypes.object.isRequired,
    }
    componentDidMount() {
        console.log(this.props.location,'aaa')
    }

    componentDidUpdate(prevProps) {
        console.log(this.props.location,'aaa')
       /* if (this.props.location !== prevProps.location) {
            let location =this.props.location.pathname;
            if(['/'].includes(location)){
                this.updateMenuClass('dark new_menu');
            }else{
                this.updateMenuClass('dark');
            }
        }*/
    }

    render() {
        if(this.props.location.key ) {
            return <div className={'main_content'}>{this.props.children}</div>;
        }
        try{
            let path = this.props.location.pathname.split('/')[1];
            if(['','/','p','about-us','stephen-hawking-medal','festival','speakers','testimonials','testimonials-item','press','artists',
            'person','press-item','contacts','media-coverage','terms-of-use','media-accreditation','privacy-policy'].includes(path)){
                return <div className={'main_content'}>{this.props.children}</div>;
            }

            return (
                <div className={'main_content'}><PageNotFound/></div>
            );
        }catch (e){
            return <div className={'main_content'}></div>;
        }

    }

    onRouteChanged() {

    }

}

const ContentWithRouter = withRouter(Content);
export {Content,ContentWithRouter}