import React,{Component} from "react";
import css_classes from '../style.module.css';
import FaceBookIcon from "../images/soc_fb.png";
import TwitterIcon from "../images/soc_x.png";
import InstagramIcon from "../images/soc_insta.png";
import YouTubeIcon from "../images/soc_yt.png";
import TiktokIcon from "../images/soc_tiktok.png";

export  class Follow extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }
    render() {
        return <div className={css_classes.follow_wrap}>
            <div className={'am_container'}>
                <div className={css_classes.img_as_bg_wrap}>
                    <img src={this.content.image} alt={'s'}/>
                    <div className={css_classes.img_as_bg_info+' '+css_classes.follow_inner}>
                        <div className={css_classes.soc_links_wrap}>
                            {this.content.title}
                            <div className={css_classes.soc_icons}>
                                <a href={"https://www.facebook.com/StarmusFestival"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={FaceBookIcon} className={css_classes.soc_icon} alt='Facebook'/>
                                </a>
                                <a href={"https://twitter.com/starmusfestival"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={TwitterIcon} className={css_classes.soc_icon} alt='Twitter'/>
                                </a>
                                <a href={"https://www.instagram.com/starmus/"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={InstagramIcon} className={css_classes.soc_icon} alt='Instagram'/>
                                </a>
                                <a href={"https://www.youtube.com/user/starmusfest"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={YouTubeIcon} className={css_classes.soc_icon} alt='YouTube'/>
                                </a>
                                <a href={"https://www.tiktok.com/@starmusfestival?_t=8lAfo1uRj3S&_r=1"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={TiktokIcon} className={css_classes.soc_icon} alt='Tiktok'/>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}