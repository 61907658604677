import React, {Component} from 'react';
import styles from "../style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
import s3_1 from "../images/s3_1.png";
import s3_2 from "../images/s3_2.png";
import s3_3 from "../images/s3_3.png";
import Carousel from "react-multi-carousel";
import Slider from "react-slick";

class S8Section3 extends Component {
    render() {
        var settings = {
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            appendDots: dots => (
                <ul style={{margin: "0px"}}> {dots} </ul>
            ),
            responsive: [

                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className={styles.section}>
                <div className={'am_container flex flex_column_992'}>

                    <div className={styles.img_section + ' ' + styles.s3_img_section}>
                        <div className={"slider-container am_slick s8_section_3 "+styles.s3_img_slider}>
                            <Slider {...settings}>
                                <img className={styles.s3_image} src={s3_1} alt={"s3_1"}/>
                                <img className={styles.s3_image} src={s3_2} alt={"s3_2"}/>
                                <img className={styles.s3_image} src={s3_3} alt={"s3_3"}/>
                            </Slider>
                        </div>
                        <div className={styles.s3_image_wrap}><img className={styles.s3_image} src={s3_1} alt={"s3_1"}/></div>
                        <div className={styles.s3_image_wrap}><img className={styles.s3_image} src={s3_2} alt={"s3_2"}/></div>
                        <div className={styles.s3_image_wrap}><img className={styles.s3_image} src={s3_3} alt={"s3_3"}/></div>
                    </div>
                    <div className={styles.text_section}>
                        <div className={styles.title_45 + ' color_orange'}> ABOUT STARMUS</div>
                        <p className={styles.p + ' ' + styles.s3_firts_p}></p>
                        <p className={styles.p + ' ' + styles.s3_firts_p}>Created by Garik Israelian, PhD in
                            astrophysics, and legendary Queen guitarist and PhD in astrophysics, Brian May,Starmus is
                            considered the most ambitious science and music festival in the world.</p>
                        <p className={styles.p}>Starmus main mission is to bring science, space and the stars closer to
                            the general public by inspiring the next generation of scientists and explorers.</p>
                        <p className={styles.p}>Founded in 2011 and after 7 epic editions in different parts of the
                            world, Starmus is the only global event that manages to bring together under the same roof a
                            large number of Nobel laureates; the great heroes and visionaries of the space race - such
                            as the Apollo astronauts - and world-class scientists, engineers and researchers to deliver
                            the most inspiring debates on the great questions of our time.</p>
                        <p className={styles.p}>With music as its other great cornerstone, Starmus offers in each
                            edition exclusive performances by world-renowned artists. Thus, Brian May, Jean-Michel
                            Jarre, Hans Zimmer, The Offspring, Steve Vai or Rick Wakeman are some of the headliners that
                            Starmus has counted with in different editions. Not only with exclusive performances but
                            with unique collaborations that are marking the musical history of our time as Brian May
                            sharing the stage, for the first time, with Jean-Michel Jarre or The Offspring.</p>
                        <p className={styles.p + ' ' + styles.s3_blue}>STARMUS,‘home’ of the Stephen Hawking Medal for
                            Science Communication</p>
                        <p className={styles.p}>Stephen Hawking and Alexei Leonov, along with STARMUS co-founders Brian
                            May and Garik Israelian, worked to create the Stephen Hawking Medal for Science
                            Communication in 2015, awarding individuals and teams who have made significant
                            contributions to science communication.</p>


                    </div>
                </div>
            </div>
        );
    }
}

export default S8Section3;