import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import {Link} from "react-router-dom";
import Configs from "../../configs";

class InnerHeader extends Component {
    constructor(props) {
        super();
    }
    render() {
        return (
            <div className={'header_section ' + css_classes.header}>
                <a target={'_blank'} href={Configs.esel_url} className={css_classes.eset_link}>Eset link</a>
            </div>
        );
    }
}

export default InnerHeader;