import React, {Component} from "react";
import css_classes from "./style.module.css";

export class ImgGrid extends Component{
    constructor(props) {
        super();
        this.images = props.images
    }
    render(){
        let images = this.images;
        let grid_item_id = 0;
        let grid_items = images.map((image)=>{
            grid_item_id++;
            return <div className={css_classes['section5_grid_item_'+grid_item_id]}><img className={ css_classes.grid_item_img} src={image.url}/></div>
        });

        return <div className={'am_container  section_60'}>
            <div className={css_classes.section5_grid}>
                {grid_items}
            </div>
        </div>;
    }
}