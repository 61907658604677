import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class Template2ColumnText extends Component{
    constructor(props) {
        super();
        this.content = props.content
    }
    render() {
        let title='';
        if(this.content.title){
            title =<div className={"section_title black text_center "+ css_classes.template_text_title}>{this.content.title}</div>
        }
        return <div className={'am_container section_60 '}>
            {title}
            <div className={"section_content two_column_text"} dangerouslySetInnerHTML={{__html:this.content.text}}>

            </div>
        </div>
    }
}