import React from "react";
import css_classes from "./style.module.css";
import {Link} from "react-router-dom";
import default_image from "./images/default_image.jpg"

const PressItem = (props) => {
    let data = props.data;
    return (<React.Fragment>
        <Link to={'/press-item/' + data.id} className={"col4 "+css_classes.item_wrap}>
            <div className={css_classes.item}>
                <div className={css_classes.image_wrap}>
                    <img src={data.image?data.image:default_image} className={css_classes.image}/>
                </div>
                <div className={css_classes.title_wrap}>
                    <div className={css_classes.title}>{data.title}</div>
                    <div className={css_classes.date}>{data.date}</div>
                </div>
            </div>
        </Link>
    </React.Fragment>)
}
export default PressItem;