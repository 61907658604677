import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import program_css from "../program.module.css";
import inner_css from "../inner.module.css";
import {Link} from "react-router-dom";

class ProgramItem extends Component {
    constructor(props) {
        super();
        this.program = props.program;
        this.info = JSON.parse(props.program.info);
        this.desc = this.program.desc.split('|');
        this.title=this.info.title;
    }
    drawRow(data,index){
        let mainCLass = css_classes.pItemGroupRow;
        if(index ==0){
            mainCLass = css_classes.pItemGroupTitle;
        }
        let extraClass,text='';
        if(data.hasOwnProperty('className')){
            extraClass=css_classes[data.className]
            text = data.text;
        }else{
            text=data;
        }
        if(data.hasOwnProperty('slug')){
            return <Link to={'/person/'+data.slug} target={'_blank'}  className={mainCLass+' '+extraClass}>{text}</Link>;
        }else {
            return <div key={'k2'+index} className={mainCLass+' '+extraClass}>{text}</div>;
        }
    }

    render() {
        return (
            <div className={css_classes.programInfo}>
                {/*<div className={css_classes.ripa_container+' ' + css_classes.programInfoTitle}>{this.info.title.title_1 || this.desc[0]}</div>*/}
                <div className={css_classes.programDayBlue}>{this.info.title.title_1 || this.program.label}</div>
                <div className={program_css.title_bar}>
                    <div className={inner_css.ripa_container + ' ' + program_css.title_bar_inner}>
                        <div className={program_css.title_bar_big}> STARMUS MAIN LECTURES</div>
                        <div className={program_css.title_bar_small}>
                            <div className={css_classes.pItemTitleTime}>{this.title.time}</div>
                            <div className={css_classes.pItemTitlePlase}>{this.title.place}</div>
                        </div>
                    </div>
                </div>
                <div>



                </div>
                <div className={css_classes.ripa_container+' ' + css_classes.pItemList}>
                    {this.info.groups.map((group,index)=>{
                        return <div key={'k1_'+index}className={css_classes.pItemListItem}>
                            {group.map((row,index)=>{
                                return this.drawRow(row, index);
                            })}

                        </div>
                    })

                    }
                </div>
                {this.info.extra && <div className={css_classes.ripa_container+' '+css_classes.pItemExtra}>
                    <div className={css_classes.pItemExtraRow1}>{this.info.extra.row_1}</div>
                    <div className={css_classes.color_orange}>{this.info.extra.row_2}</div>
                    <div className={css_classes.pItemExtraRow3}>{this.info.extra.row_3}</div>
                    <div className={css_classes.color_grey}>{this.info.extra.row_4}</div>
                </div>}

            </div>
        );
    }
}

export default ProgramItem;
