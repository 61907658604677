import React, {Component} from 'react'
import ScrollDown from "../../components/Scroll/ScrollDown";
import '../css/mars3.css'


class Mars3 extends Component {
  render() {
    return (
      <section className="mars3-section">
        <div className='container'>
          <div className="mars3-block starmus-block">
            <div className="mars3-block-text">
              <h1 className="mars3-title starmus-h4">
                Mars 3 - The first lander on Mars
              </h1>
              <div className='mars3-text'>
                <p className='mars3-p starmus-p'>
                  Mars 3 (USSR) was a robotic space probe, the first to attain a soft
                  landing on Mars, on December 2 1971. It failed 20 seconds after landing, having transmitted only a partial
                  panoramic image with no details due to the dust storm.
                </p>
                <p className='mars3-p starmus-p'>
                  The lander carried a small walking robot called PROP-M.
                </p>
              </div>
            </div>
            <ScrollDown to='mariner9-section' containerId="main"/>
          </div>
        </div>
      </section>
    )
  }
}

export default Mars3