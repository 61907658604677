import styles from "../style.module.css";
import PreRegForm from "./preRegForm"
import React from 'react';
import {Partners} from "../../StarmusArmenia/Partners";
import bg from "../images/tickets.jpg";
import bg_small from "../images/tickets_800.jpg";
import Configs   from "../../configs";
// import bg2 from "./images/section_1.jpg";

export default function Tickets7(props) {
    const buyTicketClick=()=>{
        window.gtag('event', 'conversion', {'send_to': 'AW-16507181171/5AFsCMXYw54ZEPOwnr89'});
    }
    return (
        <>
            <div className={styles.tickets_section}>
                <img src={bg} alt={'tickets'} className={styles.tickets_img_large}/>
                <img src={bg_small} alt={'tickets'} className={styles.tickets_img_small}/>
                <a onClick={buyTicketClick} target={'_blank'} href={'https://www.ticketportal.sk/event/STARMUS-VII?idpartner=1017&lang=US'} className={styles.b_button}>Book your ticket now!!</a>
                <a target={'_blank'} href={Configs.esel_url} className={styles.eset_link}>Eset link</a>
            </div>

        </>
    );
}