import React, {Component} from 'react';
import "video-react/dist/video-react.css"
import newVideo from '../../assets/videos/new_video.mp4';
// import shortVideo from '../../assets/videos/Starmus_short_video.mp4';
import '../css/mars_video.css'
import ScrollDown from "../../components/Scroll/ScrollDown";
import {VideoModal, VideoPlayButton, VideoBg} from "../../components/VideoBg";

class MarsVideo extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.playVideo = this.playVideo.bind(this);
  }

  playVideo = () => {
    this.videoRef.current.showModal();
  };

  render() {
    return (
      <section className='mars-video-section'>
        <div className='container'>
          <VideoModal ref={this.videoRef} videoSrc={newVideo}/>
          <div className='mars_video_block'>
            <VideoBg videoSrc={newVideo}/>
            <div className="mars_video_block_text">
              <h1 className='mars_video_h1 starmus-h2'>FROM STARMUS TO MARS</h1>
              <p className='mars_video_block_description starmus-p'>The sixth Starmus will be dedicated to Mars</p>
              <VideoPlayButton playClick={this.playVideo}/>
            </div>
          </div>
          <ScrollDown to='mars-facts-section' containerId="main"/>
        </div>
      </section>
    )
  }
};

export default MarsVideo;