import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"

export class TestimonialsDetails extends Component {

    constructor(props) {
        super();
        this.state={
            other_posts:[],
            files:[],
            data:{}
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            document.getElementById('main').scrollTo(0,0);
            const id = nextProps.match.params.id;
            this.getData(id);
        }
    }
    async componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        const {match: {params: {id}}} = this.props;
        this.getData(id);
    }
    getData = (id)=> {

        fetch(`${Configs.api_url}data/get-press-details?id=${id}`)
            .then(response => response.json())
            .then(data => {
                        this.setState({
                            data:data.data.model,
                            other_posts: data.data.other_posts,
                            files: data.data.files
                        });

                }
            );
    }
    render() {
        let random_min =1;
        let random_max = 3;
        let random_bg = Math.floor(Math.random() * (random_max - random_min + 1)) + random_min;

        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={"header_section "+css_classes['random_section_'+random_bg]}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.section_1_title}>{this.state.data.date}</div>
                    </div>
                </div>
                <div className={'section_60'}>
                    <div className={'am_container'}>
                        <div className={'section_title black text_center '}>{this.state.data.title}</div>
                        <div className={"text_1040 text_center post_content"} dangerouslySetInnerHTML={{__html:this.state.data.content}}>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>


    }
}
