import React, {Component} from 'react';

import '../css/main_banner.css';
import ScrollDown from "../../components/Scroll/ScrollDown";
import MainMenu from "../../components/Menu";
import bgVideo from "../../assets/videos/main_banner.mp4";
import Mariner9Img from "../../assets/images/mariner-9.png";
import Mariner9Img750 from "../../assets/images/768/mariner-9.png";
import Mars3Img from "../../assets/images/mars-3.png";
import {Link} from "react-router-dom";

class MainBanner extends Component {
  render() {
    return (
      <section className="main-banner-section">
        <div className='main-banner-container container'>
          <picture>
            <source srcSet={Mariner9Img750} media="(max-width: 768px)"/>
            <img className='mariner-9-img' src={Mariner9Img} alt='Mariner 9'/>
          </picture>
          <picture>
            <source srcSet={Mars3Img} media="(max-width: 768px)"/>
            <img src={Mars3Img} className='mars-3-img' alt='Mars-3'/>
          </picture>
          <video className="main_banner_video" autoPlay={true} loop muted>
            <source src={bgVideo} type="video/mp4"/>
          </video>
          <div className='main-banner-text'>
            <div className="starmus-title">
              <h1 className="starmus-h1">
                Starmus VI
              </h1>
              <h1 className="starmus-h2 festival_slogan_6">
                50 Years on Mars
              </h1>
            </div>

            <div className="starmus-date-location starmus-p">
              <div>SEPTEMBER 5-10, 2022</div>
              <div><span className="starmus-about-highlighted">YEREVAN, ARMENIA</span></div>
              <Link to={'/tickets'} className={'home_buy_ticktes'}>BUY TICKETS</Link>
            </div>
          </div>

          <ScrollDown to='about-starmus-section' containerId="main"/>
        </div>
      </section>
    )
  }
}

export default MainBanner