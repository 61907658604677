import React, {Component} from "react";
import Clock from "./Sections/clock";
import Section1 from "./Sections/section1";
import Section2 from "./Sections/section2";
import Section3 from "./Sections/section3";
import Section5 from "./Sections/section5";
import Section6 from "./Sections/section6";
import Section4 from "./Sections/section4";
import Section7 from "./Sections/section7";
import styles from "./style.module.css";
import {Partners} from "../StarmusArmenia/Partners";
import Section8 from "./Sections/section8";
import Tickets from "./Sections/tickets";
import Tickets7 from "./Sections/tickets";
import {Link} from "react-router-dom";



export class Starmus7 extends Component {
    constructor(props) {
        super(props);
        // props.updateMenuClass('transparent');
    }

    render() {
            return <React.Fragment>
                <div className={"am_wrapper page_main "+styles.content_wrapper}>
                    {/*<Clock/>*/}
                    <Tickets7></Tickets7>
                    <div className={styles.abs_program} >
                        <Link to={'/program-new'} className={styles.programBtn}>Program</Link>
                        <a href={'https://www.ticketportal.sk/event/STARMUS-VII?idpartner=1017&lang=US'} className={styles.buyTicket}>Buy Ticket</a>
                    </div>

                    <Section7 openPopup={this.props.openPopup}/>
                    <Section1 />
                    <Section2 />
                    <Section3 />
                    <Section4 />
                    <Section5 />
                    <Section6 />
                    <Section8 />

                </div>
            </React.Fragment>
    }
}
