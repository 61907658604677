import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {Switcher} from "../components/Switcher/Switcher";
import Figure from "../components/Figure";

export class Speakers extends Component {

    constructor(props) {
        super();
        this.state = {switcher_buttons:[],speakers:[]}

    }

    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        this.getData();
    }
    getData = (festival_id=0)=> {
        fetch(`${Configs.api_url}data/get-persons?type=speaker&festival_id=`+festival_id)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        switcher_buttons: data.data.festivals,
                        speakers: data.data.persons,
                    });
                }
            );
    }
    switchYear = (festival_id) => {
        this.getData(festival_id);
    }
    render() {
        let keys = this.state.switcher_buttons;
        let speakers = [];
        for (let speaker of this.state.speakers) {
            speakers.push(<Figure info={speaker}/>)
        }

        let switcher='';
        if(this.state.switcher_buttons.length){
            switcher=<Switcher keys={keys} switcherFunction={this.switchYear}></Switcher>
        }

        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={css_classes.section_1}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.section_1_title}> STARMUS SPEAKERS</div>
                    </div>
                </div>
                <div className={'section_60 am_container text_center'}>

                    {switcher}
                    <div className={css_classes.speakers_list}>
                        {speakers}
                    </div>
                </div>
            </div>
        </React.Fragment>


    }
}
