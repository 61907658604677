import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import PressItem from "../components/PressItem";
import {Link} from "react-router-dom";

export class Press extends Component {

    constructor(props) {
        super();
        this.state = {
            posts:[],
            category_name:null,
            has_load_more:true,
            current_page:1,
            current_slug:null
        }
        this.loadMore = this.loadMore.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.slug !== this.props.match.params.slug) {

            document.getElementById('main').scrollTo(0,0);
            const slug = nextProps.match.params.slug;
            this.resetData(slug).then(function () {
                this.getData(slug);
            }.bind(this));
        }
    }
    async componentDidMount() {
        const {match: {params: {slug}}} = this.props;

        document.getElementById('main').scrollTo(0,0);
       await this.resetData(slug).then(function () {
            this.getData(slug);
        }.bind(this));
    }
    async resetData(newSlug){
        await this.setState({
            posts:[],
            current_page:1,
            current_slug:newSlug
        })
    }
    getData = ()=> {
        let slug = this.state.current_slug;
        let page  = this.state.current_page;
        fetch(`${Configs.api_url}data/get-press?category=${slug}&sort=date_desc&page=${page}`)
            .then(response => response.json())
            .then(data => {
                    let ready_posts = this.state.posts;
                    if(data.data.posts.length){
                        ready_posts = ready_posts.concat(data.data.posts);
                        this.setState({
                            posts: ready_posts,
                            category_name: data.data.category_name,
                            has_load_more:true
                        });
                    }else{
                        this.setState({
                            has_load_more:false
                        });
                    }

                }
            );
    }
    async loadMore(){
        let page =this.state.current_page+1;
        await this.setState({has_load_more:false,current_page:page});
        this.getData()
    }
    render() {

        let latest_stories_data = this.state.posts.slice(0,2);
        let other_stories_data = this.state.posts.slice(2);
        let more_button = this.state.has_load_more? <div className={css_classes.more_button} onClick={this.loadMore}>Load more</div>:'';
        let latest_stories =  latest_stories_data.map((story)=>{
            return <Link to={'/press-item/' + story.id}  className={"col6"}>
                <div className={css_classes.latest_image_wrap}>
                    <img src={story.image} className={css_classes.latest_image}/>
                </div>
                <div className={css_classes.latest_title_wrap}>
                    <div className={css_classes.latest_title}>{story.title}</div>
                    <div className={css_classes.latest_date}>{story.date}</div>
                </div>
            </Link>
        });
        let other_stories =  other_stories_data.map((story)=>{
            return <PressItem data={story}/>
        });
        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={"header_section "+css_classes.section_1}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.section_1_title}>{this.state.category_name}</div>
                    </div>
                </div>
                <div className={'section_60 section_light_blue '+css_classes.press_section}>
                    <div className={'am_container'}>
                        <div className={'section_title black '+css_classes.latest_section_title}>Latest Stories</div>
                        <div className={"row"}>
                            {latest_stories}
                        </div>
                    </div>
                </div>
                <div className={'section_60'}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.other_section_title}>Other Stories</div>
                        <div className={"row flex_wrap"}>
                            {other_stories}
                        </div>
                    </div>
                </div>
                {more_button}
            </div>
        </React.Fragment>


    }
}
