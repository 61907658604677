import React, {Component} from 'react';
import program_css from "../program.module.css"
import inner_css from "../inner.module.css"
class CampProgram extends Component {
    constructor(props) {
        super();
        this.data = JSON.parse(props.data);
    }
    render() {
        return (
            <div className={program_css.camp_main}>
                <div className={program_css.title_bar}>
                    <div className={inner_css.ripa_container+' '+program_css.title_bar_inner}>
                        <div className={program_css.title_bar_big}> Starmus camp</div>
                        <div className={program_css.title_bar_small}>
                            <div>10:00-22:00</div>
                            <div>Main Square, Bratislava</div>
                        </div>
                    </div>
                </div>
                <div className={program_css.camp_sections}>
                    <div className={program_css.camp_section+' '+program_css.camp_section_music}>
                        <div className={inner_css.ripa_container+' '+program_css.ripa_music}>
                            <div className={program_css.camp_section_title}>music </div>
                            <div className={inner_css.pItemGroupRow}>
                                {this.data.music.map((item,index)=>{
                                    return <div className={program_css.camp_group}>
                                        {item.houre && <div>{item.houre}</div>}
                                        <div className={inner_css.color_orange}>{item.text}</div>
                                        {item.text2 && <div className={inner_css.color_white}>{item.text2}</div>}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={program_css.camp_section+' '+program_css.camp_section_lectures}>
                        <div className={program_css.camp_section_title}>Lectures </div>
                        <div className={inner_css.pItemGroupRow}>
                            {this.data.lectures.map((item,index)=>{
                                return <div className={program_css.camp_group}>
                                    {item.houre && <div>{item.houre}</div>}
                                    <div className={inner_css.color_orange}>{item.text}</div>
                                    {item.text2 && <div className={inner_css.color_white}>{item.text2}</div>}
                                </div>
                            })}
                        </div>
                    </div>
                    <div className={program_css.camp_section+' '+program_css.camp_section_exhibitors}>
                        <div className={program_css.camp_section_title}>Exhibitors </div>
                        <div className={inner_css.pItemGroupRow}>
                            {this.data.exhibitors.map((item,index)=>{
                                return <div className={program_css.camp_group}>
                                    {item.houre && <div>{item.houre}</div>}
                                    <div className={inner_css.color_orange}>{item.text}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className={program_css.lent}>
                    <div className={inner_css.ripa_container}>
                        <div className={program_css.small_white+' text_right'}><sup>*</sup>Free entrance</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CampProgram;
