import React, {Component} from 'react';
import css_classes from "./style.module.css"
// import eset from "./images/eset.svg"
import eset from "./images/eset_logo.png"
// import omega_ from "./images/omega.svg"
import omega from "./images/omega.png"
// import vub from "./images/vub.svg"
import kia from "./images/kia.jpg"
import sponsors from "./images/sponsors_ppp.png"
class S7Sponsors extends Component {
    render() {
        return (
            <div className={css_classes.sponsors_wrap}>
                <div className={'am_container'}>
                    {/*<div className={css_classes.sponsors}>
                        <div className={css_classes.sponsors_group}>
                            <span className={css_classes.sponsored_by}>POWERED BY:</span>
                            <img src={eset} className={css_classes.sponsors_img+' '+css_classes.eset}/>

                        </div>
                        <div className={css_classes.sponsors_group}>
                            <span className={css_classes.sponsored_by}>SPONSORED BY:</span>
                            <img className={css_classes.sponsors_img+' '+css_classes.sponsors_vub} src={vub}/>
                            <img className={css_classes.sponsors_img+' '+css_classes.sponsors_omega} src={omega}/>
                            <img className={css_classes.sponsors_img+' '+css_classes.sponsors_kia} src={kia}/>
                        </div>
                    </div>*/}
                    <img src={sponsors}/>
                </div>
            </div>
        );
    }
}

export default S7Sponsors;
