import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import Configs from "../../configs";
import PSection3 from "./PSection3";
import S7Sponsors from "../components/S7Sponsors";
import Welcome from "./Welcome";
import ProgramItem from "./ProgramItem";
import ProgramItemTitle from "./ProgramItemTitle";
import S7Medal from "./S7Medal";
import StarParty from "./StarParty";
import {Link} from "react-router-dom";

class ProgramNew extends Component {
    constructor(props) {
        super();
        this.state = {
            data: [],
            selectedDate: null,
            title: null
        }
        this.closeProgram = this.closeProgram.bind(this)
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        fetch(`${Configs.api_url}data/get-program-new`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        data: data.data,
                    })
                }
            );

    }

    openProgram(program) {
        let dateId = parseInt(program.id);
        let dateInfo = JSON.parse(program.info);
        let newState = {
            title: dateInfo.title,
        }
        switch (dateId) {
            case 11:
                newState.selectedDate = <Welcome/>;
                break;
            case 13:
                newState.selectedDate = <StarParty program={program}/>;
                break;
            case 14:
                newState.selectedDate = <S7Medal program={program}/>;
                break;
            default:
                newState.selectedDate = <ProgramItem program={program}/>;

        }
        this.setState(newState)
    }

    closeProgram() {
        this.setState({selectedDate: null})
    }

    render() {
        return (
            <div className={"am_wrapper page_main " + css_classes.programContent}>
                <div className={'header_section ' + css_classes.header}>
                    <a target={'_blank'} href={Configs.esel_url} className={css_classes.eset_link}>Eset link</a>

                </div>
                <div className={css_classes.abs_ticket+' '+(this.state.selectedDate?css_classes.abs_ticket_inner:"")} >
                    <a href={'https://www.ticketportal.sk/event/STARMUS-VII?idpartner=1017&lang=US'} target={"_blank"} className={css_classes.buyTicket+' '+(this.state.selectedDate?css_classes.buyTicketInner:"")}>Buy Ticket</a>
                </div>

                {this.state && !this.state.selectedDate && <div>

                    <div className={css_classes.section_2}>
                        <div className={'am_container'}>
                            <div className={css_classes.section_2_title}>PROGRAM / MAY 2024</div>
                            <div className={css_classes.dateList}>
                                {this.state.data.length > 0 && this.state.data.map((item, index) => {
                                    return (
                                        <Link to={'program-item-page/'+item.id} key={'b125_' + index} className={css_classes.dateListItem} onClick={() => {
                                            // this.openProgram(item)
                                        }}>
                                            <div className={css_classes.dateListDay}>{item.display_date}</div>
                                            <div className={css_classes.dateListText}>
                                                <div className={css_classes.dateListLabel}>{item.label}</div>
                                                {item.desc.split('|').map((desc, index) => {
                                                    return <div className={css_classes.dateListDesc}
                                                                key={'dateListDesc_' + index}>{desc}</div>
                                                })}
                                                <div className={css_classes.dateListMore}>FOR MORE...</div>
                                            </div>
                                        </Link>)
                                })}

                            </div>
                        </div>

                    </div>
                    <PSection3></PSection3>
                </div>}
                {
                    this.state.selectedDate && <div className={css_classes.pItemInner}>
                        <ProgramItemTitle title={this.state.title}  closeHandler={this.closeProgram}/>
                        {this.state.selectedDate}
                    </div>
                }

                <S7Sponsors></S7Sponsors>
            </div>
        );
    }
}

export default ProgramNew;