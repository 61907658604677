import React from "react";
import css_classes from "./style.module.css";
import {Link} from "react-router-dom";

const Figure = (props) => {
    return (<React.Fragment>
        <div className={'figure_wrap '+css_classes.figure_wrap}>
            <Link to={'/person/'+props.info.slug} className={'figure '+css_classes.figure}>
                <img className={css_classes.figure_image} src={props.info.image} />
                <div className={css_classes.figure_name}>{props.info.name}</div>
                <div className={"figure_position"}>{props.info.position}</div>
                <div className={css_classes.figure_about}>{props.info.about}</div>
            </Link>

        </div>
    </React.Fragment>)
}
export default Figure;