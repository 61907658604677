import React, {Component} from 'react';
import styles from "../style.module.css";
import p_1 from "../images/p_1.png";
import p_2 from "../images/p_2.png";
import p_3 from "../images/p_3.png";
import p_4 from "../images/p_4.png";
import p_5 from "../images/p_5.png";
import p_6 from "../images/p_6.png";

class PartnersPanel extends Component {
    render() {
        return (<div className={"am_container " + styles.partners_panel}>
                <div className={styles.partners_group}>
                    <div className={styles.partners_group_title}>Organizer</div>
                    <div className={styles.partners_logos_wrap}>
                        <img className={styles.partner_img} src={p_1}/>
                    </div>
                </div>
                <div className={styles.partners_group}>
                    <div className={styles.partners_group_title}>Partner</div>
                    <div className={styles.partners_logos_wrap}>
                        <img className={styles.partner_img} src={p_2}/>
                    </div>
                </div>
                <div className={styles.partners_group}>
                    <div className={styles.partners_group_title}>Sponsors</div>
                    <div className={styles.partners_logos_wrap}>
                        <img className={styles.partner_img} src={p_3}/>
                        <img className={styles.partner_img} src={p_4}/>
                        <img className={styles.partner_img} src={p_5}/>
                    </div>
                </div>
                <div className={styles.partners_group}>
                    <div className={styles.partners_group_title}>In collaboration with</div>
                    <div className={styles.partners_logos_wrap}>
                        <img className={styles.partner_img} src={p_6}/>
                    </div>
                </div>
            </div>);
    }
}

export default PartnersPanel;