import './modal.css';
import React, { Component }  from 'react';


const MyModal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                <span className={"modal_close"} onClick={handleClose}>
                </span>
                {children}
            </section>
        </div>
    );
};
export default MyModal;