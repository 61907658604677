import React, {Component} from "react";
import css_classes from '../style.module.css';
import {ImgGrid} from "../../components/ImgGrid";
export class Template5Img extends Component{
    constructor(props) {
        super();
        this.content = props.content
    }
    render(){
        let images = this.content.files.slice(0,5);
        return <ImgGrid images={images} />;
    }
};