import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class PageTemplateSimple extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }
    render() {
        let random_min =1;
        let random_max = 3;
        let random_bg = Math.floor(Math.random() * (random_max - random_min + 1)) + random_min;
         return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={"header_section "+css_classes['random_section_'+random_bg]}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.section_1_title}>{this.content.title}</div>
                    </div>
                </div>
                <div className={'section_60'}>
                    <div className={'am_container'}>
                        {/*<div className={'section_title black text_center '}>{this.state.data.title}</div>*/}
                        <div className={"text_1040 post_content"} dangerouslySetInnerHTML={{__html:this.content.content}}>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}