import React, {Component} from "react";
import css_classes from "./style.module.css"
import person_img from "./images/kemurdzhian.png"
import img1 from "./images/img1.jpg"
import img2 from "./images/img2.jpg"
import img3 from "./images/img3.jpg"

export class Kemurdzhian extends Component {
    constructor(props) {
        super();
    }

    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
    }

    render() {
        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={'header_section ' + css_classes.header_section}>
                    <div className={'am_container'}>
                        <div className={"section_title"}>Alexander Kemurdzhian 100</div>
                    </div>
                </div>
                <div className="section_60">
                    <div className={'am_container'}>
                        <div className={"text_center " + css_classes.person_image}>
                            <img src={person_img}/>
                        </div>
                        <div className={'text_1040 ' + css_classes.text}>
                            <p className={css_classes.text_p}>One of the outstanding scientists of the 20th Century,
                                professor Alexander Kemurdzhian (Armenian: Ալեքսանդր Քեմուրջյան; October 4, 1921 –
                                February 25, 2003) was born to Armenian parents originally from Trebizond (Western
                                Armenia).</p>
                            <p className={css_classes.text_p}>In 1940 he enrolled at the Bauman Higher Technical College
                                in Moscow. At the beginning of WWII, as a student, he was involved in a group of
                                engineers assigned to repair damaged tanks. In early 1942 he volunteered to join the
                                Soviet Army. For his services, he was awarded the Order of Courage, Order of the Red
                                Star, Order of the Patriotic War, Order of the Badge of Honour, and the Medal for Battle
                                Merit.</p>
                            <p className={css_classes.text_p}>Resuming academic study, Kemurdzhian graduated from the
                                department of tracked vehicles of the Bauman Higher Technical College in 1951. Soon
                                after, he began working at the Saint Petersburg-based All-Union Scientific-Research
                                Institute whose primary expertise was building tanks for the Soviet Army. For many years
                                documentation regarding Alexander Kemurdzhian and his activities was classified.
                                Kemurdzhain’s name was kept secret and he was published under the pseudonyms
                                Aleksandrov, Leonovich, Uglev.</p>
                            <p className={css_classes.text_p}>Kemurdzhian headed the team assigned to develop the
                                chassis for the Lunokhod (Moonwalker) program. His team designed both Lunokhod 1(1970)
                                and Lunokhod 2 (1973).</p>
                            <p className={css_classes.text_p}>The first-ever planetary rover, Lunokhod 1 was carried to
                                the Moon by Luna 17. It was launched on November 10 and landed on the Moon on November
                                17, 1970. </p>
                        </div>
                        <div className={'row flex_wrap text_1040'}>
                            <div className={css_classes.grid_img_1}>
                                <img className={css_classes.grid_img} src={img1}/>
                            </div>
                            <div className={css_classes.grid_img_2}>
                                <img className={css_classes.grid_img} src={img2}/>
                            </div>
                            <div className={css_classes.grid_img_3}>
                                <img className={css_classes.grid_img} src={img3}/>
                            </div>
                        </div>
                        <div className={'text_1040 ' + css_classes.text}>
                            <p className={css_classes.text_p}>Lunokhod 1 was the first successful rover to operate
                                beyond Earth and the first
                                self-propelled, crewless vehicle to operate on the Moon. The remote-controlled robot
                                weighed 756 kg, carried cameras, transmitters, and scientific instruments. It traveled
                                10.54 km in 10 months and sent back 20,000 photos, 200 panoramas, and completed over 500
                                lunar soil tests.</p>
                            <p className={css_classes.text_p}> Mars-3 was designed and manufactured in the Mobile
                                Vehicle
                                Engineering Institute by a team of approximately 150 engineers, led by Alexander
                                Kemurdzhian.</p>
                            <p className={css_classes.text_p}>
                                In May 1986, during the Chernobyl nuclear disaster, Kemurdzhian personally participated
                                in
                                the /Chernobyl liquidators rescue team/rescue works. In the shortest time under the
                                leadership of the genius engineer, the STR-1 robots were designed and manufactured.
                                These
                                transport autonomous robots worked more than 200 hours and removed over 90 tons of
                                radioactive materials.</p>
                            <p className={css_classes.text_p}>

                                Eventually, Kemurdzhian was exposed to excessive radiation and was treated in a Moscow
                                hospital for radiation burns. Meanwhile, the use of a robotic complex excluded the
                                involvement of up to 1000 military personnel and prevented them from direct nuclear
                                radiation.</p>
                            <p className={css_classes.text_p}>

                                Alexander Kemurdzhian worked at the Soviet Union leading scientific institutions for
                                most of
                                the second half of the 20th century. During his unprecedented career, he authored more
                                than
                                200 scientific publications and patented 50 inventions. The Russian Space Federation has
                                been awarding the Kemurdzhian Medal since 2010.</p>
                            <p className={css_classes.text_p}>

                                The Armenian genius engineer’s rovers’ space journey commences a new epoch in world
                                science.
                                In 1997, the International Astronomical Committee named asteroid #5933 after Kemurjian.
                                In
                                1998, the publishing company Melrose Books included Alexander Kemurdzian's name in the
                                British book "Outstanding People of the 20th Century".</p>


                        </div>

                    </div>
                </div>
                <div className={"section_60"}>
                    <div className={"text_1040"}>
                        <div className={'section_title black text_center'}>Program</div>
                        <table>
                            <tr>
                                <th>10:00</th>
                                <td>Registration</td>
                            </tr>
                            <tr>
                                <th rowSpan={10}>10:30</th>
                                <td>Welcome address:</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Armen Sarkissian,</b><br/>
                                    The President of the Republic of Armenia
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Vahram Dumanyan, </b><br/>
                                    Minister of Science, Education, Culture and Sports of the Republic of Armenia
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Vahagn Khachaturyan,</b><br/>
                                    Minister of High-Tech Industry of the Republic of Armenia</td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Garik Israelian,</b><br/> Director of Starmus
                                    Festival (Spain)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Sean O’Keffe, </b><br/>Former Administrator of NASA (USA)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Bethany Ehlmann, </b><br/>President of the Planetary Society (USA)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Louis Friedman, </b><br/>Co-Founder of the Planetary Society  (USA)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Charlie Duke, </b><br/>Apollo 16 Astronaut-Moonwalker (USA)
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Premiere of Music Videos Produced by <b>Starmus,
                                    Brian Eno</b> and <b>Serj Tankian</b>
                                </td>
                            </tr>
                            <tr>
                                <th>11:15</th>
                                <td><b>«Alexander Kemurdzhian 100» stamp cancelation</b></td>
                            </tr>

                            <tr>
                                <th>11:30</th>
                                <td><i>The life & legacy of  Alexander Kemurdzhian</i>.
                                    <br/>Vladimir Kemurdzhian
                                    <br/>Research Institute of Transmash(St. Petersbug, Russia)</td>
                            </tr>
                            <tr>
                                <th>11:45</th>
                                <td><i>Space Race II:</i>  <br/> <b>Nina  Burleigh</b>Writer and investigative journalist (USA)</td>
                            </tr>
                            <tr>
                                <th>12:00</th>
                                <td>Coffee</td>
                            </tr>
                            <tr>
                                <th>12:45</th>
                                <td>Conversation with the <b>President Armen Sarkissian. Nina Burleigh</b></td>
                            </tr>
                            <tr>
                                <th>12:45</th>
                                <td><i>Alexander Kemurdzhian: Founder of the school of creators of planetary rover locomotion systems </i> <br/>
                                    <b>Mikhail Malenekov</b> <br/>
                                    Russian Academy of Sciences( St. Petersburg, RF)
                                </td>
                            </tr>
                            <tr>
                                <th>13:15</th>
                                <td><i>Artificial Satellites in the 21st century:</i><br/> <b>Juan-Tomas  Hernani</b><br/> CEO of «Satlantis» (Spain)</td>
                            </tr>
                            <tr>
                                <th>13:30</th>
                                <td><i>Understanding Planet Mars: remaining mysteries and future investigations:</i> <br/><b>Francois Forget</b><br/> Research Director of the
                                    French National Centre for Scientific Research
                                    (France)
                                </td>
                            </tr>
                            <tr>
                                <th>13:45</th>
                                <td><i>From Mars to Exoplanets: a journey beyond the Solar System</i>    <br/><b>  Isabelle Baraffe </b><br/>Professor of Astrophysics of the University of Exeter (UK)
                                </td>
                            </tr>
                            <tr>
                                <th>14:00</th>
                                <td>Panel Discussion  <i><b>Space and Humanity:</b></i><br/>  Jean-Jacques Jordain, Isabele Baraffe, Garik Israelian and Rafael Guzman. </td>
                            </tr>
                            <tr>
                                <th>14:30</th>
                                <td><i>Space, a dimension of our future:</i><br/>   <b>Jean-Jacques Dordain</b><br/> The former Director General of European Space Agency(France)
                                </td>
                            </tr>
                            <tr>
                                <th>14:45</th>
                                <td>Closing</td>
                            </tr>
                        </table>

                        <div className={'section_title black text_center'}>PARTICIPANTS</div>
                        <ul>
                            <li>Armen Sarkissian (Armenia)</li>
                            <li>Vladimir Kemurdzhian (Russia)</li>
                            <li>Jean-Jacques Dordain (France)</li>
                            <li>Nina Burleigh (USA)</li>
                            <li>Isabelle Baraffe (United Kingdom)</li>
                            <li>Mikhail Malenkov (Russia)</li>
                            <li>Garik Israelian (Spain)</li>
                            <li>Francois Forget (France)</li>
                            <li>Rafael Guzman (USA)</li>
                            <li>Juan-Tomas Hernani (Spain)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>


    }
}
