import React, {Component} from "react";
import styles from "./style.module.css";
import {Link} from "react-router-dom";
import banner_info from "./images/banner_info.png"
import PartnersPanel from "./components/partnersPanel";
import S8_section2 from "./sections/s8_section2";
import S8Section2 from "./sections/s8_section2";
import S8Section3 from "./sections/s8_section3";
import S8Section4 from "./sections/s8_section4";
import S8Section5 from "./sections/s8_section5";
import S8Section6 from "./sections/s8_section6";
import S8Partners from "./sections/s8_partners";


export class Starmus8 extends Component {
    constructor(props) {
        super(props);
        props.updateMenuClass('new_menu');
    }
    componentDidMount() {
        this.props.updateMenuClass('new_menu');
    }

    render() {
            return <React.Fragment>
                <div className={"am_wrapper page_main "+styles.content_wrapper}>
                    <div className={styles.starmus_8_banner}>
                        <div className={"am_container"}>
                            <img className={styles.banner_info} src={banner_info} alt={"starmus_8"}/>
                        </div>
                    </div>
                    <PartnersPanel/>
                    <S8Section2  openPopup={this.props.openPopup}/>
                    <S8Section3></S8Section3>
                    <S8Section4></S8Section4>
                    <S8Section5></S8Section5>
                    <S8Section6></S8Section6>
                    <S8Partners></S8Partners>
                </div>
            </React.Fragment>
    }
}
