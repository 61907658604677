import React, {Component} from 'react'
import ScrollDown from "../../components/Scroll/ScrollDown";

import '../css/mariner9.css'
import ImageMariner9 from '../../assets/images/mariner9.png'
import ImageMariner9768 from '../../assets/images/768/mariner9.png'
import ImageMariner9375 from '../../assets/images/375/mariner9.png'
import ImagePhobos from '../../assets/images/phobos.png'
import ImagePhobos9768 from '../../assets/images/768/phobos.png'
import ImagePhobos9375 from '../../assets/images/375/phobos.png'

class Mariner9 extends Component {
  render() {
    return (
      <section className="section parallax mariner9-section">
        <div className='container'>
          <div className="mariner9-block starmus-block">
            <picture>
              <source srcSet={ImageMariner9768} media="(max-width: 768px)"/>
              <source srcSet={ImageMariner9375} media="(max-width: 375px)"/>
              <img src={ImageMariner9} className="mariner-9" alt='Mariner 9'/>
            </picture>
            <picture>
              <source srcSet={ImagePhobos9768} media="(max-width: 768px)"/>
              <source srcSet={ImagePhobos9375} media="(max-width: 375px)"/>
              <img src={ImagePhobos} className="phobos" alt='Phobos'/>
            </picture>
            <div className="mariner9-block-text">
              <h1 className="starmus-h2">
                Mariner 9 - The First to Orbit Mars
              </h1>
              <div className='mariner9-text'>
                <p className='mariner9-p starmus-p'>
                  Mariner 9 (NASA/JPL, USA) was the first spacecraft to orbit Mars. Mariner 9 arrived in
                  Martian orbit on November 14, 1971, two weeks earlier than the Soviet orbiter Mars 2
                  (arriving on Nov. 27, 1971). Mariner 9 mapped 85 percent of the Martian surface. The
                  contact was lost on Oct. 27, 1972.
                </p>
              </div>
            </div>
            <ScrollDown to='races-to-mars-section' containerId="main"/>
          </div>
        </div>
      </section>
    )
  }
}

export default Mariner9