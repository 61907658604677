import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {Switcher} from "../components/Switcher/Switcher";
import TestimonialItem from "../components/TestimonialItem";

export class Testimonials extends Component {

    constructor(props) {
        super();
        this.state = {data:[]}

    }

    componentDidMount() {
        this.getData();
    }
    getData = (category_id=3)=> {
        fetch(`${Configs.api_url}data/get-testimonials?category_id=`+category_id)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        data: data.data,
                    });
                }
            );
    }
    switchCategory = (category_id) => {
        this.getData(category_id);
    }
    render() {
        let keys = [
            {id:3,title:'Stars about Starmus'},
            {id:2,title:'Media about Starmus'},
            {id:1,title:'Reflections On Starmus'}
        ];
        let content = [];
        for (let item_info of this.state.data) {
            content.push(<TestimonialItem info={item_info}/>)
        }

        let switcher=<Switcher keys={keys} switcherFunction={this.switchCategory}></Switcher>

        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={css_classes.section_1}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.section_1_title}>Testimonials</div>
                    </div>
                </div>
                <div className={'section_60 am_container text_center'}>

                    {switcher}
                    <div className={'row flex_wrap '+css_classes.data_list}>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>


    }
}
