import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import {Link} from "react-router-dom";

class ProgramItemTitle extends Component {
    constructor(props) {
        super();
        this.title= props.title;
        this.closeProgram= props.closeHandler
    }
    render() {
        return (
             <div>
                <div className={css_classes.pItemTitle}>
                    <div className={css_classes.ripa_container+' '+css_classes.pItemTitleInner}>
                        <div className={css_classes.pItemTitleLeft}>
                            <div className={css_classes.pItemTitleDate}>{this.title.date}</div>
                        </div>
                        <Link to={'/program-new'} className={css_classes.pItemTitleClose} >

                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProgramItemTitle;