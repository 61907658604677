import React, {Component} from 'react';
import '../css/hotels_armenia.css'
import ImageHotels from '../../assets/images/hotels-armenia.jpg';
import ImageHotels375 from '../../assets/images/375/hotels-armenia.jpg';

class HotelsArmenia extends Component {

  render() {
    return (
      <section className="location-section">
        <div className='container'>
          <div className='accommodations-block'>
            <div className='accommodations-block-text'>
              <h1 className='accommodations-h4 starmus-black-h1'>HOTELS AND ACCOMMODATION</h1>
              <p className='accommodations-p starmus-black-p'>
                If you have never been to Yerevan before, choosing the right accommodation may be a challenging task.
                There is a big choice of hotels, hostels and apartment rentals in Yerevan and each of those properties
                offers various deals and promotions, so it may become difficult to make the right choice.
                Just like anywhere else in the world, most of Yerevan attractions are located in the city center. So if
                your primary purpose of visit is tourism, then it makes sense to choose one of the following options:
                hotels located near Republic Square or near the Opera House. From these 2 main areas you will be in a
                walking distance from such Yerevan sights as the Cascade, Matenadaran, History Museum of Armenia, the
                Northern Avenue, etc. Hotels in Yerevan range from low budget comfort type to upscale 5 star hotels, so
                there is a hotel for every budget. But when choosing a hotel you need to consider the actual value for
                money and free-of charge services included in the price. It also makes sense to visit the hotel website
                for special offers, package deals and promotions.
              </p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='hotels-slider'>
            <picture>
              <source srcSet={ImageHotels375} media="(max-width: 464px)"/>
              <img src={ImageHotels} className="hotels-armenia-img" alt='Hotels Armenia'/>
            </picture>
          </div>
        </div>
        <div className='container'>
          <div className='hotels-armenia-blocks'>
            <div className='hotels-armenia-block'>
              <h1 className='starmus-black-h4'>LANGUAGES IN ARMENIA</h1>
              <p className='accommodations-p starmus-black-p'>Armenia is an ethnically homogeneous country, in which
                Armenian is the
                official language and is spoken as a first language by the majority of its population.<br/>
                As of today, Russian is still, by far, the best known foreign language among the Armenian population.
                English is gaining popularity in recent years. French, Spanish, Italian and several other languages
                have also begun to be studied and used.</p>
            </div>
            <div className='hotels-armenia-block'>
              <h1 className='starmus-black-h4'>STAY ONLINE</h1>
              <p className='accommodations-p starmus-black-p'>Public hotspots and Free Wi-Fi in parks, cafes and
                restaurants are
                widespread. Yerevan has a free city Wi-Fi network. an increase in tourism over the last decade has
                opened up accommodation options, including international chains like Marriott, Radisson, and HiltonGaze
                upon Mount Ararat.
                Yerevan has changed during its 2,800 years of history, but the view of
                Mount Ararat has not. To the world, Mount Ararat is (perhaps) where Noah’s Ark ended up, but for
                Armenians, it stirs an unfulfilled yearning—for a mountain that can be seen, but not touched.</p>
            </div>
          </div>
          <div className='hotels-armenia-blocks'>
            <div className='hotels-armenia-block'>
              <h1 className='starmus-black-h4'>EXPECT HOSPITALITY</h1>
              <p className='accommodations-p starmus-black-p'>In Yerevan, hospitality is king, and residents abide by
                the adage “A
                guest has a place over the host’s head,” meaning the guest is more important than the host. Fresh,
                cold, and clean water is plentiful in Yerevan.It’s also touted to be some of the tastiest and purest
                drinking water in the world. When Yerevan celebrated its 2,750th anniversary in 1968, the city built
                2,750 water fountains for its residents—and you can drink from them. Meals in Yerevan are hearty,
                delicious, and affordable. Traditional dishes include dolma (grape leaves stuffed with meat and rice),
                mante (baked meat dumplings served with yogurt), lavash (thin wood-fired bread), lahmajun (thin dough
                topped with spicy mincemeat) ghapama (a stuffed pumpkin dish).</p>
            </div>
          </div>
          <div className='hotels-armenia-blocks'>
            <div className='hotels-armenia-block'>
              <h1 className='starmus-black-h4'>FASCINATING FACTS ABOUT ARMENIA</h1>
              <p className='accommodations-p starmus-black-p'>
                <b>1.</b> The territory of Armenia is 29.000 km2 scattered with more than 30.000 architectural
                monuments. This
                means that walking in any directions for half an hour you are likely to find an architectural treasure.
              </p>
              <p className='accommodations-p starmus-black-p'>
                <b>2.</b> Although Armenia is a landlocked country, 1/6 of its territory is occupied by a large body of
                water.
                Lake Sevan, located in Armenia, is one of the largest alpine (high-altitude) lakes in the world, and one
                of the most beautiful, too.
              </p>
              <p className='accommodations-p starmus-black-p'>
                <b>3.</b> The world's first textbook of arithmetic problems was created by an Armenian scientist, David
                the
                Invincible, who was called thus due to his extraordinary capacity of never losing a philosophical
                dispute.
              </p>
              <p className='accommodations-p starmus-black-p'>
                <b>4.</b> Armenia is a chess superpower. Currently, Armenian men's team is one of the strongest in the
                world,
                and leading Armenian chess player Levon Aronian is consistently among the top three players in the world
                in the FIDE rating list.
              </p>
              <p className='accommodations-p starmus-black-p'>
                <b>5.</b> Armenia has a number of churches and other architectural monuments dating back to the 4-5th
                centuries. Many of them are still operating as churches, others lie in picturesque ruins.
              </p>
              <p className='accommodations-p starmus-black-p'>
                <b>6.</b> George Gordon Byron studied Armenian during his stay on the island of San Lazzaro in Venice,
                in a
                prominent Armenian research center and monastery.
              </p>
              <p className='accommodations-p starmus-black-p'>
                <b>7.</b> The medieval universities of Armenia were founded in the XVI century and enjoyed international
                prestige during a long time.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HotelsArmenia;