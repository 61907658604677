import React, {Component} from 'react';
import program_css from "../program.module.css";
import inner_css from "../inner.module.css";
import mu from "./images/universities/mu.png"
import afad from "./images/universities/afad.png"
import cu from "./images/universities/cu.png"
import sas from "./images/universities/sas.png"
import stu from "./images/universities/stu.png"
import tu from "./images/universities/tu.png"

class CityProgram extends Component {
    constructor(props) {
        super();
        this.data = JSON.parse(props.data);
    }

    universities = {
        stu:{img:stu,name:"Slovak University of Technology in Bratislava"},
        mu:{img:mu,name:'Masaryk University'},
        mu_b:{img:mu,name:'Masaryk University (BRNO)'},
        cu:{img:cu,name:"Comenius University Bratislava"},
        afad:{img:afad,name:"Academy of Fine Arts and Design in Bratislava"},
        sas:{img:sas,name:"Slovak Academy of Sciences"},
        tu:{img:tu,name:"Alexander Dubček University of Trenčín"},
    }

    render() {
        return (
            <div className={program_css.city_main}>
                <div className={program_css.title_bar}>
                    <div className={inner_css.ripa_container + ' ' + program_css.title_bar_inner}>
                        <div className={program_css.title_bar_big}> CITY PROGRAM</div>
                        <div className={program_css.title_bar_small}>
                            LECTURES FROM SPEAKERS AT SLOVAK UNIVERSITIES <span className={program_css.small_white}>*For Students</span>
                        </div>
                    </div>
                </div>
                <div className={program_css.city_program_section}>
                    <div className={inner_css.ripa_container}>
                        <div className={"row flex_wrap"}>
                            {this.data.map((item, index) => {
                                console.log(this.data.length,'aaa');
                                let col_class = (this.data.length % 3) ? "col3" : "col4"
                                return <div className={program_css.city_program_col + ' ' + col_class}>
                                    <div className={program_css.u_img_wrap}>
                                        <img src={this.universities[item.key]['img']}/>
                                    </div>
                                    <div className={program_css.u_name}>
                                        {this.universities[item.key]['name']}
                                    </div>
                                    {item.data.map((time_group,index)=>{
                                        return <div className={program_css.time_group}>
                                            <div className={program_css.time_group_time}>{time_group.time}</div>
                                            <div className={program_css.time_group_speaker+' '+inner_css.color_orange}>{time_group.speaker}</div>
                                            <div className={program_css.time_group_title}>{time_group.title}</div>
                                        </div>
                                    })}
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CityProgram;