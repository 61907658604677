import styles from "../style.module.css";
import React from 'react';

export default function Section1() {
    return (
        <div className={"am_container"}>
            <div className={styles.section_1+' flex'}>
                <div className={styles.section_1_img_wrap}>
                    <img className={styles.section_1_img} src={require("../images/section_1.jpg")}/>
                </div>
                <div className={styles.section_1_text_wrap}>
                    <div className={styles.title}>Climate Change</div>
                    <div className={styles.s_text+' '+styles.section_1_text}>In the last 800,000 years, there have been eight cycles of ice ages and warmer periods, with the end of the last ice age <span className={"text_bold"}>about 11,700 years ago</span> marking the beginning of the modern climate era — and of human civilization.</div>
                </div>
            </div>
        </div>
    );
}