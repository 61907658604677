import React from "react";
import css_classes from "./style.module.css";
import {Link} from "react-router-dom";

const TestimonialItem = (props) => {
    let image ='';
    if(props.info.image){
        image = <img className={css_classes.image} src={props.info.image}/>
    }
    let more_button='';
    if(parseInt(props.info.content)){
        more_button = <Link to={'/testimonials-item/'+props.info.id} className={css_classes.more_button}>Read more</Link>
    }else{
        more_button = <div className={css_classes.no_more_button}></div>
    }
    let sub_title='';
    if(props.info.sub_title){
        sub_title = <div className={css_classes.item_sub_title}>{props.info.sub_title}</div>
    }
    return (<React.Fragment>
        <div className={'col6 ' +css_classes.item}>
            <div className={css_classes.item_inner}>
                {image}
                <div className={css_classes.item_text}>
                    <div className={css_classes.item_title}>{props.info.title}</div>
                    {sub_title}
                    <div className={css_classes.item_desc} dangerouslySetInnerHTML={{__html: props.info.description}}></div>
                    {more_button}
                </div>
            </div>
        </div>
    </React.Fragment>)
}
export default TestimonialItem;