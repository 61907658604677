import React, {Component} from "react";
import css_classes from './style.module.css'
import MyModal from "../components/Modal";
import full_program_img from "./images/full_program.png";

export class FullProgram extends Component {
    constructor(props) {
        super();
        this.state = {
            iframeUrl: null,
            isVisibleTicketModal: false,
            tickets: [],
            group_url: ''
        }


        this.openTicketModal = this.openTicketModal.bind(this);
        this.closeTicketModal = this.closeTicketModal.bind(this);
        this.resizeIframe = this.resizeIframe.bind(this);
    }


    resizeIframe() {
        const obj = document.getElementById('tomsarkgh_iframe');
        obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    }

    openTicketModal(url) {
        document.getElementById("main").classList.remove("wrapper");
        document.body.classList.add('overlay');

        this.setState({
            iframeUrl: url + '&dt=' + Math.floor(Math.random() * 100),
            isVisibleTicketModal: true
        })
    }

    closeTicketModal() {
        document.getElementById("main").classList.add("wrapper");
        document.body.classList.remove('overlay');

        this.setState({
            isVisibleTicketModal: false
        })
    }

    render() {
        return (<React.Fragment>
            <div className={' border_box'}>
                <div className={css_classes.full_program_image_wrap}>
                    <img src={full_program_img} className={css_classes.full_program_image}/>
                    <div onClick={()=>{this.openTicketModal('https://www.tomsarkgh.am/order/widgetUrl?eventId=45395&referral_id=starmus.com&sf_culture=en')}} className={css_classes.but_ticket_btn_image+' '+css_classes.buy_ticket_btn1}></div>
                    <div onClick={()=>{this.openTicketModal('https://www.tomsarkgh.am/order/widgetUrl?eventId=45752&referral_id=starmus.com&sf_culture=en')}}  className={css_classes.but_ticket_btn_image+' '+css_classes.buy_ticket_btn2}></div>
                </div>

                {/*<a href={this.group_url} className={css_classes.group_tickets_button}>GROUP TICKETS</a>*/}
                <MyModal show={this.state.isVisibleTicketModal} handleClose={this.closeTicketModal}>
                    <div className={css_classes.ticket_iframe_wrap}>
                        <iframe
                            sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-top-navigation"
                            id={"tomsarkgh_iframe"} src={this.state.iframeUrl}/>
                    </div>

                </MyModal>
            </div>

        </React.Fragment>)
    }
}