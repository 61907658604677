import React, {Component} from "react";
import Slider from "react-slick";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Configs from "../../configs";
import {NewsSliderItem} from "./NewsSliderItem";
export class NewsSlider extends Component{
    constructor(props) {
        super();
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        // Loader.startLoading();
        fetch(`${Configs.api_url}data/get-press?category=starmus_armenia_news`)
        // fetch(`${Configs.api_url}data/get-press?category=news`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        data: data.data.posts,
                    })
                }
            );
    }
    sliders() {
        return this.state.data.map(data => {
            return (
                <NewsSliderItem className="test" data={data} key={data.id}/>
            )
        });
    }
    render() {
        var settings = {
            speed: 500,
            arrows:true,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite:false,
            responsive: [

                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
            ]
        };


        return (
            <div className={"am_container"}>
                <Slider {...settings}>
                    {this.sliders()}
                </Slider>
            </div>
        );
    }
}