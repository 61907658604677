import React, {Component} from "react";
import Configs from "../configs";
import Checkbox from '@material-ui/core/Checkbox';

import css_classes from "./style.module.css"
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

export class TicketForm extends Component {
    ticket_index = null;

    constructor(props) {
        super(props);
        let initialData = props.initialData;
        initialData.fest_days = [];
        initialData.ticket_details.package_price = 0;
        initialData.ticket_details.total_price = 0;
        this.ticket_index = props.ticket_index;
        this.deleteTicket = props.deleteTicket;
        this.calcTotalPrice = props.calcTotalPrice;
        this.state = initialData;
        this.handleChange = this.handleChange.bind(this);
        this.handleDaysChange = this.handleDaysChange.bind(this);
        this.calcPrice = this.calcPrice.bind(this);
    }

    componentDidMount() {
        this.getFestDays();
    }

    handleChange(event) {
        let ticket_details = this.state.ticket_details;
        let val;
        let name = event.target.name;
        delete (ticket_details.errors[name]);
        switch (event.target.type) {
            case "checkbox":
                val = event.target.checked;
                break;
            default:
                val = event.target.value;
                break;
        }
        ticket_details[name] = val;

        this.setState({ticket_details: ticket_details});
        this.calcPrice();
    };

    calcPrice() {
        let ticket_details = this.state.ticket_details;
        let price;
        if (ticket_details.is_all_days == 'yes') {
            price = this.state.ticket_details.package_price;
        } else {
            price = 0;
            for (let i in this.state.ticket_details.days) {
                if (this.state.ticket_details.days[i]) {
                    for (let day of this.state.fest_days) {
                        if (day.id == i) {
                            price += day.price;
                        }
                    }
                }
            }
        }
        ticket_details.total_price = price
        this.setState({ticket_details: ticket_details});
        this.calcTotalPrice();
    }

    handleDaysChange(event) {
        let value = event.target.value;
        let ticket_details = this.state.ticket_details;
        ticket_details.days[value] = event.target.checked;
        delete (ticket_details.errors.days);
        this.setState({ticket_details: ticket_details})
        this.calcPrice();
    }

    getFestDays = () => {
        fetch(`${Configs.api_url}data/get-fest-days`)
            .then(response => response.json())
            .then(data => {
                    let ticket_details = this.state.ticket_details;
                    ticket_details.package_price = data.data.package_price;
                    ticket_details.total_price = data.data.package_price;
                    this.setState({
                        fest_days: data.data.fest_days,
                        ticket_details: ticket_details
                    }, this.calcPrice);
                }
            );
    }

    render() {
        let is_student_info = this.state.ticket_details.is_student ?
            <div className={css_classes.is_student_info}>You will be asked to present your valid student ID</div> : '';
        let fest_days_list = this.state.fest_days.map((elem, index) => {
            return <label className={'col6'}>
                <Checkbox
                    name={'days[]'}
                    checked={this.state.ticket_details.days[elem.id]}
                    onChange={this.handleDaysChange}
                    value={elem.id}
                    inputProps={{'aria-label': 'checkbox2'}}
                /> {elem.label}
            </label>
        })
        let fields = [
            {name: 'name', placeholder: 'Name'},
            {name: 'email', placeholder: 'Email'},
            {name: 'phone', placeholder: 'Phone'},
            {name: 'address', placeholder: 'Country, City, Zip code'},
        ]
        let fields_list = fields.map((field) => {
            return <div className={'col6'}>
                <input
                    className={"form_input " + ((this.state.ticket_details.errors[field.name]) ? css_classes.has_error : '')}
                    name={field.name}
                    onChange={this.handleChange}
                    onBlur={this.handleChange}
                    value={this.state.ticket_details[field.name]}
                    placeholder={field.placeholder}/>
            </div>;
        })
        return <React.Fragment>
            <div className={"white_bg ticket_form " + css_classes.ticket_wrap}>
                <DeleteIcon className={css_classes.delete_ticket} onClick={() => {
                    this.deleteTicket(this.state.ticket_index)
                }}></DeleteIcon>
                <div className={css_classes.ticket_form_group_1}>
                    <div className={css_classes.ticket_form_title}>For 1 persion</div>
                    <div className={"row flex_wrap"}>
                        {fields_list}
                    </div>
                    <div>
                        <label className={css_classes.student_checkbox_label}>
                            <Checkbox
                                name={'is_student'}
                                checked={this.state.ticket_details.is_student}
                                onChange={this.handleChange}
                                inputProps={{'aria-label': 'checkbox'}}
                            /> I’m a student
                        </label>
                        {is_student_info}
                    </div>
                </div>
                <div className={css_classes.ticket_form_group_2}>
                    <RadioGroup aria-label="is_all_days" defaultValue={this.state.ticket_details.is_all_days}
                                name="is_all_days" onChange={this.handleChange}>
                        <div className={"flex"}>
                            <FormControlLabel value="yes" control={<Radio/>} label="Package (including the concert)"/>
                            <FormControlLabel value="no" control={<Radio/>} label="Day Pass"/>
                            <div className={css_classes.ticket_price}>
                                € {this.state.ticket_details.total_price}
                            </div>
                        </div>
                    </RadioGroup>
                </div>
                <div
                    className={css_classes.ticket_form_group_2 + ' ' + (this.state.ticket_details.is_all_days == 'yes' ? 'hidden' : '')}>
                    {(this.state.ticket_details.errors.days) ?
                        <div className={css_classes.days_error}>Please select days</div> : ''}
                    <div className={'row flex_wrap'}>
                        {fest_days_list}
                    </div>
                </div>

            </div>
        </React.Fragment>


    }
}
