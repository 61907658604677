import React, {Component} from "react";
import css_classes from './style.module.css'
import {NewTicketItem} from "./NewTicketItem";
import MyModal from "../components/Modal";
import Configs from "../configs";
import {Link} from "react-router-dom";

export class Partners extends Component {
    constructor(props) {
        super();
        this.state = {
            partners: [],
            isVisiblePartnerModal:false,
            partnerData:[]
        }
        this.closePartnerModal = this.closePartnerModal.bind(this);
        this.fest_id=props.fest_id?props.fest_id:6;
    }

    componentDidMount() {
        this.getPartners();
    }

    getPartners() {
        // Loader.startLoading();
        fetch(`${Configs.api_url}data/get-partners-new?fest_id=${this.fest_id}`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        partners: data.data,
                    })
                }
            );
    }

    openPartnerModal(data) {
        document.getElementById("main").classList.remove("wrapper");
        document.body.classList.add('overlay');
        this.setState({
            partnerData:data,
            isVisiblePartnerModal: true
        })
    }

    closePartnerModal() {
        document.getElementById("main").classList.add("wrapper");
        document.body.classList.remove('overlay');
        this.setState({
            isVisiblePartnerModal: false
        })
    }

    render() {
        if (this.state.partners.length == 0) {
            return '';
        }




        return (<React.Fragment>
            {this.state.partners.map((group,index)=>{
                return <div key={'partner_group_'+index}  className={'am_container ' + css_classes.partner_logo_list}>
                    <div className={css_classes.partner_logo_list_title}>{group.title}</div>
                    <div className={css_classes.partner_logo_list_images}>
                        {group.files.map((fileGroup,index)=>{
                            return <div key={'partner_file_group_'+index} className={css_classes.partner_logo_list_images}>
                                {fileGroup.map((file,index)=>{
                                    if(file.title) {
                                        return <span key={'parner_item_'+index} onClick={()=>{this.openPartnerModal(file);}}><img className={css_classes.partner_logo_list_image} key={index}
                                                                                                src={file.url} title={file.title} alt={file.title}/></span>
                                    }else{
                                        return <a  key={'parner_item_'+index} href={file.partner_url}><img className={css_classes.partner_logo_list_image} key={index} src={file.url} title={file.title} alt={file.title}/></a>
                                    }
                                })}
                            </div>
                        })}
                    </div>
                </div>
            })}



            <MyModal show={this.state.isVisiblePartnerModal} handleClose={this.closePartnerModal}>
                <div className={css_classes.partner_modal_inner}>
                    <img src={this.state.partnerData.url} className={css_classes.partner_modal_logo}/>
                    <div dangerouslySetInnerHTML={{__html:this.state.partnerData.partner_url}}></div>
                    <a href={this.state.partnerData.title} className={css_classes.learn_more}>Learn More</a>
                </div>

            </MyModal>
        </React.Fragment>)
    }
}