import styles from "../style.module.css";
import PreRegForm from "./preRegForm"
import React from 'react';
import {Partners} from "../../StarmusArmenia/Partners";
import p1 from "../images/partner_logos/1.png";
import p2 from "../images/partner_logos/2.png";
import p6 from "../images/partner_logos/6.png";
import p7 from "../images/partner_logos/7.png";
import p8 from "../images/partner_logos/8.png";
import p9 from "../images/partner_logos/9.png";
import p18 from "../images/partner_logos/18.png";

export default function Section8(props) {
    return (
        <>
            <div className={styles.section_8}>
                <div className={"am_container"}>
                    <div className={styles.partners_row}>
                        <div className={styles.partner_left}>
                            <div className={styles.partner_group}>

                                <div className={styles.partner_group_title}>STARMUS VII PARTNER</div>
                                <img src={p1}/>
                            </div>
                        </div>
                        <div className={styles.partner_right}>
                            <div className={styles.partner_group}>
                                <div className={styles.partner_group_title}>STARMUS VII SPONSORS</div>
                                <img src={p2} className={styles.p2_img}/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.partners_row}>
                        <div className={styles.partner_left}>
                            <div className={styles.partner_group2}>

                                <div className={styles.partner_group_title2}>STARMUS VII ALLIANCES</div>
                                    <img src={p18}/>
                            </div>
                            <div className={styles.partners_row + ' ' + styles.partners_row_half}>
                                <div className={styles.partner_half}>
                                    <div className={styles.partner_group3}>

                                        <div className={styles.partner_group_title3}>Media Partner</div>
                                        <img src={p7}/>
                                    </div>

                                </div>
                                <div className={styles.partner_half}>
                                    <div className={styles.partner_group3}>

                                        <div className={styles.partner_group_title3}>Hotel Partner</div>
                                        <img src={p8}/>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className={styles.partner_right}>
                            <div className={styles.partner_group2}>

                                <div className={styles.partner_group_title2}>Auspices and Patronages</div>
                                <img src={p9}/>
                            </div>

                        </div>
                    </div>

                </div>
                {/*<Partners fest_id={7}></Partners>*/}
            </div>

        </>
    );
}