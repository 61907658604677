import React, {Component} from "react";
import {Element} from 'react-scroll'

import MainBanner from './components/MainBanner'
import MarsAwakens from './components/MarsAwakens'
import AboutStarmus from './components/AboutStarmus'
import MarsFacts from './components/MarsFacts'
import RacesToMars from './components/RaceToMars'
import MissionsToMars from './components/MissionsToMars'
import Mars3 from './components/Mars3'
import Mariner9 from './components/Mariner9'
import MysteryOfRedPlanet from './components/MysteryOfRedPlanet'
import LifeOnMars from './components/LifeOnMars'
import SettlingTheRedPlanet from './components/SettlingTheRedPlanet'
import MarsVideo from './components/MarsVideo'
import Index, {HomeFooter} from '../components/Footer/index'
import MainMenu from "../components/Menu";
import {Partners} from "../StarmusArmenia/Partners";
class LandingPageArm extends Component {
  render() {
    return (
      <React.Fragment>

          <Element name="mars-main-banner">
            <MainBanner/>
          </Element>
          <Element name="about-starmus-section">
            <AboutStarmus/>
          </Element>
          <Element name="mars-video-section">
            <MarsVideo/>
          </Element>
          <Element name="mars-facts-section">
            <MarsFacts/>
          </Element>
          <Element name="mars-awakens-section">
            <MarsAwakens/>
          </Element>
          <Element name="mission-to-mars-section">
            <MissionsToMars/>
          </Element>
          <Element name="mars3-section">
            <Mars3/>
          </Element>
          <Element name="mariner9-section">
            <Mariner9/>
          </Element>
          <Element name="races-to-mars-section">
            <RacesToMars/>
          </Element>
          <Element name="life-on-mars-section">
            <LifeOnMars/>
          </Element>
          <Element name="settling-the-red-planet-section">
            <SettlingTheRedPlanet/>
          </Element>
          <Element name="mystery-of-red-planet-section">
            <MysteryOfRedPlanet/>
          </Element>
          <Partners/>
          <HomeFooter />

      </React.Fragment>
    )
  };
}

export default LandingPageArm;