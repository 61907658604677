import "./newSlider.css";
import styles from "../style.module.css";

import {gsap} from "gsap";

import React, {useEffect, useRef} from 'react';
export default function Section5() {
    const images = [
        {ref:useRef(),textRef:useRef(),img: require("../images/slider/s_5_1.jpg"), text: "Every eight seconds, a child under the age of five dies from diseases related to contaminated water."},
        {ref:useRef(),textRef:useRef(),img: require("../images/slider/s_5_2.jpg"), text: "Tropical forests used to cover about 15 percent of the planet’s land area; they're now down to 6 percent"},
        {ref:useRef(),textRef:useRef(),img: require("../images/slider/s_5_3.jpg"), text: "About 12 million hectares of farmland a year get seriously degraded due to continuous overgrazing, monoculture planting, erosion, soil compaction, overexposure to pollutants…."},
        {ref:useRef(),textRef:useRef(),img: require("../images/slider/s_5_4.jpg"), text: "Around 150-200 plant and animal species go extinct on average every day. Around 137 of those species go extinct due to deforestation."},
        {ref:useRef(),textRef:useRef(),img: require("../images/slider/s_5_5.jpg"), text: "Every year, around 8 million metric tons of plastic waste end up in our oceans. Up to 200 million tons of plastic are currently found in our oceans. 10 percent of the plastic we use in our daily lives - the equivalent to 700 billion plastic bottles - ends up in the oceans. Unlike other materials, plastics won’t ever break down. Instead, they will remain in the environment for hundreds of years."},
        {ref:useRef(),textRef:useRef(),img: require("../images/slider/s_5_6.jpg"), text: "Plastic pollution in the oceans kills over 100,000 marine mammals, like dolphins and sea lions, and more than one million seabirds every year."},
        {ref:useRef(),textRef:useRef(),img: require("../images/slider/s_5_7.jpg"), text: "The Great Pacific Garbage Patch is an island of garbage inside the Pacific Ocean. It includes an estimated 1.8 trillion pieces of rubbish and has a size of France."},
        {ref:useRef(),textRef:useRef(),img: require("../images/slider/s_5_8.jpg"), text: "There are over 500 locations recorded as dead zones around the world, these are areas of low oxygen concentration where marine life suffocates and dies."},
    ];
    let currentIndex=0;
    const {imagesLoaded} = window;
    const prevButton = useRef();
    const nextButton = useRef();
    const cardsContainerElRef = useRef();
    const appBgContainerElRef = useRef();
    const cardInfosContainerElRef = useRef();
    const loaderElRef = useRef();


    function swapCards(direction) {
        changeInfo(direction);
        swapCardsClass(direction);
        currentIndex=(currentIndex+direction+8)%8;
    }
    function swapCardsClass(direction) {
        let currentCardEl = images[currentIndex].ref.current;
        let previousCardEl = images[getPrevIndex()].ref.current;
        let nextCardEl = images[getNextIndex()].ref.current;
        
        currentCardEl.classList.remove("current--card");
        previousCardEl.classList.remove("previous--card");
        nextCardEl.classList.remove("next--card");

        let newIndex = (currentIndex+direction+8)%8

        currentCardEl = images[newIndex].ref.current
        previousCardEl = images[getPrevIndex(newIndex)].ref.current
        nextCardEl = images[getNextIndex(newIndex)].ref.current

        removeCardEvents(currentCardEl);
        currentCardEl.classList.add("current--card");
        previousCardEl.classList.add("previous--card");
        nextCardEl.classList.add("next--card");
    }
    function changeInfo(direction) {
        let currentInfoEl = images[currentIndex].textRef.current;
        currentInfoEl.classList.remove("current--info");
        let nextIndex=direction>0?getNextIndex():getPrevIndex()
        let nextInfoEl = images[nextIndex].textRef.current;
        nextInfoEl.classList.add('current--info');
        gsap.timeline()
            .to([prevButton.current, nextButton.current], {
                duration: 0.2,
                opacity: 0.5,
                pointerEvents: "none",
            })
            .to(
                currentInfoEl.querySelectorAll(".text"),
                {
                    duration: 0.4,
                    stagger: 0.1,
                    translateY: "-120px",
                    opacity: 0,
                },
                "-="
            )
            .call(() => {

            })
            .call(() => initCardEvents())
            .fromTo(
                nextInfoEl.querySelectorAll(".text"),
                {
                    opacity: 0,
                    translateY: "40px",
                },
                {
                    duration: 0.4,
                    stagger: 0.1,
                    translateY: "0px",
                    opacity: 1,
                }
            )
            .to([prevButton.current, nextButton.current], {
                duration: 0.2,
                opacity: 1,
                pointerEvents: "all",
            });

    }

    function updateCard(e) {
        const card = e.currentTarget;
        const box = card.getBoundingClientRect();
        const centerPosition = {
            x: box.left + box.width / 2,
            y: box.top + box.height / 2,
        };
        let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
        gsap.set(card, {
            "--current-card-rotation-offset": `${angle}deg`,
        });
        const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
        gsap.set(currentInfoEl, {
            rotateY: `${angle}deg`,
        });
    }

    function resetCardTransforms(e) {
        const card = e.currentTarget;
        const currentInfoEl = cardInfosContainerEl.querySelector(".current--info");
        gsap.set(card, {
            "--current-card-rotation-offset": 0,
        });
        gsap.set(currentInfoEl, {
            rotateY: 0,
        });
    }

    function initCardEvents() {
        const currentCardEl = cardsContainerEl.querySelector(".current--card");
        currentCardEl.addEventListener("pointermove", updateCard);
        currentCardEl.addEventListener("pointerout", (e) => {
            resetCardTransforms(e);
        });
    }

    // initCardEvents();

    function removeCardEvents(card) {
        card.removeEventListener("pointermove", updateCard);
    }

    function init() {
        let tl = gsap.timeline();

        tl.to(cardsContainerEl.children, {
            delay: 0.15,
            duration: 0.5,
            stagger: {
                ease: "power4.inOut",
                from: "right",
                amount: 0.1,
            },
            "--card-translateY-offset": "0%",
        })
            .to(cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
                delay: 0.5,
                duration: 0.4,
                stagger: 0.1,
                opacity: 1,
                translateY: 0,
            })
            .to(
                [prevButton.current, nextButton.current],
                {
                    duration: 0.4,
                    opacity: 1,
                    pointerEvents: "all",
                },
                "-=0.4"
            );
    }

    const waitForImages = () => {
        let loadedCount = 0
        gsap.set(cardsContainerEl.children, {
            "--card-translateY-offset": "20px",
        });
        gsap.set(cardInfosContainerEl.querySelector(".current--info").querySelectorAll(".text"), {
            translateY: "40px",
            opacity: 0,
        });
        images.forEach((file) => {
            const imageLoader = new Image();
            imageLoader.src = file.img;

            imageLoader.onload = () => {
                loadedCount++;
                let loadProgress = loadedCount / images.length;

                if (loadedCount == images.length) {
                    init()
                }
            };
        });


        gsap.set([prevButton.current, nextButton.current], {
            pointerEvents: "none",
            opacity: "0",
        });

    };
    const getNextIndex = (index) => {
        if(index===undefined){
            index=currentIndex
        }
        return (index+9)%8;
    }
    const getPrevIndex = (index) => {
        if(index===undefined){
            index=currentIndex
        }
        return (index+7)%8;
    }


    let cardsContainerEl, appBgContainerEl, cardInfosContainerEl;

    useEffect(() => {
        cardsContainerEl = cardsContainerElRef.current;
        appBgContainerEl = appBgContainerElRef.current;
        cardInfosContainerEl = cardInfosContainerElRef.current;
        if (cardsContainerElRef.current) {
            waitForImages();
        }
    }, [cardsContainerElRef.current])
    let info_list =[];
    let card_list=[];
    let index=0;
    {images.forEach((item, index) => {
        let prefix=''
        let infoClass='';
        if(index==currentIndex){
            prefix="current"
            infoClass='current--info'
        }
        if(index == getNextIndex()){
            prefix="next"
        }
        if(index == getPrevIndex()){
            prefix="previous"
        }

        card_list.push( <div ref={item.ref} key={'slider_img_' + index} className={"card " + (prefix?prefix+"--card":"")}>
            <div className="card__image">
                <img src={item.img} alt={"slider_img_" + index}/>
            </div>
        </div>)
        info_list.push(<div ref={item.textRef} className={"info "+infoClass } key={'slider_info_' + index} >
            <p className="text description">{item.text}</p>
        </div>)
    })}
    return (
        <><div className={styles.section_5}>
            <div className={"slider_wrap "} >
                    <div className="cardList">
                        <button ref={prevButton} className="cardList__btn btn btn--left" onClick={() => {
                            swapCards(-1)
                        }}>
                            <div className="icon">
                                {/*<svg>*/}
                                {/*    <use xlink:href="#arrow-left"></use>*/}
                                {/*</svg>*/}
                            </div>
                        </button>

                        <div ref={cardsContainerElRef} className="cards__wrapper">
                            {card_list}
                        </div>

                        <button ref={nextButton} className="cardList__btn btn btn--right" onClick={() => {
                            swapCards(1)
                        }}>
                            <div className="icon">
                                {/*<svg>
                                <use xlink:href="#arrow-right"></use>
                            </svg>*/}
                            </div>
                        </button>
                    </div>
                    <div className="infoList">
                        <div ref={cardInfosContainerElRef} className="info__wrapper">


                            {info_list}
                        </div>
                    </div>
            </div>
            <div className={styles.section_5_video_wrap}>
                <video className={styles.section_5_video} muted autoPlay loop >
                    <source src={require("../images/s_5_bg.mp4")} type="video/mp4" />
                </video>
            </div>

        </div>
        </>
    );


}