import React, {Component} from 'react';
import StarmusSlider from '../../components/Slider/StarmusSlider';
import '../css/mars_awakens.css';
import '../css/mars_volkano.css';
import ScrollDown from "../../components/Scroll/ScrollDown";
import MarsVolkanoImage1 from '../../assets/images/mars-awakens/mars-awakens-9.jpg';
import MarsVolkanoImage2 from '../../assets/images/mars-awakens/mars-awakens-10.jpg'


class MarsAwakensComponent extends Component {
  render() {
    return (
      <div className='mars-awakens-slider'>
        <picture>
          <source srcSet={this.props.imagesrc} media="(min-width: 800px)"/>
          <img className='cover-parent' src={this.props.imagesrc} alt='Mars Awakens'/>
        </picture>
        <div className="mars-awakens-block-text">
          <h1 className='mars-awakens-h1 starmus-h1'>Mars Awakens</h1>
          <p className='mars-awakens-p starmus-p'>4<sup>th</sup> Rock from the Sun Rocks !</p>
        </div>
      </div>
    )
  }
};

class MarsAwakens extends Component {
  render() {
    return (
      <section className='mars-awakens-section'>
        <div className='mars-awakens-block starmus-block'>
          <StarmusSlider>
            {
              [...Array(9).keys()].slice(1).map(index => (
                <MarsAwakensComponent key={`mars-awakens-${index}`}
                                      imagesrc={require(`../../assets/images/mars-awakens/mars-awakens-${index}.jpg`)}/>
              ))
            }
            <div className='mars-volkano-slide'>
              <picture>
                <source srcSet={MarsVolkanoImage1} media="(min-width: 800px)"/>
                <img className='cover-parent' src={MarsVolkanoImage1} alt='Mars Awakens'/>
              </picture>
              <p className='mars-volkano-text starmus-p'>
                The Martian canyon Valles Marineris is
                <strong> 4,000 km</strong> long,
                <strong> 200 km</strong> wide,
                and almost <strong>7 km</strong> deep.
              </p>
            </div>
            <div className='mars-volkano-slide'>
              <picture>
                <source srcSet={MarsVolkanoImage2} media="(min-width: 800px)"/>
                <img className='cover-parent' src={MarsVolkanoImage2} alt='Mars Awakens'/>
              </picture>
              <p className='mars-volkano-text starmus-p'>
                Mars volcano <strong>Olympus Mons </strong>
                (25 km high) is three times the height
                of <strong>Mt. Everest</strong> and
                is the largest in the Solar system.
              </p>
            </div>
          </StarmusSlider>
          <ScrollDown to='mission-to-mars-section' containerId="main"/>
        </div>
      </section>
    );
  }
};

export default MarsAwakens