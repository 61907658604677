import React, {Component} from "react";
import css_classes from '../style.module.css';
import section5_img1 from '../images/section5_img1.jpg';
import section5_img2 from '../images/section5_img2.jpg';
import section5_img3 from '../images/section5_img3.jpg';
import section5_img4 from '../images/section5_img4.jpg';
import section5_img5 from '../images/section5_img5.jpg';
import {ImgGrid} from "../../components/ImgGrid";
class Section5 extends Component{
    constructor(props) {
        super();
    }
    render(props){
        let images =[
            {url:section5_img1},
            {url:section5_img2},
            {url:section5_img3},
            {url:section5_img4},
            {url:section5_img5},
        ]
        return <div className={css_classes.section5}>

            <ImgGrid images={images} />
            {/*<div className={"am_container  "+css_classes.section5_grid}>*/}
            {/*    <img className={css_classes.section5_grid_item_1} src={section5_img1}/>*/}
            {/*    <img className={css_classes.section5_grid_item_2} src={section5_img2}/>*/}
            {/*    <img className={css_classes.section5_grid_item_3} src={section5_img3}/>*/}
            {/*    <img className={css_classes.section5_grid_item_4} src={section5_img4}/>*/}
            {/*    <img className={css_classes.section5_grid_item_5} src={section5_img5}/>*/}
            {/*</div>*/}
        </div>;
    }
}
export default Section5;