import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import Configs from "../../configs";
import S7Sponsors from "../components/S7Sponsors";
import Welcome from "./Welcome";
import ProgramItem from "./ProgramItem";
import ProgramItemTitle from "./ProgramItemTitle";
import S7Medal from "./S7Medal";
import StarParty from "./StarParty";
import InnerHeader from "../components/InnerHeader";
import CampProgram from "./CampProgram";
import CityProgram from "./CityProgram";

class S7ProgramItemPage extends Component {


    constructor(props) {
        super();
        this.state = {
            data: [],
            selectedDate: null,
            title: null,
            program:null
        }
    }
    componentDidMount() {

        const {match: {params: {id}}} = this.props;
        this.getData(id);

    }

    getData(id) {
        fetch(`${Configs.api_url}data/get-day-info?id=`+id)
            .then(response => response.json())
            .then(data => {
                    this.openProgram(data.data)
                }
            );

    }

    openProgram(program) {
        let dateId = parseInt(program.id);
        let dateInfo = JSON.parse(program.info);
        let newState = {
            title: dateInfo.title,
            program:program
        }
        switch (dateId) {
            case 11:
                newState.selectedDate = <Welcome/>;
                break;
            case 13:
                newState.selectedDate = <StarParty program={program}/>;
                break;
            case 14:
                newState.selectedDate = <S7Medal program={program}/>;
                break;
            default:
                newState.selectedDate = <ProgramItem program={program}/>;

        }
        this.setState(newState)
    }

    closeProgram() {
        this.setState({selectedDate: null})
    }

    render() {
        if(!this.state.selectedDate){
            return '';
        }
        return (
            <div className={"am_wrapper page_main " + css_classes.programContent}>
                <InnerHeader/>
                <div className={css_classes.abs_ticket+' '+(this.state.selectedDate?css_classes.abs_ticket_inner:"")} >
                    <a href={'https://www.ticketportal.sk/event/STARMUS-VII?idpartner=1017&lang=US'} target={"_blank"} className={css_classes.buyTicket+' '+css_classes.buyTicketInner}>Buy Ticket</a>
                </div>
                {
                    this.state.selectedDate && <div className={css_classes.pItemInner}>
                        <ProgramItemTitle title={this.state.title}  closeHandler={this.closeProgram}/>
                        {this.state.selectedDate}
                    </div>
                }
                {this.state.program.id!=11 &&
                    <div>
                        { this.state.program && <CampProgram data={this.state.program.camp_program}/>}
                        { this.state.program && <CityProgram data={this.state.program.city_program}/>}
                    </div>

                }
                <S7Sponsors></S7Sponsors>
            </div>
        );
    }
}

export default S7ProgramItemPage;
