import React from 'react';
import './css/main.css';
import './css/am.css';
import './css/mui.css';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from "./App";


ReactDOM.render((
    <App/>
), document.getElementById('root'));
serviceWorker.unregister();
