import styles from "../style.module.css";
import React, {useEffect, useRef} from 'react';
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Section3() {
    const sectionRef = useRef()
    const textRef1 = useRef()
    useEffect(()=>{
        /*gsap.fromTo(textRef1.current, {rotation:0},{
            rotation: 300,
            duration: 2,
            scrollTrigger: {
                trigger:sectionRef.current,
                start: "top bottom",
                scrub: true,


            },
        });*/
    },[]);
    return (
        <div ref={sectionRef} className={styles.section_3}>
            <div className={styles.section_3_bg_1}></div>
            <div className={styles.section_3_bg_2}></div>
            <div className={styles.section_3_bg_3}></div>
            <div className={"am_container flex align_items_center "+styles.section_3_text}>
                    <div className={styles.s_3_col}>
                        Climate change could be irreversible
                        <div ref={textRef1} className={styles.s_3_big_text}>by 2030</div>
                    </div>
                    <div className={styles.s_3_col+" "+styles.s_3_center_col}>
                        <div>by 2030</div>
                        <div>by 2050</div>
                    </div>
                    <div className={styles.s_3_col}>
                        There could be
                        <div className={styles.s_3_big_text}>1.2 billion</div> climate refugees
                        <div className={styles.s_3_big_text}>by 2050</div>
                    </div>
            </div>

        </div>
    );
}