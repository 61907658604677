import React, {Component} from "react";
import css_classes from '../style.module.css';
import {Follow} from "./Follow";
import SubscribeForm from "../../components/Forms/SubscribeForm";
import FaceBookIcon from "../images/soc_fb.png";
import TwitterIcon from "../images/soc_x.png";
import InstagramIcon from "../images/soc_insta.png";
import YouTubeIcon from "../images/soc_yt.png";
import TiktokIcon from "../images/soc_tiktok.png";

export class ButtonWithBg extends Component {

    constructor(props) {
        super();
        this.content = props.content;
    }

    render() {
        console.log(this.content);
        return <div className={css_classes.page + ' ' + css_classes.flex_column}>


            <div className={css_classes.button_with_bg_wrap} style={{backgroundImage:`url(${this.content.image})`}}>

                <div className={css_classes.title} dangerouslySetInnerHTML={{__html:this.content.title}}></div>
                <a href={this.content.description} className={css_classes.button_with_bg_btn} dangerouslySetInnerHTML={{__html:this.content.sub_title}}></a>
            </div>

        </div>
    }
}