import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class PageTemplate5 extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }
    render() {
        return <div className={css_classes.template_5_wrap+' '+css_classes.page} style={{backgroundImage:`url(${this.content.image})`}}>
            <div className={'am_container color_orange '}>
                <div className={css_classes.template_5_info}>
                    <div className={css_classes.title + ' ' + css_classes.template_5_title }   dangerouslySetInnerHTML={{__html: this.content.title}}></div>
                    <div className={css_classes.template_5_text_container}
                         dangerouslySetInnerHTML={{__html: this.content.content}}>
                    </div>
                </div>
            </div>
        </div>
    }
}