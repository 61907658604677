import React, {Component} from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import LandingPage from "./LandingPage";
import LocationPage from "./LocationPage";
import {StephenHawkingMedal} from "./StephenHawkingMedal";
import MainMenu from "./components/Menu";
import Footer from "./components/Footer";
import {AboutUs} from "./AboutUs";
import {Festival} from "./Festival";
import {Slovakia} from "./Slovakia";
import {Speakers} from "./Speakers";
import {Person} from "./Person";
import "slick-carousel/slick/slick.css";
import {Contacts} from "./Contacts";
import {Artists} from "./Artists";
import {QA} from "./QA";
import {Testimonials} from "./Testimonials";
import {Press} from "./Press";
import {PressDetails} from "./PressDetails";
import {WhyArmenia} from "./WhyArmenia";
import {MediaCoverage} from "./MediaCoverage";
import {TestimonialsDetails} from "./TestimonialDetails";
import {Tickets} from "./Tickets";
import {Content, ContentWithRouter} from "./Content";
import {Kemurdzhian} from "./Kemurdzhian";
import {StarmusArmenia} from "./StarmusArmenia";
import {NewTickets} from "./StarmusArmenia/NewTickets";
import {Program} from "./StarmusArmenia/Program";
import {SimplePage} from "./SimplePage";
import {Banners} from "./StarmusArmenia/Banners";
import {BannerContent} from "./BannerContent";
import {NewsSlider} from "./components/NewsSlider";
import {Partners} from "./StarmusArmenia/Partners";
import {CityProgram} from "./StarmusArmenia/CityProgram";
import {Starmus7} from "./Starmus7";
import LandingPageArm from "./LandingPageArm";
import {PageNotFound} from "./NotFound";
import CookiesAlert from "./components/CookiesAlert/CookiesAlert";
import ProgramNew from "./Starmus7/ProgramNew";
import S7ProgramItemPage from "./Starmus7/ProgramNew/S7ProgramItemPage";
import S7Partners from "./Starmus7/S7Partners";
import S7PartnerPage from "./Starmus7/S7Partners/S7PartnerPage";
import {Starmus8} from "./Starmus8";
import {Page} from "./Page";
// import LandingPageArm from "./LandingPageArm";
// import "slick-carousel/slick/slick-theme.css";
// import ReactGA from 'react-ga';
// const TRACKING_ID = "G-6H6ESW84QH";
export default class App extends Component{
    /*componentDidMount() {
        ReactGA.initialize(TRACKING_ID);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }*/

    constructor(props) {
        super();
        this.closePopup = this.closePopup.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.state = {popupClass: '',popupBody: '',menuClass:'dark'};
        this.updateMenuClass = this.updateMenuClass.bind(this);
        // this.updateMenuClass('dark');
    }

    openPopup(html){
        this.setState({popupBody:html})
        this.setState({popupClass:'open'})
    }
    closePopup(){
        this.setState({popupClass:'',popupBody:''})
    }
    updateMenuClass(className){
        this.setState({menuClass:className});
    }
    render(){
        return <React.StrictMode>
            {/*<WaitForLoading ref={Loader => {
                window.Loader = Loader;
            }}/>*/}
            <BrowserRouter >
                <Switch>


                    <>
                    <div className={'am_popup '+this.state.popupClass}>
                        <div className={'am_popup_content'}>
                            <div className={'am_popup_close_button'} onClick={this.closePopup}></div>
                            <div className={'am_popup_body'} >
                                {this.state.popupBody}
                            </div>
                        </div>

                    </div>
                        <CookiesAlert />
                <main id="main" className="wrapper">
                    <MainMenu menuClass={this.state.menuClass}/>
                    <ContentWithRouter updateMenuClass={this.updateMenuClass}>
                        <Route exact path="/" render={(props) => {
                            props.match.params.slug="_home";
                            return <Page {...props}
                                  openPopup={this.openPopup}/>
                        }}/>


                        <Route path="/p/:slug" render={(props) => (<Page {...props} openPopup={this.openPopup}/>)}/>
                        <Route path="/about-us" component={AboutUs}/>
                        <Route path="/stephen-hawking-medal" component={StephenHawkingMedal}/>
                        <Route path="/festival/:id" component={Festival}/>
                        <Route path="/speakers" component={Speakers}/>
                        <Route path="/testimonials" component={Testimonials}/>
                        <Route path="/testimonials-item/:id" component={TestimonialsDetails}/>
                        <Route path="/artists" component={Artists}/>
                        <Route path="/person/:id" component={Person}/>
                        <Route path="/press/:slug" component={Press}/>
                        <Route path="/press-item/:id" component={PressDetails}/>
                        <Route path="/contacts" component={Contacts}/>
                        <Route path="/media-coverage" component={MediaCoverage}/>
                        <Route path="/terms-of-use" component={() => (<SimplePage id="484" />)}/>
                        <Route path="/media-accreditation" component={() => (<SimplePage id="487" />)}/>
                        <Route path="/privacy-policy" component={() => (<SimplePage id="483" />)}/>

                        {/*<Route exact path="/" render={(props) => (<Starmus8 updateMenuClass={this.updateMenuClass} openPopup={this.openPopup}/>)}/>*/}
                        {/*<Route path="/" component={AboutUs}/>*/}
                        {/*<Route path="/" component={Starmus8}/>*/}

                        {/*ARMENIA*/}
                        <Route path="/location"  component={LocationPage}/>
                        <Route path="/starmus6-armenia" component={StarmusArmenia}/>
                        {/*<Route path="/Armenia" component={StarmusArmenia}/>*/}
                        <Route path="/LandingPageArm" component={LandingPageArm}/>
                        <Route path="/kemurdzhian" component={Kemurdzhian}/>
                        <Route path="/why-armenia" component={WhyArmenia}/>
                        <Route path="/program-armenia" component={Program}/>
                        <Route path="/banners" component={Banners}/>
                        <Route path="/city-program" component={CityProgram}/>
                        <Route path="/banner-content/:slug" component={BannerContent}/>
                        <Route path="/amnews" component={NewsSlider}/>
                        {/*<Route path="/ticket/:action/:id?"
                               render={(props) => (
                                   <Tickets {...props} updateMenuClass={this.updateMenuClass} />
                               )}
                        />*/}
                        {/*<Route path="/partners" component={Partners}/>*/}
                        {/*<Route path="/qa" component={QA}/>*/}


                        <Route path="/program-new" component={ProgramNew}/>
                        <Route path="/program-item-page/:id" component={S7ProgramItemPage}/>

                        <Route path="/s7-partners" component={S7Partners}/>
                        <Route path="/s7-partner/:id" component={S7PartnerPage}/>

                        <Route path="/Slovakia" component={Slovakia}/>
                        <Route path="/starmus7"
                               render={
                                   (props) => (
                                       <Starmus7 updateMenuClass={this.updateMenuClass} openPopup={this.openPopup}/>
                                   )
                               }/>


                    </ContentWithRouter>
                    <Footer/>

                </main>
                    </>
                </Switch>
            </BrowserRouter>
        </React.StrictMode>;
    }
}
export {App};