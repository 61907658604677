import React, {Component} from 'react';
import './slider.css'

class Slider extends Component {
    constructor(props) {
        super(props);
        this.slideCount = React.Children.count(this.props.children);
        this.state = {activeSlide: 0}
    }

    setTimer = () => {
        this.timer = setInterval(
            () => this.nextItem(), 5000,
        );
    };

    nextItem = () => {
        if(this.state.activeSlide+1 == this.slideCount){
            clearInterval(this.timer);
            this.setState({activeSlide:0});
            this.timeout=setTimeout(this.setTimer,10000)
        }else{
            this.setState({activeSlide: (this.state.activeSlide + 1) % this.slideCount});
        }

    };


    componentDidMount() {
        this.setTimer();
    };

    componentWillUnmount() {
        clearInterval(this.timer);
        clearInterval(this.timeout);
    };

    render() {
        return (
            <div className='slideshow-block'>
                {
                    React.Children.map(this.props.children, (Child, index) => (
                        <div key={`starmus_slider_${index}`} className={this.state.activeSlide === index ? 'slider-item opaque' : 'slider-item'}>
                            {Child}
                        </div>
                    ))
                }
            </div>
        )
    }
};

export default Slider;