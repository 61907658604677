import React, {Component} from "react";
import css_classes from './style.module.css'
import {NewTicketItem} from "./NewTicketItem";
import MyModal from "../components/Modal";
import Configs from "../configs";
import astro from "./images/astro.png";
import star_party from "./images/star_party.png";
import welcom from "./images/welcom.png";
import {Link} from "react-router-dom";

export class NewTickets extends Component {
    constructor(props) {
        super();
        this.state = {
            iframeUrl: null,
            infoModalText: null,
            isVisibleTicketModal: false,
            isVisibleInfoModal: false,
            tickets: [],
            group_url: ''
        }


        this.openTicketModal = this.openTicketModal.bind(this);
        this.closeTicketModal = this.closeTicketModal.bind(this);
        this.openInfoModal = this.openInfoModal.bind(this);
        this.closeInfoModal = this.closeInfoModal.bind(this);
        this.resizeIframe = this.resizeIframe.bind(this);
    }

    componentDidMount() {
        this.getConfigs();
    }

    getConfigs() {
        // Loader.startLoading();
        fetch(`${Configs.api_url}data/get-configs`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        configs: data.data,
                        tickets: JSON.parse(data.data.tickets),
                    })
                }
            );
    }

    resizeIframe() {
        const obj = document.getElementById('tomsarkgh_iframe');
        obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
    }

    openTicketModal(url) {
        document.getElementById("main").classList.remove("wrapper");
        document.body.classList.add('overlay');

        this.setState({
            iframeUrl: url + '&dt=' + Math.floor(Math.random() * 100),
            isVisibleTicketModal: true
        })
    }

    closeTicketModal() {
        document.getElementById("main").classList.add("wrapper");
        document.body.classList.remove('overlay');

        this.setState({
            isVisibleTicketModal: false
        })
    }

    openInfoModal(text) {
        document.getElementById("main").classList.remove("wrapper");
        document.body.classList.add('overlay');
        this.setState({
            infoModalText:text,
            isVisibleInfoModal: true
        })
    }
    closeInfoModal() {
        document.getElementById("main").classList.add("wrapper");
        document.body.classList.remove('overlay');
        this.setState({
            isVisibleInfoModal: false
        })
    }
    render() {
        if (this.state.tickets.length == 0) {
            return '';
        }
        return (<React.Fragment>
            <div className={css_classes.tickets_section + ' border_box'}>
                <div className={css_classes.tickets_section_inner}>
                    <div className={css_classes.tickets_section_title}> EVENT TICKETS</div>
                    <div className={css_classes.two_tickets}>
                        <NewTicketItem openTicketModal={this.openTicketModal} openInfoModal={this.openInfoModal}
                                       data={this.state.tickets[0]}></NewTicketItem>

                        <NewTicketItem openTicketModal={this.openTicketModal} openInfoModal={this.openInfoModal}
                                       data={this.state.tickets[1]}></NewTicketItem>
                        <NewTicketItem openTicketModal={this.openTicketModal}  openInfoModal={this.openInfoModal}
                                       data={this.state.tickets[3]}></NewTicketItem>
                    </div>
                    {/*<div className={css_classes.tickets_section_title+' '}> EXTRA</div>*/}
                    {/*<NewTicketItem openTicketModal={this.openTicketModal} data={this.tickets[2]} type={'full'}></NewTicketItem>*/}

                </div>
                <div className={"text_center"}><span onClick={()=>{
                    this.openInfoModal('<div>' +
                        '<div class="text_center ticket_info_modal_title">'+this.state.tickets[0].title+'</div>' +
                        '<div>'+this.state.tickets[0].text+'</div>' +
                        '<div class="text_center ticket_info_modal_title">'+this.state.tickets[1].title+'</div>' +
                        '<div>'+this.state.tickets[1].text+'</div>' +
                        '<div class="text_center ticket_info_modal_title">'+this.state.tickets[3].title+'</div>' +
                        '<div>'+this.state.tickets[3].text+'</div>' +

                        '</div>')
                }} className={css_classes.tt_button_3}>DETAILS</span></div>
                <div className={'flex '+css_classes.tickets_3}>
                    <div className={css_classes.t_new_wrap}>
                        <div className={"text_left " + css_classes.t_new}>
                            <img src={astro} className={css_classes.tt_image}/>
                            <div className={css_classes.t_new_inner}>
                                <div className={css_classes.tt_1}>ASTROPHOTOGRAPHY SCHOOL</div>
                                <div className={css_classes.tt_2}>31 Aug - 2 Sep</div>

                                <div className={css_classes.tt_7}>Apricot Aghveran Resort</div>
                                <div className={css_classes.tt_7}>Astrophotography school is a unique event for enthusiasts who want to learn about imaging the sky and also have access to one of the darkest skies on Earth with their own equipment.
                                    For three days prior the main Festival Starmus VI, participants will stay in Aghveran and get unique experience.</div>
                                <Link to={'/banner-content/astrophotography-school'} className={css_classes.tt_button_2} >ABOUT THE EVENT</Link>
                                <div className={css_classes.tt_4} onClick={() => {
                                    this.openTicketModal('https://www.tomsarkgh.am/order/widgetUrl?eventId=45696&referral_id=starmus.com&sf_culture=en')
                                }}>BUY TICKET
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"text_left " + css_classes.t_new_wrap}>
                        <div className={"text_left " + css_classes.t_new}>
                            <img src={welcom} className={css_classes.tt_image}/>
                            <div className={css_classes.t_new_inner}>
                                <div className={css_classes.tt_1}>WELCOME CONCERT</div>
                                <div className={css_classes.tt_2}>4 September</div>
                                <div className={css_classes.tt_7}>Sport-Concert Complex, Concert Hall</div>
                                <div className={css_classes.tt_5}>TIGRAN HAMASYAN</div>
                                <div className={css_classes.tt_6}> The Call Within</div>
                                <div className={css_classes.tt_7}>Marc Karapetian <span
                                    className={css_classes.tt_8}>(bass)</span>, Arthur Hnatek <span
                                    className={css_classes.tt_8}>(drums)</span></div>
                                <div className={css_classes.tt_9}></div>
                                <div className={css_classes.tt_10}>DEREK SHERINIAN</div>
                                <div className={css_classes.tt_8}>featuring</div>
                                <div className={css_classes.tt_7}>Simon Phillips <span
                                    className={css_classes.tt_8}>(drums)</span>, Ric Fierabracci <span
                                    className={css_classes.tt_8}>(bass)</span></div>
                                <div className={css_classes.tt_9}></div>
                                <div className={css_classes.tt_10}>EDVARD MOSER</div>
                                <div className={css_classes.tt_8}>(Nobel Laureate Neurophysics)</div>
                                <Link to={'/banner-content/welcome-concert'} className={css_classes.tt_button_2} >ABOUT THE EVENT</Link>
                                <div className={css_classes.tt_4} onClick={() => {
                                    this.openTicketModal(this.state.tickets[2]['url'])
                                }}>BUY TICKET
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"text_left " + css_classes.t_new_wrap}>
                        <div className={"text_left " + css_classes.t_new}>
                            <img src={star_party} className={css_classes.tt_image}/>
                            <div className={css_classes.t_new_inner}>
                                <div className={css_classes.tt_1}>STAR PARTY</div>
                                <div className={css_classes.tt_2}>8 September</div>
                                {/*<div className={css_classes.tt_5}>COMING SOON</div>*/}
                                <div className={css_classes.tt_7}>
                                    Star Party Starmus VI will be held in Garni Temple. More than twenty professional telescopes will be available for guests so they can enjoy stargazing, live music, delicious wines and connect with the stars.</div>

                                <div className={css_classes.tt_9}></div>
                                <div className={css_classes.tt_10}>NOSOUND SEBU (CAPITAL CITIES)</div>
                                <Link to={'/banner-content/starparty'} className={css_classes.tt_button_2} >ABOUT THE EVENT</Link>
                                <div className={css_classes.tt_4} onClick={() => {
                                    this.openTicketModal('https://www.tomsarkgh.am/order/widgetUrl?eventId=45752&referral_id=starmus.com&sf_culture=en')
                                }}>BUY TICKET
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*<a href={this.group_url} className={css_classes.group_tickets_button}>GROUP TICKETS</a>*/}
                <MyModal show={this.state.isVisibleTicketModal} handleClose={this.closeTicketModal}>
                    <div className={css_classes.ticket_iframe_wrap}>
                        <iframe
                            sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-top-navigation"
                            id={"tomsarkgh_iframe"} src={this.state.iframeUrl}/>
                    </div>

                </MyModal>

                <MyModal show={this.state.isVisibleInfoModal} handleClose={this.closeInfoModal}>
                    <div className={css_classes.partner_modal_inner}>
                        <div dangerouslySetInnerHTML={{__html:this.state.infoModalText}}></div>
                    </div>
                </MyModal>
            </div>

        </React.Fragment>)
    }
}