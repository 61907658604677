import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {Switcher} from "../components/Switcher/Switcher";
import TestimonialItem from "../components/TestimonialItem";

export class MediaCoverage extends Component {

    constructor(props) {
        super();
        this.state = {data:[]}

    }

    componentDidMount() {
        this.getData();
    }
    getData = ()=> {
        fetch(`${Configs.api_url}data/get-testimonials?category_id=7`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        data: data.data,
                    });
                }
            );
    }

    render() {

        let content = [];
        for (let item_info of this.state.data) {
            content.push(<div className={'col6 ' +css_classes.item}>
                <a target={'_blank'} href={item_info.sub_title} className={css_classes.item_inner}>
                    <img src={item_info.image} className={css_classes.image}/>
                    <div className={css_classes.item_title}>
                        {item_info.title}
                    </div>
                </a>
            </div>)
        }


        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={css_classes.section_1}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.section_1_title}>Media Coverage</div>
                    </div>
                </div>
                <div className={'section_60 am_container text_center'}>

                    <div className={'row flex_wrap '+css_classes.data_list}>
                        {content}
                    </div>
                </div>
            </div>
        </React.Fragment>


    }
}
