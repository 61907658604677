import React, {Component} from "react";
import Slider from "react-slick";

import css_classes from "./style.module.css";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Link} from "react-router-dom";

export class NewsSliderItem extends Component {
    constructor(props) {
        super();
        this.data = props.data;
    }

    render() {
        return <div className={css_classes.item_wrap}>
            <img src={this.data.image} className={css_classes.img}/>
            <div className={css_classes.title_wrap}>
                <div className={css_classes.title}>{this.data.title}</div>
                <div className={css_classes.date}>{this.data.date}</div>
            </div>
            <div className={css_classes.desc} dangerouslySetInnerHTML={{__html: this.data.description}}>
            </div>
            <Link to={'/press-item/'+this.data.id} className={css_classes.learn_more}>Learn more</Link>
        </div>
    }
}