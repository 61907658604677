import React, {Component} from 'react';
import styles from "../style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
import s4_1 from "../images/s4_1.png";
class S8Section4 extends Component {
    render() {
        return (
            <div className={styles.section}>
                <div className={'am_container flex flex_column_992 '+styles.revers_on_small}>
                    <div className={styles.text_section}>
                        <div className={styles.title_45+' color_orange'}>  STARMUS…WHAT’S NEXT? </div>
                        <p className={styles.p}></p>
                        <p className={styles.p}>
                            Starmus is now preparing for the celebration of a very special edition STARMUS La Palma (April 26-30, 2025), with the aim of boosting the recovery of the island after the disaster caused by the volcanic eruption of 2021 that devastated more than 15,000 hectares of territory destroying more than XXX homes in its path. The celebration of the festival will consolidate La Palma worldwide as "the island of the stars". A place as magical as it is deserved given the privileged conditions of the island, the first Starlight Reserve in the world, for the observation and study of the night sky. STARMUS La Palma will bring together leading figures from the world of science and the space race and major international artists to pay tribute to the island and to address the great challenges we face in terms of space debris, light pollution and a special nod to the centenary of the birth of quantum mechanics and its application in astronomy.
                        </p>

                    </div>
                    <div className={styles.img_section+' '+styles.img_section_right}>
                        <div className={styles.relative}>
                            <img  src={s4_1} alt={"s4_1"}/>
                            <div className={styles.img_copyright}>Photo: Daniel Lopez / IAC</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default S8Section4