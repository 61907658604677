import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import medalsImage from "./images/medals.png"
import absBg from "./images/medal_section_bg.jpg"
import ProgramItem from "./ProgramItem";
import program_css from "../program.module.css";
import inner_css from "../inner.module.css";

class StarParty extends Component {
    constructor(props) {
        super();
        this.program = props.program;
        this.info = JSON.parse(props.program.info);
        this.desc = this.program.desc.split('|')
        this.starPartyInfo = JSON.parse(props.program.camp_program)["star_party"];
    }

    drawRow(data, index) {
        let mainCLass = css_classes.pItemGroupRow;
        if (index == 0) {
            mainCLass = css_classes.pItemGroupTitle;
        }
        let extraClass, text = '';
        if (data.hasOwnProperty('className')) {
            extraClass = css_classes[data.className]
            text = data.text;
        } else {
            text = data;
        }
        return <div key={'k2' + index} className={mainCLass + ' ' + extraClass}>{text}</div>
    }

    render() {
        return (
            <>
                <ProgramItem program={this.program}></ProgramItem>
                <div className={css_classes.starPartyBg}>
                    <div className={program_css.title_bar}>
                        <div className={inner_css.ripa_container + ' ' + program_css.title_bar_inner}>
                            <div className={program_css.title_bar_big}> STAR PARTY</div>
                        </div>
                    </div>
                    <div className={css_classes.ripa_container + ' ' + css_classes.starPartyInner}>


                        <div className={css_classes.starPartyProgram}>
                            {this.starPartyInfo.map((item,index)=>{
                                return <div className={css_classes.starPartyGroup}>
                                    {item.houre && <div>{item.houre}</div>}
                                    <div className={inner_css.color_orange}>{item.text}</div>
                                </div>
                            })

                        }
                        </div>

                        <div className={css_classes.starPartyText+' '+css_classes.color_blue}>
                            <div className={css_classes.starPartyRow1}>17:00</div>
                            <div className={css_classes.starPartyRow2}>DEVÍN CASTLE</div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default StarParty;
