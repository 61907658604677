import React, {Component} from 'react';
import StarmusSlider from '../../components/Slider/StarmusSlider';
import '../css/footer_slider.css';


class FooterSlider extends Component {
  render() {
    return (
      <section className='footer-slider-section'>
        <div className='footer-slider-block'>
          <StarmusSlider>
            {
              [...Array(8).keys()].map(index => (
                <div className='footer-slide'>
                  <picture>
                    <source srcSet={require(`../../assets/images/location_footer_slider/about_armenia_${index}.jpg`)}
                            media="(min-width: 800px)"/>
                    <img className='cover-parent'
                         src={require(`../../assets/images/location_footer_slider/about_armenia_${index}.jpg`)}
                         alt='Location Armenia'/>
                  </picture>
                </div>
              ))
            }
          </StarmusSlider>
        </div>
      </section>
    );
  }
};

export default FooterSlider;