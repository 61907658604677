import React, {Component} from "react";
import Slider from "react-slick";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Figure from "../Figure";
import css_classes from "../../Festival/style.module.css";
export class PersonSlider extends Component{
    constructor(props) {
        super();
        this.data=props.data
    }
    sliders() {
        return this.data.map(data => {
            return (
                <Figure className="test" info={data} key={data.id}/>
            )
        });
    }
    render() {
        var settings = {
            speed: 500,
            arrows:true,
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite:false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


        return (

            <Slider {...settings}>
                {this.sliders()}
            </Slider>

        );
    }
}