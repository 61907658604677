import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {PersonSlider} from "../components/PersonSlider";
import {Program} from "../StarmusArmenia/Program";
import header_default_image from "../images/default_header_image.jpg"
import {Link} from "react-router-dom";


export class BannerContent extends Component {
    constructor(props) {
        super();
        this.state = {
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.slug!== this.props.match.params.slug) {
            document.getElementById('main').scrollTo(0,0);
            const slug = nextProps.match.params.slug
            this.getBannerContent(slug)
        }
    }
    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        const {match: {params: {slug}}} = this.props;
        this.getBannerContent(slug);

    }

    getBannerContent(slug){
        fetch(`${Configs.api_url}data/get-banner-content?slug=${slug}`)
            .then(response => response.json())
            .then((data) => {
                this.setState({
                    banner:data.data.banner,
                    program:data.data.program,
                    speakers:data.data.speakers,
                    artists:data.data.artists,
                    files:data.data.files
                });
            });
    }


    render() {
        if (this.state.banner !== undefined) {
            let files='';
            if(this.state.files.length){
                files = this.state.files.map((file)=>{
                    return <div className={css_classes.event_image_wrap}>
                        <img src={file.url} alt={file.title} className={css_classes.event_image}/>
                    </div>
                })
            }
            const location = (this.state.banner.location)?<div className={Number(this.state.banner.group_id) === 0 ? css_classes.banner_item_location : css_classes.baner_item_location_2}>{this.state.banner.location}</div>:'';
            return <React.Fragment>
                <div className={"am_wrapper page_main new_style"}>
                    <div className={css_classes.header_wrap}>
                        <img src={header_default_image} className={css_classes.event_header_image}/>
                        <div className={css_classes.title_and_date}>
                            <div className={"am_container "}>
                                <div className={'text_1040 '+css_classes.title_and_date_inner}>
                                    <div className={css_classes.title}>{this.state.banner.title}</div>
                                    <div className={css_classes.banner_item_date}>
                                        <div className={css_classes.banner_item_day}>{this.state.banner.date_part}</div>
                                        <div className={css_classes.banner_item_month} dangerouslySetInnerHTML={{__html:this.state.banner.month_part}}></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                    <div style={{ paddingLeft: "2rem" }}  className={"am_container back_content"}>
                        <div className={'text_1040 '}>
                            {location}
                            {(this.state.banner.group_id!=0)&&<p style={{ fontWeight: "normal", paddingBottom:"1rem", fontSize:"2rem"}}>{this.state.banner.subtitle}</p>}
                        </div>

                        <div className={"text_1040"} dangerouslySetInnerHTML={{__html:this.state.banner.content}}></div>

                        {(this.state.banner.ticket_link)?<div className={'text_center'}>
                            <Link to={"/tickets"} className={css_classes.new_button}>BUY TICKET</Link>
                        </div>:''}
                        {(this.state.banner.group_id==0)?
                        <div className={'text_1040 '}>
                            {this.state.banner.cover_image?<img src={this.state.banner.cover_image} />:'' }
                        </div>:''}

                    </div>

                    <Program data={this.state.program}/>

                    {this.state.speakers.length > 0 ? <div className={' section_60'}>
                        <div className={'am_container '}>
                            <div className={'section_title black '}>Speakers</div>
                            <div className={css_classes.person_slider_wrap}>
                                <PersonSlider key={'speakers'} data={this.state.speakers}/>
                            </div>
                        </div>
                    </div> : ''}
                    {this.state.artists.length > 0 ? <div className={' section_60'}>
                        <div className={'am_container '}>
                            <div className={'section_title black '}>Artists</div>
                            <div className={css_classes.person_slider_wrap}>
                                <PersonSlider key={'artists'} data={this.state.artists}/>
                            </div>
                        </div>
                    </div> : ''}
                    {this.state.files.length > 0 ? <div className={'am_container '+css_classes.event_image_list}>
                        {files}

                    </div> : ''}
                </div>
            </React.Fragment>
        }
        return '';


    }
}
