import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class OnlyImage extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }
    render() {
        return <div className={css_classes.page +' '+css_classes.content_bg} style={{backgroundImage:`url(${this.content.bg_image})`}}>
            <div className={css_classes.content_bg_mobile+' '+css_classes.centered_info_wrap}  style={{backgroundImage:`url(${this.content.mobile_bg_image})`}}>

            </div>
        </div>
    }
}