import React, {Component} from 'react';
import LocationBlock from "./LocationBlock";
import '../css/about_armenia.css';

import imageSrcArmenia from '../../assets/images/about-armenia.jpg';
import imageSrcArmenia375 from '../../assets/images/375/about-armenia.jpg';
import imageSrcYerevan from '../../assets/images/about-yerevan.jpg';
import imageSrcYerevan375 from '../../assets/images/375/about-yerevan.jpg';
import imageSrcTravel from '../../assets/images/travel-armenia.jpg';
import imageSrcTravel375 from '../../assets/images/375/travel-armenia.jpg';

const aboutArmeniaTextList = ['Armenia, officially the Republic of Armenia is a landlocked country in the South Caucasus region of Eurasia. It is located in Western Asia, on the Armenian Highlands, it is bordered by Turkey to the west, Georgia to the north, the de facto independent Republic of Artsakh and Azerbaijan to the east, and Iran and Azerbaijan\'s exclave of Nakhchivan to the south.',
  'Armenia became the world\'s first Christian country more than 1,700 years ago in 301 AD and has a large Diaspora all over the world. As a former Soviet republic lying in the Caucasus region straddling Asia and Europe, Armenia has a rich and ancient culture. The Armenian alphabet is an alphabetic writing system used to write Armenian. It was developed around 405AD by Mesrop Mashtots, an Armenian linguist and ecclesiastical leader. The system originally had 36 letters; eventually, three more were adopted. The kingdom rose to power in the mid-9th century BC , but went into gradual decline and was eventually conquered by the Iranian Medes in the early 6th century BC.',
  'The geopolitical region would re-emerge as Armenia shortly after. The peoples of Urartu are the earliest identifiable ancestors of the Armenians. Armenia, officially the Armenian Soviet Socialist Republic, also commonly referred to as Soviet Armenia, was one of the constituent republics of the Soviet Union in December 1992 located in the South Caucasus region of Eurasia. It was established in December 1920, when the Soviets took over control of the short-lived First Republic of Armenia and lasted until 1991.'];

const aboutYerevanTextList = ['Armenia’s capital, Yerevan, is one of the world’s oldest continually inhabited cities—29 years older than Rome. Throughout its 2,800-year history, invasions, kingdoms have come and gone.Today, Yerevan is a city of one million, with a European ambience, lined with wide boulevards and sidewalk cafes.',
  'After independence from the Soviet Union in 1991, Armenia became a place of pride and pilgrimage for the world’s 7-10-million strong Armenian diaspora (far larger than Armenia’s population of around 3 million). Now, it welcomes more and more visitors annually.',
  'Yerevan is cosmopolitan, safe, and affordable—with museums, hotels, overly generous portions of food and wine, and even a night at the opera going for friendly prices.In the last few years, Armenia has granted citizens from a long list of countries, including the United States, visa-free entry. There are no fees or paperwork for visitors as long as they have a valid passport, and tourists can stay in the country for up to six months—a generous time-frame. Daily flights from Paris, Moscow, Vienna, Rome and Dubai cost around $200 round-trip.'];

const aboutTravelingTextList = [
    'Travelling to Armenia can be done: By plane Zvartnots International Airport 10 km west of Yerevan is the country\'s main airport. Shirak Airport in Gyumri has a few flights from Russia. By train There is an overnight train once every other day to Tbilisi, Georgia. By car It is possible to drive to Armenia via Iran or Georgia. The borders with Turkey and Azerbaijan are closed. Local travel agents can arrange transport to the border; some Georgian agents can arrange transport all the way through to Tbilisi. Although more expensive than a train or bus, a private car may be more comfortable and can be used for some sightseeing along the way.' +
    '<div><a style="margin-top: 20px; display: inline-block; text-align: center" class="switcher_button active" href="https://Starmus.ArmeniaTravel.am">BOOK TRAVEL SERVICE</a></div>'

];


class AboutArmenia extends Component {

  render() {
    return (
      <section className="location-section">
        <div className='container'>
          <LocationBlock imageLeft={false} title='About Armenia' text={aboutArmeniaTextList}
                         imageSrc={imageSrcArmenia} imageSrc375={imageSrcArmenia375}/>
        </div>
        <div className='container'>
          <LocationBlock imageLeft={true} title='About Yerevan' text={aboutYerevanTextList} imageSrc={imageSrcYerevan} imageSrc375={imageSrcYerevan375}/>
        </div>
        <div className='container'>
          <div className='getting-armenia-block'>
            <div>
              <h1 className='starmus-h2'>Getting to Armenia</h1>
              <p className='getting-armenia-p starmus-p'>Great things come in small packages... Whether by land or air,
                this ancient and
                small land of Armenia awaits you to explore its riches and begin your adventure.</p>
            </div>
          </div>
        </div>
        <div className='container'>
          <LocationBlock imageLeft={true} title='TRAVELING TO ARMENIA' text={aboutTravelingTextList}
                         imageSrc={imageSrcTravel} imageSrc375={imageSrcTravel375}/>
        </div>
      </section>
    );
  }
}

export default AboutArmenia;