import React,{Component} from "react";
import css_classes from '../style.module.css';
import styles from "../../Starmus8/style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
import Configs from "../../configs";
import {ProgramItem} from "../../StarmusArmenia/ProgramItem";

export  class Agenda extends Component{

    constructor(props) {
        super();
        this.festival_id = props.content.festival_id;
        this.title = props.content.title;
        this.state = {agenda:[]}
    }
    componentDidMount() {
        this.getData(this.festival_id)
    }
    getData(festival_id){
        // Loader.startLoading();
        // window.Loader.startLoading();
        fetch(`${Configs.api_url}data/get-agenda?festival_id=${festival_id}`)
            .then(response => response.json())
            .then(data => {
                console.log(data,'aaa')
                    // window.Loader.stopLoading();
                    this.setState({
                        agenda:data
                    })
                }
            );
    }


    render() {
        if (this.state.agenda.length == 0) {
            return '';
        }
        let index=0;
        let days=[];
        for(let key in this.state.agenda) {
            let data = this.state.agenda[key].program
            let persons = this.state.agenda[key].persons
            days.push(<ProgramItem key={'pi_l_' + index} data={data} persons={persons}/>)
            index++;
        }
        return <div className={css_classes.agenda_wrap+' '+css_classes.page}>
            <div className={'am_container'}>
                <div className={css_classes.title + ' text_center '+css_classes.title_black}
                     dangerouslySetInnerHTML={{__html: this.title}}></div>
                <div >

                    {days}
                </div>
            </div>
        </div>
    }
}