import React, {Component} from "react";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";

export class AboutUs extends Component{
    render() {
        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
            {/*<WaitForLoading/>*/}

            <Section1/>
            <Section2 />
            <Section4 />
            <Section5 />
            <Section6 />
            </div>
        </React.Fragment>
    }
}
