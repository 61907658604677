import React, {Component} from "react";
import css_classes from "./style.module.css"
import DeleteIcon from '@material-ui/icons/Delete';

export class TicketView extends Component {
    ticket_index = null;

    constructor(props) {
        super(props);
        let initialData = props.initialData;
        this.state={
            ticket:initialData,
            festDays:props.festDays
        }
    }

    componentDidMount() {
    }



    render() {
        let ticket_days='';
        let days = this.state.ticket.days;
        let festDays = this.state.festDays;
        for(let id in days){
            if(days[id]){
                for (let day of festDays){
                    if(day.id==id){
                        ticket_days+='<p>'+day.label+'</p>';
                    }
                }
            }
        }

        return <div className={"white_bg "+css_classes.ticket_view_wrap}>

            <div className={'row '}>
                <div className={css_classes.ticket_qr_wrap}>
                    <img src={this.state.ticket.qr_url} className={css_classes.ticket_qr_img} />
                </div>
                <div className={'col4 '+css_classes.ticket_text}>
                    <p className={css_classes.ticket_text_p}><span className={'text_bold'}>Name:</span> {this.state.ticket.name} </p>
                    <p className={css_classes.ticket_text_p}><span className={'text_bold'}>Email:</span> {this.state.ticket.email} </p>
                    <p className={css_classes.ticket_text_p}><span className={'text_bold'}>Phone:</span> {this.state.ticket.phone} </p>
                    <p className={css_classes.ticket_text_p}><span className={'text_bold'}>Address:</span> {this.state.ticket.address} </p>
                    {this.state.ticket.is_student?<div className={css_classes.is_student_info+' '+css_classes.view_student_info}>You will be asked to present your valid student ID</div>:''}
                </div>
                <div className={''}>
                    <div className={css_classes.ticket_price_wrap+' flex flex-column'}>
                        <div className={'text_bold'}>
                            {this.state.ticket.is_all_days?'You have chosen a ticket for the whole package (including the concert)':'You have chosen a ticket for these days'}
                        </div>
                        <div dangerouslySetInnerHTML={{__html:ticket_days}}></div>
                        <div className={css_classes.ticket_price}>
                            € {this.state.ticket.price}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}
