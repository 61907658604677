import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class PageTemplate6 extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }
    render() {
        return <div className={css_classes.black_bg_section+' '+css_classes.page} >
            <div className={'am_container '}>
                <div className={css_classes.title+' text_center'} dangerouslySetInnerHTML={{__html: this.content.title}}></div>
                <div className={css_classes.template_6_text_container}
                     dangerouslySetInnerHTML={{__html: this.content.content}}>
                </div>
                <div className={css_classes.template_6_images}>
                    {this.content.files.map(function (item){
                        return <div className={css_classes.template_6_images_item}><img src={item.url} alt={item.title} /></div>;
                    })}
                </div>

            </div>
        </div>
    }
}