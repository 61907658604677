import React, {Component} from "react";
import css_classes from './style.module.css'
import "./program_style.css";
import Configs from "../configs";
import {ProgramItem} from "./ProgramItem";
import {Banners} from "./Banners";

export class Program extends Component {
    constructor(props) {
        super();
        this.data=props.data;
        this.show_banner=(props.data)?0:1;
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        if(this.data){
            this.setState({
                data: this.data,
            })
        }else{
            fetch(`${Configs.api_url}data/get-program`)
                .then(response => response.json())
                .then(data => {
                        this.setState({
                            data: data.data,
                        })
                    }
                );
        }
        // Loader.startLoading();

    }

    render() {
        if (this.state.data.length == 0) {
            return '';
        }
        let left = [];
        let right = [];
        let index='';
        for(let key in this.state.data) {
            let value = this.state.data[key]
            left.push(<ProgramItem key={'pi_l_' + index} data={value}/>)
            right.push(<ProgramItem key={'pi_r_' + index} data={value}/>)
            index++;
        }
        return (<React.Fragment>
            {this.show_banner?<div className={css_classes.section_1}>
                <div className={css_classes.section_1_1}></div>
                <div className={css_classes.section_1_2}></div>
            </div>:''}

            <div className={'section_60'}>
                <div className={'am_container border_box'}>
                    <div className={'row'}>
                        <div className={'col6 program_col_left'}>{left}</div>
                        <div className={'col6 program_col_right'}>{right}</div>
                    </div>
                </div>
            </div>
            {this.show_banner?<Banners/>:''}

        </React.Fragment>);
    }
}