import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {PersonSlider} from "../components/PersonSlider";
import {VideoBg, VideoModal, VideoPlayButton} from "../components/VideoBg";
import TemplateVideo from "../Festival/Components/TemplateVideo";


export class Person extends Component {
    constructor(props) {
        super();
        this.state = {}
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            document.getElementById('main').scrollTo(0,0);
            const id = nextProps.match.params.id
            this.getPersonData(id)
        }
    }
    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        const {match: {params: {id}}} = this.props;
        this.getPersonData(id);

    }

    getPersonData(id){
        fetch(`${Configs.api_url}data/get-person?id=${id}`)
            .then(response => response.json())
            .then(data => this.setState({
                person: data.data.person,
            }));
    }


    render() {

        if (this.state.person !== undefined) {


            let video ='';
            let video_id=this.state.person.video_id;
            if(video_id){
                let content = {text:video_id,title:this.state.person.name}
                video = <TemplateVideo key={video_id} content={content}></TemplateVideo>
            }
            let festival_list=[];
            if(this.state.person.festivals.length>0){
                festival_list = this.state.person.festivals.map(function ( fest) {
                    return <div className={css_classes.fest_list_item}>{fest.title}</div>
                })
            }
            return <React.Fragment>
                <div className={"am_wrapper page_main"}>

                    <div className={css_classes.person_header}>
                        <div className={'am_container'}>
                            <div className={css_classes.person_avatar}>
                                <img className={css_classes.person_avatar_img} src={this.state.person.image} alt={this.state.person.name}/>
                            </div>
                            <div className={css_classes.person_header_data}>
                                <div className={css_classes.person_type}>{this.state.person.type}</div>
                                <div className={'section_title '+css_classes.person_name}>{this.state.person.name}</div>
                                <div className={css_classes.person_position}>{this.state.person.position}</div>
                            </div>
                        </div>
                    </div>
                    <div className={'am_container '}>

                        <div className={css_classes.fest_list}>
                            {festival_list}
                        </div>
                    </div>
                    <div className={'am_container '+css_classes.person_data}>
                        <div dangerouslySetInnerHTML={{__html:this.state.person.text}}></div>
                    </div>
                    {video}
                    <div className={'section_60 section_light_blue'}>
                        <div className={'am_container '}>
                            <div className={'section_title black '}>OTHER {this.state.person.type_text}</div>
                            <PersonSlider data={this.state.person.other_persons}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }
        return '';


    }
}
