import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import medalsImage from "./images/medals.png"
import absBg from "./images/medal_section_bg.png"
import program_css from "../program.module.css";
import inner_css from "../inner.module.css";
class S7Medal extends Component {
    constructor(props) {
        super();
        this.program = props.program;
        this.info = JSON.parse(props.program.info);
        this.desc = this.program.desc.split('|')
    }
    drawRow(data,index){
        let mainCLass = css_classes.pItemGroupRow;
        if(index ==0){
            mainCLass = css_classes.pItemGroupTitle;
        }
        let extraClass,text='';
        if(data.hasOwnProperty('className')){
            extraClass=css_classes[data.className]
            text = data.text;
        }else{
            text=data;
        }
        return <div key={'k2'+index} className={mainCLass+' '+extraClass}>{text}</div>
    }

    render() {
        return (
            <div className={css_classes.programInfo+' '+css_classes.medalProgramInfo}>
                <div className={css_classes.programDayBlue}>{this.info.title.title_1 || this.program.label}</div>
                <div className={program_css.title_bar}>
                    <div className={inner_css.ripa_container + ' ' + program_css.title_bar_inner}>
                        <div className={program_css.title_bar_big}> STARMUS MAIN stage</div>
                        <div className={program_css.title_bar_small}>
                            <div>18:30-21:00</div>
                            <div>Típos Aréna Stadium</div>

                        </div>
                    </div>
                </div>
                <div className={css_classes.ripa_container+' '+css_classes.m_top_50}>
                    <div className={css_classes.pItemGroupTitle}>STEPHEN HAWKING MEDAL AWARD CEREMONY</div>
                    <div className={css_classes.medal_section_1}>
                        <img src={medalsImage}/>
                        <div className={css_classes.medal_section_1_text}>
                            AWARDING EXCELLENCE IN
                            SCIENCE COMMUNICATIONS
                        </div>
                    </div>
                </div>
                <div className={css_classes.medal_section_2}>
                    <div className={css_classes.ripa_container}>
                        {this.info.groups.map((group,index)=>{
                                return <div key={'k1_'+index}className={css_classes.pItemListItem}>
                                    {group.map((row,index)=>{
                                        return this.drawRow(row, index);
                                    })}

                                </div>
                            })
                        }
                    </div>


                </div>
                <img src={absBg} className={css_classes.abs_bg} />
            </div>
        );
    }
}

export default S7Medal;
