import React, {Component} from 'react';
import styles from "../style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
class S8Section2 extends Component {
    render() {
        return (
            <div className={styles.section_2+' '+styles.section}>
                <div className={'am_container'}>
                    <div className={styles.text_section}>
                        <div className={styles.title_45+' color_orange'}> STARMUS is coming again …</div>
                       <div className={styles.title_40}>to Rock the Planet!</div>



                        <div className={styles.section_2_text}>
                            Subscribe to the "<span className={'color_blue'}>STARMUS Spotlight</span>"  newsletter series and get ready to be inspired  as we shine a spotlight on the latest updates,  exclusive content, upcoming events,  remarkable individuals,
                            and captivating  stories<br/>
                            that make STARMUS truly special.
                        </div>
                        <button className={styles.subscribe_button+' color_blue'} onClick={()=>{this.props.openPopup(<PreRegForm></PreRegForm>)}}>Subscribe</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default S8Section2;