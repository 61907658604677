import React, {Component} from "react";
import css_classes from '../style.module.css';
import {Follow} from "./Follow";
import SubscribeForm from "../../components/Forms/SubscribeForm";
import FaceBookIcon from "../images/soc_fb.png";
import TwitterIcon from "../images/soc_x.png";
import InstagramIcon from "../images/soc_insta.png";
import YouTubeIcon from "../images/soc_yt.png";
import TiktokIcon from "../images/soc_tiktok.png";

export class Subscribe extends Component {

    constructor(props) {
        super();
        this.content = props.content;
    }

    render() {
        return <div className={css_classes.page+' '+css_classes.flex_column+' '+css_classes.black_bg_section}>
                <div className={'am_container '+' '+css_classes.h100+' '+css_classes.subscribe_inner}>
                    <div className={css_classes.follow_wrap} style={{backgroundImage: `url(${this.content.image})`}}>
                        <div className={css_classes.soc_links_wrap}>
                            {this.content.title}
                            <div className={css_classes.soc_icons}>
                                <a href={"https://www.facebook.com/StarmusFestival"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={FaceBookIcon} className={css_classes.soc_icon} alt='Facebook'/>
                                </a>
                                <a href={"https://twitter.com/starmusfestival"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={TwitterIcon} className={css_classes.soc_icon} alt='Twitter'/>
                                </a>
                                <a href={"https://www.instagram.com/starmus/"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={InstagramIcon} className={css_classes.soc_icon} alt='Instagram'/>
                                </a>
                                <a href={"https://www.youtube.com/user/starmusfest"} target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={YouTubeIcon} className={css_classes.soc_icon} alt='YouTube'/>
                                </a>
                                <a href={"https://www.tiktok.com/@starmusfestival?_t=8lAfo1uRj3S&_r=1"}
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <img src={TiktokIcon} className={css_classes.soc_icon} alt='Tiktok'/>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className={css_classes.subscribe_wrap}>

                        <div className={css_classes.img_as_bg_wrap}>
                            {this.content.img &&
                                <img src={this.content.image} alt={'s'} className={css_classes.subscribe_bg}/>}
                            {!this.content.img && <div className={css_classes.subscribe_bg}></div>}
                            <div className={css_classes.img_as_bg_info + ' ' + css_classes.centered_info_wrap}>
                                <div className={'text_center'}>
                                    <div className={css_classes.subscribe_title}>{this.content.sub_title}</div>
                                    <button className={css_classes.subscribe_button} onClick={() => {
                                        this.props.openPopup(<SubscribeForm></SubscribeForm>)
                                    }}>Subscribe
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

        </div>
    }
}