import React,{Component} from "react";
import css_classes from './style.module.css'
import MyModal from "../components/Modal";
export class NewTicketItem extends Component{
    constructor(props) {
        super();
        this.data=props.data;
        this.type=props.type;
    }


    render() {
        return <div className={css_classes.ticket_item+' '+(this.type=='full'?css_classes.ticket_item_full:'')}>
            <div className={css_classes.ticket_item_title}>{this.data.title}</div>
            {/*<div className={css_classes.ticket_item_text} >{(this.data.text && 0)?<div className={css_classes.tt_button_3} onClick={()=>{this.props.openInfoModal(this.data.text)}}>Details</div>:'' }</div>*/}
            {/*{(this.data.info)?<div className={css_classes.ticket_item_info} dangerouslySetInnerHTML={{__html: this.data.info}}></div>:''}*/}
            <div className={css_classes.ticket_price_group}>
                <span className={css_classes.ticket_price}>{this.data.price} <span className={css_classes.ticket_currency}>{this.data.currency}</span></span>

                <span onClick={()=>{this.props.openTicketModal(this.data.url)}} className={css_classes.ticket_buy}>Buy Ticket</span>
            </div>
        </div>
    }
}