import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.css"
import {Form} from "./Components/Form";
import {Link} from "react-router-dom";


export class Contacts extends Component {
    constructor(props) {
        super();
        this.state = {}
    }


    render() {
        return <React.Fragment>
            <div className={"am_wrapper page_main"}>

                <div className={'header_section contact_header_section'}>
                    <div className={'am_container'}>
                        <div className={"section_title"}>CONTACTS</div>
                    </div>
                </div>
                <div className={'am_container contact_section'}>
                    <div className={'text_1040'}>If you have any questions about anything relating to Starmus, or if you would like to be placed on our email mailing list to receive updates of up and coming Starmus events then please contact us on the following email.</div>
                    <div className={'row'}>
                        <div className={'col6'}>
                            {/*<div className="contact_info_row">
                                <div className={"contact_info_label"}>Phone</div>
                                <div className={"contact_info_value"}>+37496061662</div>
                            </div>*/}
                            <div className="contact_info_row email">
                                <div className={"contact_info_label"}>Email</div>
                                <div className={"contact_info_value"}>info@starmus.com</div>
                            </div>
                            <div className="contact_info_row email">
                                <div className={"contact_info_label"}>Media relations</div>
                                <div className={"contact_info_value"}>press@starmus.com</div>
                                {/*<div className={"contact_info_label"}>Armenian contact</div>*/}
                                {/*<div className={"contact_info_value"}>prarmenia@starmus.com</div>*/}
                            </div>
                            {/*<div className="contact_info_row email">*/}
                            {/*    <div className={"contact_info_label"}>General questions</div>*/}
                            {/*    <div className={"contact_info_value"}>armenia@starmus.com</div>*/}
                            {/*</div>*/}
                            <div className={"contact_links"}>
                                <Link to={'/privacy-policy'} >Privacy Policy</Link>
                                <Link to={'/terms-of-use'} >Terms of use</Link>
                            </div>
                        </div>
                        <div className={'col6'}>
                            <Form />
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>


    }
}
