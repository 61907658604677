import React, {Component} from "react";
import css_classes from '../style.module.css';
import section3_img1 from '../images/section3_img1.png'
import section3_img2 from '../images/section3_img2.png'
import section3_img3 from '../images/section3_img3.png'
class Section3 extends Component{
    constructor(props) {
        super();
        this.name=props.name;
        this.data=props.data;
    }
    render(props){
        return <div className={css_classes.section3}>
            <img src={section3_img1} className={css_classes.section3_img1}/>
            <div className={css_classes.section3_text} dangerouslySetInnerHTML={{__html:this.data.content}}></div>
            <div className={'text-center'}>
                {this.data.files.map((file,index)=> {
                    return <img src={file.url} className={css_classes.section3_img2}/>
                })}
            </div>
        </div>;
    }
}
export default Section3;