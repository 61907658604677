import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {Switcher} from "../components/Switcher/Switcher";
import Figure from "../components/Figure";
import TemplateVideo from "../Festival/Components/TemplateVideo";
import styles from "../Starmus7/style.module.css";
import {Link} from "react-router-dom";

export class Slovakia extends Component {

    constructor(props) {
        super();
        this.state = {switcher_buttons:[{id:'speaker',title:"Speakers",},{id:'artists',title:"Artists"},{id:'sponsors',title:"Sponsors"}],speakers:[]}

    }

    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        this.getData();
    }
    getData = (key="speaker")=> {
        fetch(`${Configs.api_url}data/get-last-fest-persons?type=`+key)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        speakers: data.data,
                    });
                }
            );
    }
    switchType = (type) => {
        if(type=='sponsors'){
            this.props.history.push('/s7-partners');
        }else {
            this.getData(type);
        }
    }
    render() {
        let keys = this.state.switcher_buttons;
        let speakers = [];
        let camp_artists =[];
        for (let speaker of this.state.speakers) {
            if(speaker.is_camp_artist){
                camp_artists.push(<Figure info={speaker}/>)
            }else{
                speakers.push(<Figure info={speaker}/>)
            }

        }
        if(camp_artists.length>0){
            speakers = <>
                {speakers}
                <div className={css_classes.camp_artists_title}>Camp Artists</div>
                {camp_artists}
            </>
        }
        let switcher='';
        if(this.state.switcher_buttons.length){
            switcher=<Switcher keys={keys} switcherFunction={this.switchType}></Switcher>
        }

        return <React.Fragment>
            <div className={"am_wrapper page_main style_2024"}>
                <div className={css_classes.section_1}>
                    <div className={'am_container'}>
                        <div className={'section_title '+css_classes.section_1_title}> The  Future  Of Our  Home  Planet</div>
                        <div className={css_classes.section_1_sub_title}>Starmus  VII:  Bratislava, Slovakia</div>
                        <div className={css_classes.section_1_sub_title}>12-17 MAY, 2024</div>
                    </div>
                    <div className={css_classes.section_1_video_wrap}>
                        <video className={css_classes.section_1_video} muted autoPlay loop >
                            <source src={require("./images/s_1_bg.mp4")} type="video/mp4" />
                        </video>
                    </div>
                    <div className={css_classes.esetText}>
                        <div className={"am_container text_right "+css_classes.eset_wrap}>
                            <a href={"https://eset.com"} target={"_blank"}> <img className={css_classes.eset} src={require("./images/eset.svg")}/></a>
                        </div>
                    </div>
                </div>

                <div className={css_classes.abs_program}>
                    <Link to={'/program-new'} className={css_classes.programBtn}>Program</Link>
                </div>
                <div className={'section_60 am_container text_center'}>

                    {switcher}
                    <div className={css_classes.speakers_list}>
                        {speakers}
                    </div>
                </div>
                <div className={css_classes.section_2}>
                    <div className={"am_container "}>
                        <div className={"flex align_items_center "+css_classes.section_2_inner}>
                            <div className={css_classes.section_2_left}>
                                <div className={css_classes.section_2_title}>Starmus VII: From Stars to Earth</div>
                                <div className={css_classes.section_2_text}>
                                    Our planet is fragile. We live amid a delicate balance of an atmosphere that is precariously thin and supports our existence. Beginning with the industrial revolution, we tampered with the atmosphere with the production of greenhouse gases. Substantial evidence scientists universally accept suggests a “tipping point” is approaching, after which our ability to limit climate change and the disastrous effects that come with it will dramatically diminish. Climate change could eventually make life on Earth untenable, and we are seeing signs of that rapid evolution now.

                                    Increasing carbon dioxide changes weather patterns, increases temperatures and storms, and dramatically warms the oceans, which will have increasingly catastrophic effects on living creatures. Starmus cannot solve the problem. Only a worldwide effort involving governments can. But we can help to sharpen and raise awareness of the facts, which despite their quite straightforward simplicity, many do not yet accept as reality.

                                    We must take steps to take care of our world, and the Starmus Festival offers an important voice to raise awareness everywhere of the urgency we now need.
                                </div>
                            </div>
                            <div className={css_classes.section_2_right}>
                                {/*<div className={css_classes.section_6_sub_title}>One in eight deaths in 2022 attributable to diseases caused by carcinogens and other poisons in polluted air.</div>*/}
                                <div className={css_classes.grid}>
                                    <img className={css_classes.grid_img} src={require('./images/grid_1.jpg')}/>
                                    <img className={css_classes.grid_img} src={require('./images/grid_2.jpg')}/>
                                    <img className={css_classes.grid_img} src={require('./images/grid_3.jpg')}/>
                                    <img className={css_classes.grid_img} src={require('./images/grid_4.jpg')}/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <TemplateVideo content={{text:"DZDFoBTw7JY",title:"Starmus VII"}}></TemplateVideo>


            </div>
        </React.Fragment>


    }
}
