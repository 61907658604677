import styles from "./style.module.css";
import React, {Component} from 'react';
import Configs from "../../configs";
export default class SubscribeForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            fields: {},
            errors: {},
            done:false
        }
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["firstname"]){
            formIsValid = false;
            errors["firstname"] = "Cannot be empty";
        }
        if(!fields["lastname"]){
            formIsValid = false;
            errors["lastname"] = "Cannot be empty";
        }
        if(!fields["country"]){
            formIsValid = false;
            errors["country"] = "Cannot be empty";
        }

        //Email
        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }
        if(typeof fields["email"] !== "undefined"){
            if(!fields["email"].match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/)){
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    formSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            window.gtag('event', 'conversion', {'send_to': 'AW-16507181171/c_4iCMjYw54ZEPOwnr89'});

            fetch(`${Configs.api_url}data/subscription`, {
                method:'post',
                body:JSON.stringify(this.state.fields)
            })
                .then(response => response.json())
                .then(data => this.setState({
                    person: data.data.person,
                }));
            this.setState({done:true,errors:{},fields:{firstname:'',lastname:'',email:'',country:''}});
        }else{
            this.setState({success_message:false});
        }

    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }
    render() {
        return (
            <div className={styles.preRegWrap}>
                {!this.state.done && <>
                    <div className={"text_center "+styles.preRegTitle}>Stay tuned for our newsletters and get ready to embark on an extraordinary journey with STARMUS Spotlight!</div>
                    <form name="preregForm" className="s7_form" onSubmit={this.formSubmit.bind(this)}>
                        <div className={"s7_form_group"}>
                            <label>First Name</label>
                            <input className={"s7_input"} ref="firstname" type="text" size="60" placeholder="Firstame"
                                   onChange={this.handleChange.bind(this, "firstname")} value={this.state.fields["firstname"]}/>
                            {this.state.errors["firstname"] && <div className="form_error">{this.state.errors["firstname"]}</div>}
                        </div>
                        <div className={"s7_form_group"}>
                            <label>Last Name</label>
                            <input className={"s7_input"} ref="lastname" type="text" size="60" placeholder="Lastame"
                                   onChange={this.handleChange.bind(this, "lastname")} value={this.state.fields["lastname"]}/>
                            {this.state.errors["lastname"] && <div className="form_error">{this.state.errors["lastname"]}</div>}
                        </div>
                        <div className={"s7_form_group"}>
                            <label>Email</label>
                            <input ref="email" type="text" size="30" placeholder="Email"
                                   onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/>
                            {this.state.errors["email"] && <div className="form_error">{this.state.errors["email"]}</div>}
                        </div>
                        <div className={"s7_form_group"}>
                            <label>Country</label>
                            <input ref="country" type="text" size="30" placeholder="Country"
                                   onChange={this.handleChange.bind(this, "country")} value={this.state.fields["country"]}/>
                            {this.state.errors["country"] && <div className="form_error">{this.state.errors["country"]}</div>}
                        </div>
                        <div className={"text_center"}>
                            <button type={"submit"} className={'s7_button'} >Subscribe</button>
                        </div>
                        {/*<span className="form_success">{this.state.success_message}</span>*/}
                    </form>
                </>}
                {this.state.done && <div className={"text_center"}>
                    <img className={styles.preRegGif} src={require("./images/thanks.gif")}/>
                    <div className={styles.preRegTitle}>Thank you for  subscribe
                        <div className={styles.preRegText}>We are thrilled to welcome you to the STARMUS community! Your registration for the “STARMUS Spotlight” newsletter series has been successfully received. Get ready to be the first to receive our exciting newsletters, filled with fascinating insights, exclusive updates, and much more!</div>
                    </div>
                </div>}

            </div>
        );
    }
}