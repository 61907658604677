import React,{Component} from "react";

export class Form extends Component {
    constructor(props){
        super(props);

        this.state = {
            fields: {},
            errors: {},
            success_message:''
        }
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["name"]){
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        //Email
        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }
        if(typeof fields["email"] !== "undefined"){
            if(!fields["email"].match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/)){
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }
        if(!fields["subject"]){
            formIsValid = false;
            errors["subject"] = "Cannot be empty";
        }
        if(!fields["message"]){
            formIsValid = false;
            errors["message"] = "Cannot be empty";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    contactSubmit(e){
        e.preventDefault();
        console.log(this.state.fields);
        if(this.handleValidation()){
            this.setState({success_message:'Success sent',errors:{},fields:{name:'',email:'',subject:'',message:''}});
            console.log(this.state.fields)

        }else{
            this.setState({success_message:false});
        }

    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    render(){
        return (
            <form name="contactform" className="contactform" onSubmit= {this.contactSubmit.bind(this)}>
                <label>
                    Your name
                    <input ref="name" type="text" size="30" placeholder="Name" onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"]}/>
                    <span className="form_error">{this.state.errors["name"]}</span>
                </label>
                <label>
                    Email
                    <input refs="email" type="text" size="30" placeholder="Email" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/>
                    <span className="form_error">{this.state.errors["email"]}</span>
                </label>
                <label>
                    Subject
                    <input refs="subject" type="text" size="30" placeholder="Subject" onChange={this.handleChange.bind(this, "subject")} value={this.state.fields["subject"]}/>
                    <span className="form_error">{this.state.errors["subject"]}</span>
                </label>
                <label>
                    Message
                    <textarea refs="message" type="text" rows={6} placeholder="Your message starts with…"  onChange={this.handleChange.bind(this, "message")} >{this.state.fields["message"]}</textarea>
                    <span className="form_error">{this.state.errors["message"]}</span>
                </label>
                <input type={"submit"} className={'submit_button'} value={"Send"}/>
                <span className="form_success">{this.state.success_message}</span>


            </form>
        )
    }
}
