import React, {Component} from "react";
import css_classes from '../style.module.css';
import section3_img1 from '../images/section3_img1.png'
import section3_img2 from '../images/section3_img2.png'
import {VideoBg, VideoModal, VideoPlayButton} from "../../components/VideoBg";
import video from "../../assets/videos/armenia.mp4";
class Section4 extends Component{
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.playVideo = this.playVideo.bind(this);
    }

    playVideo = () => {
        this.videoRef.current.showModal();
    };

    render(props){
        // let video = "y6cWVS3WK2o";
        let video = "ezTvDoPMiUs";
        return <div className={"video_bg_section " + css_classes.section4}>
            <div className={"video_bg_button"}>
                <div className={"section_title " + css_classes.section4_title}>STEPHEN HAWKING MEDAL</div>
                {/*<div className={}></div>*/}
                <VideoPlayButton playClick={this.playVideo}/>
            </div>
            <VideoBg videoSrc={video} fromYoutube={true}/>
            <VideoModal ref={this.videoRef} videoSrc={video} fromYoutube={true} />

        </div>;
    }
}
export default Section4;