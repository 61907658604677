// CookiesAlert.js

import React, {useState} from 'react';
import css_classes from "./style.module.css";
import cookiePdf from "../../pdf/pdf1.pdf"

const CookiesAlert = () => {

    let _cookiesState = localStorage.getItem('cookiesState');
    if(_cookiesState){
        _cookiesState = JSON.parse(_cookiesState);
    }

    let showCookieAlert=false;
    if(_cookiesState==null){
        showCookieAlert=true;
    }else{
        for(let i in _cookiesState){
            if (!_cookiesState[i]){
                showCookieAlert=true;
                break
            }
        }
    }
    const [showAlert, setShowAlert] = useState(showCookieAlert);
    const [showConfigure, setShowConfigure] = useState(false);
    const [cookiesState, setCookiesState] = useState(_cookiesState || {
        ad_storage:false,
        analytics_storage: false
    });

    const handleDismiss = () => {
        setShowAlert(false);
        initGTag();
    }
    const configure = () => {
        setShowConfigure(true)
    }
    const checkboxClick = (type) => {
        var _cookiesState={...cookiesState}
        _cookiesState[type]=!_cookiesState[type]
        setCookiesState(_cookiesState)

    }
    const addGoogleAnalytic = (key) => {
        window['dataLayer'] = window['dataLayer'] || []
        window['dataLayer'].push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
        var f = document.getElementsByTagName('script')[0];
        var j = document.createElement("script");
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + key;
        f.parentNode.insertBefore(j, f);
    }

    const acceptCookies = (acceptAll) => {
        let _cookiesState={...cookiesState}
        if(acceptAll){
            for(let i in _cookiesState){
                _cookiesState[i]=true;
            }
        }
        localStorage.setItem('cookiesState',JSON.stringify(_cookiesState));
        setShowAlert(false);
        initGTag();
        return;
    };
    const initGTag =()=>{
        let granted={}
        for(let i in _cookiesState) {
            granted[i]=_cookiesState[i]?'granted':'denied'
        }
        window.gtag('consent', 'update', granted);

        return;


    }
    if (!showCookieAlert && !window.G_SCRIPT_ADDED) {
        initGTag(1);
        window.G_SCRIPT_ADDED = true;
    }

    return (
        showAlert && (
            <div className={css_classes.overlay + ' border_box'}>
                <div className={css_classes.cookiesAlert}>
                    {!showConfigure &&
                        <div>This website uses third party cookies to ensure you get the best experience.<br/>
                            <a href={cookiePdf} target={"_blank"}>Cookies Policy</a>
                            <div className={css_classes.buttons_row}>
                                <button className={css_classes.button} onClick={configure}>Configure</button>
                                <button className={css_classes.button} onClick={handleDismiss}>Reject All</button>
                                <button className={css_classes.button + ' ' + css_classes.ok_button}
                                        onClick={()=>{acceptCookies(true)}}>Accept All
                                </button>
                            </div>
                        </div>
                    }
                    {showConfigure &&
                        <div>
                            <div className={css_classes.cookie_type_row+ ' '+css_classes.disabled}>
                                <span className={css_classes.checkbox+' '+css_classes.checked}></span>Technical Cookies
                            </div>
                            <div className={css_classes.cookie_type_row} onClick={()=>checkboxClick('ad_storage')}>
                                <span className={css_classes.checkbox+' '+(cookiesState.ad_storage?css_classes.checked:'')}></span>Marketing cookies
                            </div>
                            <div className={css_classes.cookie_type_row} onClick={()=>checkboxClick('analytics_storage')}>
                                <span className={css_classes.checkbox+' '+(cookiesState.analytics_storage?css_classes.checked:'')}></span>Analytical cookies
                            </div>

                            <div className={css_classes.buttons_row+' '+css_classes.buttons_row_config}>
                                <button className={css_classes.button} onClick={handleDismiss}>Close</button>
                                <button className={css_classes.button + ' ' + css_classes.ok_button}
                                        onClick={()=>{acceptCookies(false)}}>Accept
                                </button>
                            </div>
                        </div>
                    }

                </div>
            </div>)
    );
};

export default CookiesAlert;
