import React, {Component} from 'react'
import ScrollDown from "../../components/Scroll/ScrollDown";
import '../css/life_on_mars.css'

class LifeOnMars extends Component {
  render() {
    return (
      <section className="section parallax life-on-mars-section">
        <div className='container'>
          <div className="life-on-mars-block starmus-block">
            <div className="life-on-mars-block-text">
              <h1 className="starmus-h1">
                Life on MARS
              </h1>
              <div className='life-on-mars-text'>
                <div className="starmus-flex-center">
                  <div className="oval oval-life-on-mars"/>
                  <div className={'life-on-mars-p-container'}>
                    <p className='life-on-mars-p starmus-p'>
                      Mars' atmosphere is mostly carbon dioxide. However, methane has also been
                      detected, which can be interpreted as a sign of biological activity or
                      geologic activity.
                    </p>
                  </div>
                </div>
                <div className="starmus-flex-center">
                  <div className="oval oval-life-on-mars"/>
                  <div className={'life-on-mars-p-container'}>
                    <p className='life-on-mars-p starmus-p'>
                      Mars had liquid water and a thicker atmosphere in the past.
                    </p>
                  </div>
                </div>
                <div className="starmus-flex-center">
                  <div className="oval oval-life-on-mars"/>
                  <div className={'life-on-mars-p-container'}>
                    <p className='life-on-mars-p starmus-p'>
                      Polar caps of Mars are frozen water covered by a layer of carbon dioxide
                      ice.
                    </p>
                  </div>
                </div>
                <div className="starmus-flex-center">
                  <div className="oval oval-life-on-mars"/>
                  <div className={'life-on-mars-p-container'}>
                    <p className='life-on-mars-p starmus-p'>
                      The Curiosity rover discovered complex organic molecules in sedimentary
                      rocks dating to three billion years old.
                    </p>
                  </div>
                </div>
                <div className="starmus-flex-center">
                  <div className="oval oval-life-on-mars"/>
                  <div className={'life-on-mars-p-container'}>
                    <p className='life-on-mars-p starmus-p'>
                      If Mars possessed an Earth-like biosphere in the past, Mars may contain
                      subsurface deposits of oil and natural gas indicating past life.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ScrollDown to='settling-the-red-planet-section' containerId="main"/>
          </div>
        </div>
      </section>
    )
  }
}

export default LifeOnMars