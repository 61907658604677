import React, {Component} from "react";
import css_classes from '../style.module.css';
import {VideoBg, VideoModal, VideoPlayButton} from "../../components/VideoBg";
import YouTube from "react-youtube";

class Section4 extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.playVideo = this.playVideo.bind(this);
    }

    playVideo = () => {
        this.videoRef.current.showModal();
    };
    render(props) {
        let video = "FVc_4d_KELA";
        return <div className={"video_bg_section " + css_classes.section4}>
            <div className={"video_bg_button"}>
                <div className={"section_title " + css_classes.section4_title}>Rick Wakeman</div>
                <div className={css_classes.section_4_text}>Starmus</div>
                {/*<div className={}></div>*/}
                <VideoPlayButton playClick={this.playVideo}/>
            </div>
            <VideoBg videoSrc={video} fromYoutube={true}/>
            <VideoModal ref={this.videoRef} videoSrc={video} fromYoutube={true} />

        </div>;
    }
}

export default Section4;