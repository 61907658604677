import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class TemplateText extends Component{
    constructor(props) {
        super();
        this.content = props.content;
        this.title_align=props.title_align
    }
    render() {
        let title='';
        if(this.content.title){
            title =<div className={"section_title black "+(this.title_align=='left'?'':'text_center')+" "+ css_classes.template_text_title}>{this.content.title}</div>
        }
        return <div className={'am_container section_60 '}>
            {title}
            <div className={"section_content text_1040"} dangerouslySetInnerHTML={{__html:this.content.text}}>
            </div>
        </div>
    }
}