import React, {Component} from "react";
import css_classes from '../style.module.css';
import {Switcher} from "../../components/Switcher/Switcher";
import Figure from "../../components/Figure";
import Configs from "../../configs";
import {Link} from "react-router-dom";

class Section6 extends Component {

    componentDidMount() {
        fetch(`${Configs.api_url}data/get-press?category=about_page&sort=id_asc`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        about_posts:data.data.posts
                    });
                }
            );
    }


    constructor(props) {
        super();
        this.state={
            about_posts:[]
        }
    }


    render(props) {
        let list_html = [];
        this.state.about_posts.map((item) => (list_html.push(
            <div className={(item.is_main==1)?css_classes.section_6_full:css_classes.section_6_item }>
                <div className={css_classes.section_6_item_inner}>
                    <div className={css_classes.section_6_img_wrap}>
                        <img className={css_classes.section_6_item_bg} src={item.image}></img>
                    </div>
                    <div className='section_title'>{item.title}</div>
                    <div className={css_classes.section_6_text} dangerouslySetInnerHTML={{__html:item.description}}></div>
                    <Link to={'/press-item/' + item.id}>Read more</Link>
                </div>
            </div>)));
        return <div className="am_container">
            <div className={css_classes.section6}>
                {list_html}
            </div>
        </div>;
    }
}


export default Section6;