import React, {Component} from 'react';
import './starmus_arrows.css'

export class LeftArrow extends Component {
  render() {
    return (
      <div className='arrows-button-oval-container' onClick={this.props.onClick}>
        <div className='arrows-button-image-container container'>
          <div className='arrows-button left-arrow'/>
        </div>
      </div>
    )
  }
};

export class RightArrow extends Component {
  render() {
    return (
      <div className='arrows-button-oval-container' onClick={this.props.onClick}>
        <div className='arrows-button-image-container container'>
          <div className='arrows-button right-arrow'/>
        </div>
      </div>
    )
  }
};