import React, {Component} from "react";
import css_classes from '../style.module.css';
class Section1 extends Component{
    constructor(props) {
        super();
        this.name=props.name;
        this.data=props.data;
    }
    render(){

        return <div className={css_classes.section1} style={{backgroundImage:this.data.bg_image}}>
            <div className="am_container">
                <div className={css_classes.section1_title_group}>
                    <div className={"section_title "+css_classes.section1_title}>{this.data.title}</div>
                    <div className="section_sub_title">{this.data.sub_title}</div>
                </div>
            </div>
        </div>;
    }
}
export default Section1;