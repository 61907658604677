import React, {Component} from "react";
import css_classes from "./style.module.css"


export class PageNotFound extends Component {


    render() {
        return  <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={'header_section ' + css_classes.header_section}>
                    <div className={'am_container'}>
                        <div className={"section_title"}>404 Page not Found</div>
                    </div>
                </div>

            </div>
        </React.Fragment>


    }
}
export default PageNotFound;