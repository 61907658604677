import styles from "../style.module.css";
import React from 'react';

export default function Section6() {
    return (
        <div className={styles.section_6}>
            <video autoPlay muted loop className={styles.section_6_bg}>
                <source src={require("../images/s_6_bg.mp4")} type="video/mp4"/>
            </video>
            <div className={"am_container "+styles.section_6_content}>
                <div className={"row"}>
                    <div className={"flex_half half_769"}>
                        <div className={styles.title}>Peace and Society</div>
                        {/*<div className={styles.section_6_sub_title}>One in eight deaths in 2022 attributable to diseases caused by carcinogens and other poisons in polluted air.</div>*/}
                        <div className={styles.s_6_grid}>
                            <img className={styles.s_6_grid_img} src={require('../images/s_6_1.jpg')}/>
                            <img className={styles.s_6_grid_img} src={require('../images/s_6_2.jpg')}/>
                            <img className={styles.s_6_grid_img} src={require('../images/s_6_3.jpg')}/>
                            <img className={styles.s_6_grid_img} src={require('../images/s_6_4.jpg')}/>
                        </div>
                    </div>
                    <div className={"flex_half half_769"}>
                        <div className={styles.section_6_row}>
                            The value of military spending globally has reached <span className={styles.section_6_row_big}> $ 2 trillion</span>.
                        </div>
                        <div className={styles.section_6_row}>Global cost of corruption is at least
                            <span className={styles.section_6_row_big}>$ 2.6 trillion</span>.
                        </div>
                        <div className={styles.section_6_row}><span className={styles.section_6_row_big}>$ 3.5 trillion</span> a year will cost to make the world sustainable</div>
                        <div className={styles.section_6_row}>We need <span className={styles.section_6_row_big}>$116 billion</span> to wipe out hunger</div>
                        <div className={styles.section_6_row}>We need <span className={styles.section_6_row_big}>$26 billion</span> to provide basic education to all children</div>
                    </div>
                </div>

            </div>
        </div>
    );
}