import React, {Component} from 'react';

import '../css/main_banner.css';
import ScrollDown from "../../components/Scroll/ScrollDown";
import {Link} from "react-router-dom";

class MainBanner extends Component {
  render() {
    return (
      <section className="main-banner-section">
        <div className='main-banner-container container'>

          <div className='main-banner-text'>
            <div className="starmus-title">
              <h1 className="starmus-h1 new_banner_h1">
                Starmus VII
              </h1>
              <h1 className="starmus-h2 festival_slogan_6">
                Coming soon
              </h1>
            </div>

          </div>

        </div>
      </section>
    )
  }
}

export default MainBanner