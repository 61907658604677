import styles from "../style.module.css";
import React from 'react';

export default function Section4() {
    return (
        <div className={styles.section_4}>
            <div className={"am_container"}>
                <div className={styles.title+' text_center '+styles.title_black}>Environmental Pollution</div>
                <div className={styles.section_4_sub_title}>One in eight deaths in 2022 attributable to diseases caused by carcinogens and other poisons in polluted air.</div>

                <div className={styles.section_4_row+" flex align_items_center"}>
                    <div>
                        <video className={styles.section_4_video} muted autoPlay loop poster={require("../images/s_4_1.png")}>
                            <source src={require("../images/s_4_1.mp4")} type="video/mp4" />
                        </video>
                    </div>

                    <div className={styles.section_4_text}>
                        Air pollution causes between
                        <span className={styles.s_text_2}>150</span> and <span className={styles.s_text_2}>200</span> extra deaths in every <span className={styles.s_text_2}>100,000</span> people per year. It causes more extra deaths a year than tobacco smoking.
                    </div>
                </div>
                <div className={styles.section_4_row+" flex align_items_center "+styles.section_4_row_revers}>
                    <div className={styles.section_4_text}>
                        Water pollution kills more than
                        <span className={styles.s_text_2}>10,000</span> people around the world every day - that's <span className={styles.s_text_2}>3.6 million</span> people every year.

                    </div>
                    <div>
                        <video className={styles.section_4_video} muted autoPlay loop poster={require("../images/s_4_2.png")}>
                            <source src={require("../images/s_4_2.mp4")} type="video/mp4" />
                        </video>
                    </div>

                </div>
            </div>
        </div>
    );
}