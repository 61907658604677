import React, {Component} from "react";
import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Section6 from "./components/Section6";
import Section7 from "./components/Section7";
import Configs from "../configs";

class StephenHawkingMedal extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {}
        // this.isHome=props.isHome;
    }

    componentDidMount() {
        // if(this.isHome){
        //     this.getData('_home');
        // }else{
        this.getData('stephen-hawking-medal');
        // }


    }

    getData(slug) {
        // Loader.startLoading();
        // window.Loader.startLoading();
        fetch(`${Configs.api_url}data/get-page?slug=${slug}`)
            .then(response => response.json())
            .then(data => {

                    // window.Loader.stopLoading();
                    this.setState({
                        page: data.page,
                        content: data.content,
                    })
                }
            );
    }

    render() {
        if (this.state.content) {
            return <React.Fragment>
                {/*<WaitForLoading/>*/}
                <div className={"am_wrapper page_main"}>
                    <Section1 data={this.state.content[0]}/>
                    <Section2 data={this.state.content[1]}/>
                    <Section3 data={this.state.content[2]}/>
                    <Section4/>
                    <Section5/>
                    <Section6/>
                    <Section7 data={this.state.content[3]}/>
                </div>
            </React.Fragment>
        }
        return '';
    }
}

export {StephenHawkingMedal};