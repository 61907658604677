import React, {Component} from 'react'
import ScrollDown from "../../components/Scroll/ScrollDown";
import '../css/settling_the_red_planet.css'

class SettlingTheRedPlanet extends Component {
    render() {
        return (
          <section className='settling-section'>
            <div className='container'>
                <div className="settling-block starmus-block">
                    <div className="settling-block-text">

                        <h1 className="starmus-h1">
                            Settling the Red Planet
                        </h1>
                        <div className='settling-text'>
                            <p className={'starmus-p'}>
                                <strong>
                                    In order to achieve long-term human settlement on Mars, these goals would need to be
                                    met:
                                </strong>
                            </p>
                            <p className={'starmus-p'}>
                                Artificial Mars habitats with life-support systems
                            </p>
                            <p className={'starmus-p'}>
                                Energy production and storage
                            </p>
                            <p className={'starmus-p'}>
                                Resource extraction equipment
                            </p>
                            <p className={'starmus-p'}>
                                Basic utilities <br/>
                                oxygen, power, local communications, waste disposal, sanitation and water recycling
                            </p>
                            <p className={'starmus-p'}>
                                Storage facilities
                            </p>
                            <p className={'starmus-p'}>
                                Food production spaces and equipment
                            </p>
                            <p className={'starmus-p'}>
                                Propellant production equipment
                            </p>
                            <p className={'starmus-p'}>
                                Mars suits, rovers and aircraft
                            </p>
                            <p className={'starmus-p'}>
                                Robots on Mars
                            </p>
                            <p className={'starmus-p'}>
                                Communications
                            </p>
                        </div>
                    </div>
                    <ScrollDown to='mystery-of-red-planet-section' containerId="main"/>
                </div>
            </div>
          </section>
        )
    }
}

export default SettlingTheRedPlanet