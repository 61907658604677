import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class PageTemplate2 extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }
    render() {
        return <div className={css_classes.template_2_wrap+' '+css_classes.h100} style={{backgroundImage:`url(${this.content.bg_image})`}}>
            <div className={'am_container '+css_classes.template_2_info}>
                    <div className={css_classes.template_2_title} dangerouslySetInnerHTML={{__html: this.content.title}}></div>
                    <div className={css_classes.template_2_text_container}
                         dangerouslySetInnerHTML={{__html: this.content.content}}>
                    </div>

            </div>
        </div>
    }
}