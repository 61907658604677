import React, {Component} from 'react';
import './switcher.css'

export class Switcher extends Component {
    constructor(props) {
        super();
        this.keys = props.keys;
        if (this.keys.length > 0) {
            this.state = {active_key: props.active_key || this.keys[0]['id']};
        }
        this.switcherFunction = props.switcherFunction;
    }

    switchTo = (key) => {
        this.setState({active_key:key});
        this.switcherFunction(key);
    }

    render() {
        let buttons = [];
        for (let key of this.keys) {
            let active_class = (key.id == this.state.active_key) ? 'active' : '';
            buttons.push(<div className={"switcher_button " + active_class} onClick={() => {
                this.switchTo(key.id)
            }}>{key.title}</div>)
        }
        return (
            <div className="switcher">{buttons}</div>
        )
    }
};
