import styles from "../style.module.css";
import React, {Component, useEffect, useRef, useState} from 'react';
import RingLoader from "react-spinners/RingLoader";
import Slider from "./slider";

let images =[
    require("../images/bbg.jpg"),
    require("../images/bg1.jpg"),
    require("../images/bg2.jpg"),
    require("../images/bg3.jpg"),
    require("../images/bg4.jpg"),
    require("../images/bg5.jpg"),
    require("../images/body.png"),
    require("../images/hour.png"),
    require("../images/minute.png"),
]
let AnimationInterval=null;
let AnimationTimeout=null;
export default function Clock() {
    const [isReady, setIsReady] = useState(false);
    const minuteArrow = useRef();
    const hourArrow = useRef();
    const minuteArrowAudio = useRef();

    let loadedCount=0;
    useEffect(()=>{
        // const audio = new Audio(require("./images/ticking_clock.mp3"));
        images.forEach((file) => {
            const imageLoader = new Image();
            imageLoader.src = file;

            imageLoader.onload = () => {
                loadedCount++;
                if(loadedCount == images.length){
                    setIsReady(true);
                    animateArrows();
                }
            };
        });
        return stopAnimationArrows;
    },[])
    let stopAnimationArrowsFlag = false;
    const stopAnimationArrows = () => {
        stopAnimationArrowsFlag= true;
        clearInterval(AnimationInterval);
        clearTimeout(AnimationTimeout);
    }
    const animateArrows = () => {
        let arrow = minuteArrow.current;
        let arrow2 = hourArrow.current;
        let angel = 180
        let hourAngel = 330
        try{
            arrow.style.transform="rotate("+angel+"deg)";
            arrow2.style.transform="rotate("+hourAngel+"deg)";
        }catch (e){
            console.log('exxx','aaa');
            return;
        }

        AnimationInterval = setInterval(()=>{
            angel+=6;
            hourAngel++;
            arrow.style.transform="rotate("+angel+"deg)";
            arrow2.style.transform="rotate("+hourAngel+"deg)";
            /*try{
                minuteArrowAudio.current.currentTime = 0;
                minuteArrowAudio.current.play();
            }catch (e){

            }*/

            if(angel==360){
                clearInterval(AnimationInterval);
                if(!stopAnimationArrowsFlag){
                    AnimationTimeout = setTimeout(animateArrows,10000);
                }
            }

        },1000)
    }

    return (
        <>


            {/*<audio ref={minuteArrowAudio} autoPlay>
                <source src={require("./images/ticking_clock.mp3")} type="audio/mp3" />
            </audio>*/}
        {isReady &&
            <div className={'am_container '+styles.all_wrapper}>

                <div className={styles.clock_text}>
                    <div className={styles.text_1}>
                        The Future Of Our Home Planet
                    </div>
                    <div className={styles.text_2}>Starmus VII: Bratislava, Slovakia</div>
                    <div className={styles.text_2}>12-17 MAY, 2024</div>
                    <a href={"https://eset.com"} target={"_blank"} className={styles.text_3}> <img className={styles.eset} src={require("../images/eset.svg")}/></a>
                </div>

                <div className={styles.clock_wrapper}>
                    <div className={styles.clock_body}></div>
                    <div ref={hourArrow} className={styles.clock_hour}></div>
                    <div ref={minuteArrow} className={styles.clock_minute}></div>
                    <div className={styles.clock_bg +' clock_slider'}>
                        <Slider loop={false} showArrows={false} interval={5}>
                            <img src={require("../images/bbg.jpg")}/>
                            <img src={require("../images/bg1.jpg")}/>
                            <img src={require("../images/bg2.jpg")}/>
                            <img src={require("../images/bg3.jpg")}/>
                            <img src={require("../images/bg4.jpg")}/>
                            <img src={require("../images/bg5.jpg")}/>
                        </Slider>
                    </div>
                </div>

            </div>}

            {!isReady && <div className='loading-component'>
                <RingLoader
                    color={"#ffffff"}
                    size={60}
                    loading={!isReady}
                />
            </div>}
        </>
    )
}