import React, {Component} from 'react';
import ScrollDown from "../../components/Scroll/ScrollDown";
import '../css/mars_facts.css';

const factsList = [
  {
    fact: 'Average Distance from Sun',
    mars: '<strong class="fact-strong">228</strong><br/>million km',
    earth: '<strong class="fact-strong">150</strong><br/>million km'
  },
  {
    fact: 'Diameter',
    mars: '<strong class="fact-strong">6779</strong><br/>km',
    earth: '<strong class="fact-strong">12742</strong><br/>km'
  },
  {
    fact: 'Tilt of Axis',
    mars: '<strong class="fact-strong">25</strong><br/>degrees',
    earth: '<strong class="fact-strong">23.5</strong><br/>degrees'
  },
  {
    fact: 'Length of Year',
    mars: '<strong class="fact-strong">687</strong><br/>Earth days',
    earth: '<strong class="fact-strong">365.25</strong><br/>days'
  },
  {
    fact: 'Length of Day',
    mars: '<strong class="fact-strong">24</strong> <br/>hours 37 minutes',
    earth: '<strong class="fact-strong">23</strong> <br/>hours 56 minutes'
  },
  {
    fact: 'Mass',
    mars: '<strong class="fact-strong">0.107</strong><br/>that of Earth',
    earth: '<strong class="fact-strong">9.34</strong><br/>that of Mars'
  },
  {
    fact: 'Gravity',
    mars: '<strong class="fact-strong">0.375</strong><br/>that of Earth',
    earth: '<strong class="fact-strong">2.66</strong><br/>that of Mars'
  },
  {
    fact: 'Temperature',
    mars: 'Min -<strong class="fact-strong">140</strong> C  <br/>Max +<strong class="fact-strong">20</strong> C',
    earth: 'Min -<strong class="fact-strong">88</strong> C <br/>Max +<strong class="fact-strong">58</strong> C'
  },
  {
    fact: 'Atmosphere',
    mars: '<strong class="fact-strong">96 </strong>% carbon dioxide, others',
    earth: '<strong class="fact-strong">78 </strong>% nitrogen, <br/><strong class="fact-strong">21 </strong>% oxygen, others'
  },
  {
    fact: 'Number of Moons',
    mars: '<strong class="fact-strong">2</strong>',
    earth: '<strong class="fact-strong">1</strong>'
  },
];

class MarsFacts extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.factInterval = null;
    this.state = {
      factCurrentNumber: 0,
      factPreviousNumber: factsList.length - 1,
    };
  }

  factIntervalStart() {
    clearInterval(this.factInterval);
    this.factInterval = setInterval(() => {
      this.next();
    }, 5000);
  }

  updated(step) {
    let factCurrentNumber = (factsList.length + this.state.factCurrentNumber + step) % factsList.length;
    let factPreviousNumber = (factsList.length + factCurrentNumber - 1) % factsList.length;
    this.factIntervalStart();
    this.setState({factCurrentNumber, factPreviousNumber});
  }

  next() {
    this.updated(1);
  }

  previous() {
    this.updated(-1);
  }

  render() {
    return (
      <section className="mars-facts-section section parallax">
        <div className='container'>
          <div className="mars-facts-block starmus-block">
            <div className="mars-facts-block-text">
              <h1 className="mars-facts-h1 starmus-h1">
                Mars Facts
              </h1>
            </div>
            <div id="fact-text" className={'fact-main-text starmus-p'}
                 dangerouslySetInnerHTML={{__html: factsList[this.state.factCurrentNumber].fact}}/>
            <div className='mars-facts-planets-block'>
              <div className='mars-facts-planet-block'>
                <div className='mars-facts-earth mars-facts-planets'>
                  <div className='mars-facts-earth-after'>
                    <div className={'active-fact'}>
                      <div className={'oval-facts oval-facts-active oval oval-earth'}
                           onClick={this.next}/>
                      <div className={'active-fact-text-earth active-fact-text starmus-p'}
                           dangerouslySetInnerHTML={{__html: factsList[this.state.factCurrentNumber].earth}}/>
                    </div>
                    <div className={'passive-fact'}>
                      <div className={'oval-facts oval-facts-passive oval oval-earth'}
                           onClick={this.previous}/>
                      <div className={'passive-fact-text-earth passive-fact-text starmus-p'}
                           dangerouslySetInnerHTML={{__html: factsList[this.state.factPreviousNumber].earth}}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mars-facts-planet-block'/>
              <div className='mars-facts-planet-block'>
                <div className='mars-facts-mars mars-facts-planets'>
                  <div className='mars-facts-mars-after'>
                    <div className={'active-fact'}>
                      <div className={'active-fact-text-mars active-fact-text starmus-p'}
                           dangerouslySetInnerHTML={{__html: factsList[this.state.factCurrentNumber].mars}}/>
                      <div className={'oval-facts oval-facts-active oval oval-mars'}
                           onClick={this.next}/>

                    </div>
                    <div className={'passive-fact'}>
                      <div className={'passive-fact-text-mars passive-fact-text starmus-p'}
                           dangerouslySetInnerHTML={{__html: factsList[this.state.factPreviousNumber].mars}}/>
                      <div className={'oval-facts oval-facts-passive oval oval-mars'}
                           onClick={this.previous}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'fact-main-passive-text starmus-p'} dangerouslySetInnerHTML={{__html: ''}}/>
            <ScrollDown to='mars-awakens-section' containerId="main"/>
          </div>
        </div>
      </section>
    )
  }

  componentDidMount() {
    this.factIntervalStart();
  }
}

export default MarsFacts