import React, {Component} from 'react';
import styles from "../style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
import s5_1 from "../images/s5_1.png";
class S8Section5 extends Component {
    render() {
        return (
            <div className={styles.section}>
                <div className={'am_container flex flex_column_992'}>
                    <div className={styles.img_section}>
                        <div className={styles.relative}>
                            <img  src={s5_1} alt={"s5_1"}/>
                        </div>
                    </div>
                    <div className={styles.text_section}>
                        <div className={styles.title_45+' color_orange'}>  LA PALMA</div>
                        <p className={styles.p}></p>
                        <p className={styles.p}>


                            Located in the archipelago of the Canary Islands, the island of La Palma is  an enclave with a privileged natural legacy. Its landscape is sculpted by  volcanoes and the Atlantic, giving rise to a variety of microclimates that  make the island an environment with a unique biodiversity in the world.  Thus, the island has been catalogued as a UNESCO World Biosphere  Reserve and a third of its territory is a protected area. Both for its strategic location and these privileged natural characteristics,  La Palma offers unbeatable conditions for stargazing, being considered  one of the best places on the planet for observing the sky.


                        </p>
                        <p className={styles.p}>More information <a href={"https://visitlapalma.com"} className={'color_blue'}> www.visitlapalma.com</a></p>

                    </div>
                </div>
            </div>
        );
    }
}

export default S8Section5