import React, {Component} from 'react';
import {LeftArrow, RightArrow} from "../Arrows/StarmusArrows";
import './starmus_slider.css'

class StarmusSlider extends Component {
  constructor(props) {
    super(props);
    if(this.props.hasOwnProperty("showArrows")){
      this.showArrows = this.props.showArrows ;
    }else{
      this.showArrows =true;
    }
    if(this.props.hasOwnProperty("loop")){
      this.loop = this.props.loop ;
    }else{
      this.loop =true;
    }
    this.slideCount = React.Children.count(this.props.children);
    this.state = {activeSlide: 0}
  }

  setTimer = () => {
    this.timer = setInterval(
      () => this.nextItem(), 5000,
    );
  };

  nextItem = () => {
    if(!this.loop && this.state.activeSlide == this.slideCount){
      return;
    }
    this.setState({activeSlide: (this.state.activeSlide + 1) % this.slideCount});
    clearInterval(this.timer);
    this.setTimer()
  };

  previousItem = () => {
    this.setState({activeSlide: (this.slideCount + this.state.activeSlide - 1) % this.slideCount});
  };

  componentDidMount() {
    this.setTimer();
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  };

  render() {
    return (
      <div className='slideshow-block'>
        {
          React.Children.map(this.props.children, (Child, index) => (
            <div key={`starmus_slider_${index}`} className={this.state.activeSlide === index ? 'slider-item opaque' : 'slider-item'}>
              {Child}
            </div>
          ))
        }
        {this.showArrows && <div className='arrow-container'>
          <div className='arrows'>
            <div className='left-arrow-container'>
              <LeftArrow onClick={this.previousItem}/>
            </div>
            <div className='right-arrow-container'>
              <RightArrow onClick={this.nextItem}/>
            </div>
          </div>
        </div>}
      </div>
    )
  }
};

export default StarmusSlider;