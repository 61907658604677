import React,{Component} from "react";
import css_classes from '../style.module.css';
import styles from "../../Starmus8/style.module.css";
import banner_info from "../../Starmus8/images/banner_info.png";
import SubscribeForm from "../../components/Forms/SubscribeForm";
import RegForm from "../../components/Forms/RegForm";

export  class MainBanner extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }

    render() {

        return <div className={css_classes.page +' '+css_classes.mainBannerWrap+' '+css_classes.content_bg} style={{backgroundImage:`url(${this.content.bg_image})`}}>
            <div className={css_classes.content_bg_mobile+' '+css_classes.centered_info_wrap}  style={{backgroundImage:`url(${this.content.mobile_bg_image})`}}>
                <div className={css_classes.mainBannerInfo}>
                    <div className={'am_container'}>
                        <img className={css_classes.mainBannerImage} src={this.content.image} alt={this.content.title}/>
                        <div className={css_classes.mainBannerTitle}>{this.content.title}</div>
                        <div className={css_classes.mainBannerSubTitle}>{this.content.sub_title}</div>
                        <div className={css_classes.mainBannerDesc}>{this.content.description}</div>
                        <button className={css_classes.reg_button} onClick={() => {
                            this.props.openPopup(<RegForm></RegForm> )
                        }}>Registration
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }
}