import React, {Component} from 'react';
import styles from "../style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
import ps_1 from "../images/ps_1.png";
import ps_2 from "../images/ps_2.png";
import ps_3 from "../images/ps_3.png";
import ps_4 from "../images/ps_4.png";
import ps_5 from "../images/ps_5.png";

class S8Partners extends Component {
    render() {
        return (
            <div className={styles.section}>
                <div className={'am_container'}>
                    <div className={styles.partners_section}>
                        <div className={styles.partners_section_titile}>STARMUS PARTNERS</div>
                        <div className={styles.partners_section_items}>
                            <div className={styles.partners_section_item}>
                                <div className={styles.partners_section_img_wrap}>
                                    <img className={styles.partners_section_img} src={ps_1} alt={"ps_1"}/>
                                </div>
                                <div className={styles.partners_section_item_text}>Starlight Foundation</div>
                                <a href={"https://www.iac.es/en"}
                                   className={styles.partners_section_item_link}>www.iac.es/</a>
                            </div>
                            <div className={styles.partners_section_item}>
                                <div className={styles.partners_section_img_wrap}>
                                    <img className={styles.partners_section_img} src={ps_2} alt={"ps_2"}/>
                                </div>
                                <div className={styles.partners_section_item_text}>IAC (Instituto de Astrofísica de
                                    Canarias)
                                </div>
                                <a href={"https://en.fundacionstarlight.org/"}
                                   className={styles.partners_section_item_link}>fundacionstarlight.org</a>
                            </div>
                            <div className={styles.partners_section_item}></div>
                        </div>
                    </div>
                    <div className={styles.partners_section}>
                        <div className={styles.partners_section_titile}>STARMUS LA PALMA PARTNERS</div>
                        <div className={styles.partners_section_items}>
                            <div className={styles.partners_section_item}>
                                <div className={styles.partners_section_img_wrap}>
                                    <img className={styles.partners_section_img} src={ps_3} alt={"ps_3"}/>
                                </div>
                                <div className={styles.partners_section_item_text}>Ministry of Industry and Tourism of Spain</div>
                            </div>
                            <div className={styles.partners_section_item}>
                                <div className={styles.partners_section_img_wrap}>
                                    <img className={styles.partners_section_img} src={ps_4} alt={"ps_4 "}/>
                                </div>
                                <div className={styles.partners_section_item_text}>The Canary Islands Government</div>
                            </div>
                            <div className={styles.partners_section_item}>
                                <div className={styles.partners_section_img_wrap}>
                                    <img className={styles.partners_section_img} src={ps_5} alt={"ps_5 "}/>
                                </div>
                                <div className={styles.partners_section_item_text}>Cabildo of La Palm</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default S8Partners