import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import PressItem from "../components/PressItem";
import {Link} from "react-router-dom";
import {TicketForm} from "./TicketForm";
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import redsys_img from './images/redsys.png';
import paypal_img from './images/paypal.png';
import Checkbox from "@material-ui/core/Checkbox";
import {TicketView} from "./TicketView";

export class Tickets extends Component {

    constructor(props) {
        super(props);
        props.updateMenuClass('light');
        this.state = {
            action: null,
            ticketsList: [
                // this.getNewTicketData(0)
            ],
            is_agree: false,
            is_agree_error: false,
            payment_methode: 'redsys',
            promo_code: null,
            promo_code_info: null,
            promo_code_discount: 0,
            redsys_form:null
        }

        this.addTicket = this.addTicket.bind(this);
        this.deleteTicket = this.deleteTicket.bind(this);
        this.calcTotalPrice = this.calcTotalPrice.bind(this);
        this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
        this.handleIsAgreeChange = this.handleIsAgreeChange.bind(this);
        this.handlePromoCodeChange = this.handlePromoCodeChange.bind(this);
        this.applyPromoCode = this.applyPromoCode.bind(this);
        this.checkout = this.checkout.bind(this);
        this.startPayment = this.startPayment.bind(this);
        this.initData = this.initData.bind(this);
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    checkout() {
        let is_valid = true;
        var required_fields = [
            'name', 'email', 'phone', 'address'
        ];
        let ticketsList = this.state.ticketsList;
        for (let ticket of ticketsList) {
            let data = ticket.ticket_details;
            data.errors = {};
            for (let field of required_fields) {
                if (!data[field]) {
                    data.errors[field] = true;
                    is_valid = false;
                }
                if (field == 'email' && !this.validateEmail(data[field])) {
                    data.errors[field] = true;
                    is_valid = false;
                }
            }
            if (data.is_all_days != 'yes') {
                data.errors.days = true;
                let is_days_valid = false;
                for (let i in data.days) {
                    if (data.days[i]) {
                        delete (data.errors.days);
                        is_days_valid = true;
                        break;
                    }
                }
                if (!is_days_valid) {
                    is_valid = false;
                }
            }

        }
        this.setState({ticketsList: ticketsList});
        if (!this.state.is_agree) {
            is_valid = false;
        }
        this.setState({is_agree_error: !this.state.is_agree});
        if (is_valid) {
            this.startPayment();
        }
    }

    startPayment() {
        fetch(`${Configs.api_url}payment/start`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                tickets: this.state.ticketsList,
                payment_methode: this.state.payment_methode,
                total_price: this.state.total_price,
                promo_code: this.state.promo_code
            })
        })
            .then(response => response.json())
            .then((data => {
                    let response = data.data;
                    if(data.respcode =='1'){
                        if (response.type == 'form' && response.form) {
                            this.setState({redsys_form:response.form},()=>{
                                setTimeout(()=>{
                                    document.getElementById("frmRedsys").submit();
                                },300)
                            });

                        }
                        if (response.type == 'url' && response.url) {
                            window.location.href=response.url;
                        }else if (response.message) {
                            alert(response.message);
                        }
                    }else {
                        //location.href = '/my-account';
                        // location.href = '/thank-you/';
                    }
                }).bind(this)
            );
    }

    applyPromoCode() {
        fetch(`${Configs.api_url}payment/get-discount`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({code: this.state.promo_code})
        })
            .then(response => response.json())
            .then(data => {
                let promo_code_info, discount;
                discount = data.data.discount;
                if (data.data.discount > 0) {
                    promo_code_info = 'You have ' + discount + '% discount';
                } else {
                    promo_code_info = 'Invalid promo code';
                }
                this.setState({promo_code_info: promo_code_info, promo_code_discount: discount}, () => {
                    this.calcTotalPrice();
                })
            });
    }

    getNewTicketData(index) {
        return {
            ticket_details: {
                name: null,
                email: null,
                phone: null,
                address: null,
                is_student: false,
                is_all_days: 'yes',
                days: {},
                errors: {}
            },
            ticket_index: index
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.action !== this.props.match.params.action) {
            document.getElementById('main').scrollTo(0, 0);
            const action = nextProps.match.params.action;
            this.setState({action: action},()=>{
                this.initData();
            });
        }
    }
    async componentDidMount() {
        const {match: {params: {action,id}}} = this.props;
        this.setState({action: action,payment_id:id}, () => {
           this.initData();
        })

    }

    initData(){
        let action = this.state.action;
        if(action == 'buy'){
            this.addTicket();
        }
        if(action == 'success'){
            this.getTicketsData(this.state.payment_id);
        }
    }
    getTicketsData = (payment_id) => {
        fetch(`${Configs.api_url}payment/get-payment-tickets?hash=`+payment_id)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        successTicketsList: data.data.tickets,
                        successFestDays:data.data.fest_days,
                        successPayment:data.data.payment
                    });
                }
            );
    }

    handlePaymentMethodChange(event) {
        this.setState({payment_methode: event.target.value})
    }

    handleIsAgreeChange(event) {
        this.setState({is_agree: event.target.checked, is_agree_error: !event.target.checked})
    }

    handlePromoCodeChange(event) {
        this.setState({promo_code: event.target.value})
    }

    calcTotalPrice() {
        let sum = 0;
        let tickets_list = this.state.ticketsList;

        tickets_list.map((ticket) => {
            sum += ticket.ticket_details.total_price;
        })
        let discount = this.state.promo_code_discount;
        if (discount > 0) {
            sum -= parseInt(discount) * sum / 100
        }
        this.setState({total_price: sum})
    }

    addTicket() {
        let ticketsList = this.state.ticketsList;
        let new_ticket = this.getNewTicketData(ticketsList.length);
        ticketsList.push(new_ticket);
        this.setState({ticketsList: ticketsList}, () => {
            this.calcTotalPrice();
        });

    }

    deleteTicket(ticket_index) {
        let new_tickets = this.state.ticketsList.filter((ticket) => {
            return ticket.ticket_index != ticket_index
        });
        this.setState({ticketsList: new_tickets}, () => {
            this.calcTotalPrice();
        });

    }

    renderTickets() {
        const tickets_list = [];
        for (var i of this.state.ticketsList) {
            tickets_list.push(<TicketForm key={i.ticket_index} initialData={i} calcTotalPrice={this.calcTotalPrice}
                                          deleteTicket={this.deleteTicket}/>);
        }
        ;
        return tickets_list;
    }

    renderBuy() {

        let tickets_count = this.state.ticketsList.length;
        return (<div className={"am_wrapper"}>
            <div className={"section_light_blue"}>
                <div className={"am_container " + css_classes.light_content}>
                    <div className={css_classes.title}>FESTIVAL ENTRANCE TICKET</div>
                    <div className={"row"}>
                        <div className={"col8"}>
                            {this.renderTickets()}
                            <button className={css_classes.add_ticket_btn} onClick={this.addTicket}> Add Ticket</button>
                        </div>
                        <div className={"col4"}>
                            <div className={"white_bg " + css_classes.padding_24}>
                                <div className={css_classes.title}>Checkout</div>
                                <div className={css_classes.line}></div>
                                <div className={css_classes.text26}>
                                    170€ / day (85€ / day for students)
                                </div>
                                <div className={css_classes.line}></div>
                                <div className={css_classes.checkout_info}>
                                    <span className={css_classes.sub_title}>350€ VIP Tickets </span>
                                    (Concert Once upon a time on the Moon & The Stephen Hawking medal ceremony)
                                </div>
                                <div className={css_classes.line}></div>
                                <div className={css_classes.promocode_wrap}>
                                    <div className={'flex'}>
                                        <input className={css_classes.promocode_input}
                                               onChange={this.handlePromoCodeChange} placeholder={"Promo code"}/>
                                        <button className={css_classes.promocode_button}
                                                onClick={this.applyPromoCode}> Apply
                                        </button>
                                    </div>
                                    {(this.state.promo_code_info) ? <div
                                        className={css_classes.promo_code_info}>{this.state.promo_code_info}</div> : ''}
                                </div>
                                <div className={css_classes.line}></div>
                                <div className={'flex ' + css_classes.tickets_total + ' ' + css_classes.text26}>
                                    <span>TOTAL</span>
                                    <span
                                        className={css_classes.total_tickets_count}>{tickets_count} TICKET {tickets_count > 1 ? "S" : ""}</span>
                                    <span>€ {this.state.total_price}</span>
                                </div>
                                <div className={css_classes.line}></div>
                                <RadioGroup className={css_classes.payment_methods_wrap} aria-label="payment_method"
                                            defaultValue={'redsys'} name="payment_method"
                                            onChange={this.handlePaymentMethodChange}>
                                    <FormControlLabel className={css_classes.payment_methode_label} value="redsys"
                                                      control={<Radio/>} label={<img src={redsys_img}
                                                                                     className={css_classes.payment_methode_logo}/>}/>
                                    <FormControlLabel className={css_classes.payment_methode_label} value="paypal"
                                                      control={<Radio/>} label={<img src={paypal_img}
                                                                                     className={css_classes.payment_methode_logo}/>}/>
                                </RadioGroup>
                                <div className={css_classes.line}></div>
                                <div className={'flex'}>
                                    <Checkbox
                                        className={css_classes.agree_checkbox}
                                        name={'is_agree'}
                                        checked={this.state.is_agree}
                                        onChange={this.handleIsAgreeChange}
                                        inputProps={{'aria-label': 'checkbox'}}
                                    />
                                    <div className={css_classes.agree_wrap}>
                                        I agree with Starmus <a href={'#'} className={"orange_link"}>terms of
                                        service</a> and <a href={'#'} className={"orange_link"}>privacy policy</a>
                                    </div>
                                </div>
                                {(this.state.is_agree_error) ?
                                    <div className={css_classes.is_agree_error}>Please accept</div> : ''}
                                <div className={css_classes.line}></div>
                                <button className={css_classes.checkout_btn} onClick={this.checkout}>Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div dangerouslySetInnerHTML={{__html:this.state.redsys_form}} className={'hidden'}></div>
        </div>);
    }
    renderFail(){
        return <div>apsos</div>;
    }
    renderSuccess(){
        let ticketsList = this.state.successTicketsList;
        if(ticketsList!==undefined){
            // alert(1)
            ticketsList = ticketsList.map((ticket)=>{
                return <TicketView initialData={ticket} festDays={this.state.successFestDays} key={ticket.id} />;
            });
        }
        return <div  className={"am_wrapper"}>
            <div className={'am_container'}>

                <div className={'section_title black'}>Thank you,bla bla</div>
                {ticketsList}
            </div>
        </div>;
    }

    renderPaymentCallback() {
        return '<div>uuups</div>';
    }

    render() {
        if (this.state.action == 'buy') {
            const content = this.renderBuy();
            return (<div>{content}</div>);
        }
        if (this.state.action == 'fail') {
            const content = this.renderFail();
            return (<div>{content}</div>);
        }
        if (this.state.action == 'success') {
            const content = this.renderSuccess();
            return (<div>{content}</div>);
        }

        /*if(this.state.action == 'payment-callback'){
            return this.renderPaymentCallback();
        }*/

        return <React.Fragment>
            <div className={"am_wrapper page_main"}>
                <div className={"header_section " + css_classes.section_1}>
                    <div className={'am_container'}>
                        <div className={'section_title ' + css_classes.section_1_title}>{this.state.action}</div>
                    </div>
                </div>

            </div>
        </React.Fragment>


    }
}
