import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {TemplateSimple} from "../Festival/Components/TemplateSimple";
import TemplateCarousel from "../Festival/Components/TemplateCarousel";
import {TemplateText} from "../Festival/Components/TemplateText";
import TemplateVideo from "../Festival/Components/TemplateVideo";
import {Template2ColumnText} from "../Festival/Components/Template2ColumnText";
import {Template5Img} from "../Festival/Components/Template5Img";
import {PersonSlider} from "../components/PersonSlider";
import section_3_img from "./images/section3.jpg";
import full_program_img from "./images/full_program.jpg";
import ararat from "./images/ararat.png";
import zvartnoc from "./images/zvartnoc.jpg";
import {NewTickets} from "./NewTickets";
import MyModal from "../components/Modal"
import {SponsorForm} from "./SponsorForm";
import {Banners} from "./Banners";
import {Link} from "react-router-dom";
import {Partners} from "./Partners";
import {NewsSlider} from "../components/NewsSlider";
import {FullProgram} from "./FullProgram";


export class StarmusArmenia extends Component {
    constructor() {
        super();
        this.state = {
            isVisiblePartnersModal: false,
            success_send:false
        };
        this.showPartnersModal = this.showPartnersModal.bind(this);
        this.hidePartnersModal = this.hidePartnersModal.bind(this);
    }

    showPartnersModal = () => {
        // document.body.classList.add("overlay");
        document.getElementById("main").classList.remove("wrapper");
        document.body.classList.add('overlay');

        this.setState({ isVisiblePartnersModal: true });
    };

    hidePartnersModal = () => {
        // document.body.classList.remove("overlay");
        document.getElementById("main").classList.add("wrapper");
        document.body.classList.remove('overlay');

        this.setState({ isVisiblePartnersModal: false });
        this.setState({success_send:false});
    };

    componentDidMount() {
        this.getData(6);
    }

    getData(id) {
        // Loader.startLoading();
        fetch(`${Configs.api_url}data/get-festival?id=${id}`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        fest: data.data.fest,
                        content: data.data.content,
                        artists: data.data.artists,
                        speakers: data.data.speakers,
                    })
                }
            );
    }


    renderContent = (content) => {
        return <></>;
        switch (content.template_slug) {
            case 'simple':
                return <TemplateSimple content={content}/>;
                break;
            case 'img_carousel':
                return <TemplateCarousel content={content}/>;
                break;
            case 'text_2_column':
                return <Template2ColumnText content={content}/>;
                break;
            case 'text':
                return <TemplateText content={content}/>;
                break;
            case 'video':
                return <TemplateVideo content={content}/>;
                break;
            case '5_image_grid':
                return <Template5Img content={content}/>;
                break;

        }
        return '';
    };
    openTicketModal(url){
        let model = new NewTickets({});
        model.openTicketModal(url);
        alert(url);
    }
    render() {
        if (this.state.fest !== undefined) {
            // let content = this.state.content.map(this.renderContent);
            return <React.Fragment>
                <div key={this.state.fest.id} className={"am_wrapper page_main new_style"}>
                    <div className={css_classes.section_1}>
                        <div className={css_classes.section_1_1}></div>
                        <div className={css_classes.section_1_2}></div>
                    </div>

                    <TemplateSimple content={this.state.content[0]}/>
                    <div className={'text_center'}>
                        <Link to={"/program"} className={css_classes.new_button}>PROGRAM</Link>
                        <Link to={"/tickets"} className={css_classes.new_button}>BUY TICKET</Link>
                        <Link to={"/city-program"} className={css_classes.new_button}>SIDE EVENTS</Link>
                    </div>
                    <FullProgram/>
                    <Banners/>
                    <div className={'am_container'}>
                        <img src={section_3_img}/>
                    </div>
                    <Template2ColumnText content={this.state.content[1]}/>

                    {/*{content}*/}
                    {this.state.speakers.length > 0 ? <div className={'section_light_blue section_60'}>
                        <div className={'am_container '}>
                            <Link to={'speakers'} className={'section_title black '}>Speakers</Link>
                            <div className={css_classes.person_slider_wrap}>
                                <PersonSlider key={this.state.fest.id} data={this.state.speakers}/>
                            </div>
                        </div>
                    </div> : ''}

                    {this.state.artists.length > 0 ? <div className={' section_60'}>
                        <div className={'am_container '}>
                            <Link to={'/artists'} className={'section_title black '}>Artists</Link>
                            <div className={css_classes.person_slider_wrap}>
                                <PersonSlider key={this.state.fest.id} data={this.state.artists}/>
                            </div>
                        </div>
                    </div> : ''}
                     <NewTickets></NewTickets>
                    <TemplateSimple content={this.state.content[2]}/>
                    <img src={ararat}/>
                    <div className={"am_container"}>
                        <div className={"row"}>
                            <div className={"col6"}>
                                <TemplateText title_align={"left"} content={this.state.content[3]} />
                            </div>
                            <div className={"col6"}>
                                <TemplateText title_align={"left"} content={this.state.content[4]} />
                            </div>
                        </div>
                    </div>
                    <div className={'text_center'}>
                        <a className={css_classes.new_button+' '+css_classes.book_travel_btn} href={"https://Starmus.ArmeniaTravel.am"} target={"_blank"}>Book Travel Service</a>
                    </div>
                    <img src={zvartnoc} className={css_classes.zvartnoc}/>
                    <div className={"am_container"}>
                        <div className={css_classes.partner_section}>
                            <div className={css_classes.partner_section_text}>
                                <div className={css_classes.partner_section_small}>5-10 SEPTEMBER, YEREVAN, ARMENIA</div>
                                <div className={css_classes.partner_section_title+' section_title text_center'}>BECOME A SPONSOR</div>
                                <span onClick={this.showPartnersModal}  className={css_classes.partner_section_button}>FIND OUT MORE</span>
                            </div>

                        </div>
                    </div>
                    <TemplateSimple content={this.state.content[5]}/>
                    <div className={"am_container new_style_btn1"}>
                        <div className={"row"}>
                            <div className={"col6"}>
                                {/*<TemplateText title_align={"left"} content={this.state.content[6]} />*/}
                            </div>
                            <div className={"col6"}>
                                {/*<TemplateText title_align={"left"} content={this.state.content[7]} />*/}
                            </div>
                        </div>
                    </div>
                    <div className={css_classes.soc_links}>
                        <div className={css_classes.follow_us}>FOLLOW US</div>
                        <div className={css_classes.soc_links_links}>
                            <a href={"https://www.facebook.com/StarmusArmenia"} target={"_blank"} className={css_classes.soc_links_a+' '+css_classes.soc_links_a_fb}></a>
                            <a href={"https://www.instagram.com/starmus/"} target={"_blank"} className={css_classes.soc_links_a+' '+css_classes.soc_links_a_instagram}></a>
                            <a href={"https://www.youtube.com/channel/UC9AYS92aEi5oODJ1g1e5Wug"} target={"_blank"} className={css_classes.soc_links_a+' '+css_classes.soc_links_a_youtube}></a>
                            <a href={"https://twitter.com/StarmusArmenia"} target={"_blank"} className={css_classes.soc_links_a+' '+css_classes.soc_links_a_twitter}></a>
                        </div>
                    </div>
                    <div className={'section_60 section_light_blue'}>
                        <div className={'am_container'}>
                            <div className={'section_title black '}>News</div>
                        </div>
                        <div className={css_classes.person_slider_wrap}>
                            <NewsSlider/>
                        </div>
                    </div>
                    <Partners/>
                </div>
                <MyModal show={this.state.isVisiblePartnersModal} handleClose={this.hidePartnersModal}>
                    <SponsorForm  success_send ={this.state.success_send} handleClose={this.hidePartnersModal}/>
                </MyModal>
            </React.Fragment>
        }
        return '';


    }
}
