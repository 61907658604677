import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import partners_classes from "./partners.module.css"
import Configs from "../../configs";
import S7Sponsors from "../components/S7Sponsors";
import InnerHeader from "../components/InnerHeader";
import {Link} from "react-router-dom";
import S7PartnerDesc from "./S7PartnerDesc";

class S7Partners extends Component {


    constructor(props) {
        super();
        this.state = {
            mainPartner:null,
            partners:null
        }
    }
    componentDidMount() {
        this.getData();
        document.getElementById('main').scrollTo(0,0);
    }

    getData() {
        fetch(`${Configs.api_url}data/get-s7-partners`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        mainPartner: data.data.partners[0],
                        partners: data.data.partners.slice(1),
                        logos:data.data.logos
                    });
                }
            );

    }


    render() {
        if(!this.state.mainPartner){
            return '';
        }
        return (
            <div className={"am_wrapper page_main " + css_classes.programContent}>
                <InnerHeader/>
                <div className={css_classes.pItemTitle}>
                    <div className={css_classes.ripa_container+' '+css_classes.pItemTitleInner}>
                        <div className={css_classes.pItemTitleLeft}>
                            PARTNERS & SPONSORS
                        </div>
                        <Link to={'/'} className={css_classes.pItemTitleClose} ></Link>
                    </div>
                </div>
                <div className={css_classes.white_section}>
                    <S7PartnerDesc data={this.state.mainPartner}></S7PartnerDesc>
                </div>
                <div className={partners_classes.p_divider_1}></div>
                <div className={css_classes.white_section}>
                    { this.state.partners.map((item,index)=>{
                        return <S7PartnerDesc data={item}></S7PartnerDesc>;
                    })

                    }

                </div>
                <div className={partners_classes.p_divider_2}></div>
                <div className={css_classes.white_section}>
                    <div className={css_classes.ripa_container}>
                        <div className={css_classes.section_title+' '+css_classes.color_blue+' '+css_classes.section_title_small}>Academic Partners/ Contributors</div>
                        <div className={partners_classes.partner_logos+ ' flex'}>
                            {this.state.logos.map((item,index)=>{
                                return <div className={partners_classes.partner_logos_item}><img src={item.url}/></div>;
                            })}
                        </div>
                    </div>

                </div>
                <div className={partners_classes.p_divider_2}></div>
                <S7Sponsors> </S7Sponsors>
            </div>
        );
    }
}

export default S7Partners;
