import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import partners_classes from "./partners.module.css"
import Configs from "../../configs";
import S7Sponsors from "../components/S7Sponsors";
import InnerHeader from "../components/InnerHeader";
import {Link} from "react-router-dom";
import {VideoModal} from "../../components/VideoBg";
import eset_video_modal_button from "../images/eset_video_btn.png";

class S7PartnerPage extends Component {


    constructor(props) {
        super();
        this.state={
            data:null
        }
        this.id=props.match.params.id;
        this.videoRef = React.createRef();
        this.playVideo = this.playVideo.bind(this);
    }
    playVideo = () => {
        this.videoRef.current.showModal();
    };
    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        this.getData(this.id);
    }

    getData(id) {
        fetch(`${Configs.api_url}data/get-s7-partner?id=`+id)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        data: data.data,
                    });
                }
            );

    }


    render() {
        if(!this.state.data){
            return '';
        }
        return (
            <div className={"am_wrapper page_main " + css_classes.programContent}>
                <InnerHeader/>
                <div className={css_classes.pItemTitle}>
                    <div className={css_classes.ripa_container+' '+css_classes.pItemTitleInner}>
                        <div className={css_classes.pItemTitleLeft}>
                            PARTNERS & SPONSORS
                        </div>
                        <Link to={'/s7-partners'} className={css_classes.pItemTitleClose} ></Link>
                    </div>
                </div>
                <div className={css_classes.section_3}>
                    <div className={css_classes.ripa_container+' '+css_classes.color_white}>
                        <div className={partners_classes.inner_page_title}>
                            <img className={partners_classes.inner_page_logo} src={this.state.data.logo}/>

                            <div className={partners_classes.inner_page_title_type+' '+css_classes.color_blue}>
                                {this.state.data.type}
                            </div>
                        </div>
                        <div className={partners_classes.partner_section}>
                            <div className={partners_classes.partner_section_left}>


                                <div className={partners_classes.partner_text}>
                                    <span dangerouslySetInnerHTML={{__html:this.state.data.text}}></span>
                                </div>
                            </div>
                            <div className={partners_classes.partner_section_img} >
                                <img src={this.state.data.image}/>
                                {this.state.data.video &&
                                    <div>
                                        <img alt={'karap'} className={partners_classes.eset_video_btn} src={eset_video_modal_button} onClick={this.playVideo}/>
                                        <VideoModal ref={this.videoRef} videoSrc={this.state.data.video} fromYoutube={true} />
                                    </div>
                                }
                            </div>

                        </div>
                    </div>

                </div>
                <S7Sponsors></S7Sponsors>
            </div>
        );
    }
}

export default S7PartnerPage;
