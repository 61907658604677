import React, {Component} from "react";
import css_classes from '../style.module.css';

import StarmusSlider from "../../components/Slider/StarmusSlider";

class TemplateCarousel extends Component {
    constructor(props) {
        super();
        this.content = props.content;
    }

    render(props) {
        let files = this.content.files;
        let slides = [];

        this.content.files.forEach((item,index) => (
                slides.push(<div key={'asd_'+index} className={css_classes.template_carousel_item}>

                    {/*<div className={css_classes.template_carousel_title}>*/}
                    {/*    <div className={'am_container section_title section_title_big'}>*/}
                    {/*        {item['title']}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <img src={item['url']}/>
                </div> )
       ))
        return <div className={css_classes.template_carousel_wrap+' '+(this.content.festival_id>=6?' contain_slider':'')}>

            <StarmusSlider>
                {slides}
            </StarmusSlider>
        </div>;
    }
}

export default TemplateCarousel;