import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"


export class QA extends Component {
    constructor(props) {
        super();
        this.state = {activeClasses: []}
        this.toggleAnswer = this.toggleAnswer.bind(this);
    }

    componentDidMount() {

        const {match: {params: {id}}} = this.props;

        fetch(`${Configs.api_url}data/get-qa-list`)
            .then(response => response.json())
            .then(data => {
                let activeClasses=data.data.map(()=> {return false;})
                    this.setState({
                        qa_list: data.data,
                        activeClasses:activeClasses
                    })
                }
            );
    }

    toggleAnswer(elem) {
        let clicked_index=elem.target.getAttribute('data-index');
        let activeClasses=this.state.activeClasses;
        activeClasses.forEach((item,index)=>{
            if(index==clicked_index){
                activeClasses[index]=!item;
            }else{
                // activeClasses[index]=false;
            }
        })
        console.log(activeClasses);
        this.setState({activeClasses:activeClasses});
    }

    render() {
        if (this.state.qa_list !== undefined) {
            let qa_list = [];
            if (this.state.qa_list.length > 0) {
                let index = -1;
                const activeClasses = this.state.activeClasses.slice();
                ;
                qa_list = this.state.qa_list.map(function (qa) {
                    index++;
                    return <div className={css_classes.qa_list_item_wrap}>
                        <div
                            className={css_classes.qa_list_item}>
                            <div  data-index={index} onClick={(e) => this.toggleAnswer(e)} className={'transition '+(activeClasses[index] ? css_classes.qa_list_item_arrow_active : "") + ' ' + css_classes.qa_list_item_arrow}></div>
                            <div data-index={index} className={css_classes.qa_list_item_question}
                                 onClick={(e) => this.toggleAnswer(e)}>{qa.question}</div>
                            <div className={'transition '+(activeClasses[index] ? css_classes.qa_list_item_answer_active : "") + ' ' + css_classes.qa_list_item_answer}
                                 dangerouslySetInnerHTML={{__html: qa.answer}}></div>
                        </div>
                    </div>
                }, this)
            }
            return <React.Fragment>
                <div className={"am_wrapper page_main"}>
                    <div className={'header_section ' + css_classes.header_section}>
                        <div className={'am_container'}>
                            <div className={"section_title"}>QA</div>
                        </div>
                    </div>
                    <div className="section_60">
                        <div className={'am_container'}>
                            <div className={css_classes.qa_list }>
                                {qa_list}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }
        return '';


    }
}
