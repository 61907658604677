import React, {Component} from "react";
import {Element} from 'react-scroll'

import MainBanner from './components/MainBanner'
class LandingPage extends Component {
  render() {
    return (
      <React.Fragment>

          <Element name="mars-main-banner">
            <MainBanner/>
          </Element>
      </React.Fragment>
    )
  };
}

export default LandingPage;