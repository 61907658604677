import styles from "../style.module.css";
import PreRegForm from "./preRegForm"
import React from 'react';

export default function Section7(props) {
    return (
        <>
            <div className={styles.section_7}>
                <div className={"am_container "+styles.section_6_content}>
                    <div className={"flex"}>
                        <div className={"flex_half half_769"}>
                            <div className={styles.title+" "+styles.section_7_title}>STARMUS is coming again … to Rock the Planet!</div>
                            <div className={styles.section_7_row}>Will AI and Quantum Computers take over the World?</div>
                            <div className={styles.section_7_row}>Will technology save or destroy our planet?</div>
                            <div className={styles.section_7_row}>Can climate and environmental crisis cause mass extinction?</div>
                            <div className={styles.section_7_row}>Can cyberattack trigger the final war and will humanity survive it?</div>
                            <div className={styles.section_7_row}>Can life be ethically recreated on Earth?</div>
                            <div className={styles.section_7_reg_text}>Subscribe to the "STARMUS Spotlight" newsletter series and get ready to be inspired as we shine a spotlight on the latest updates, exclusive content, upcoming events, remarkable individuals, and captivating stories that make STARMUS truly special.</div>
                            <button className={"s7_button"} onClick={()=>{props.openPopup(<PreRegForm></PreRegForm>)}}>Subscribe</button>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}