import React, {Component} from 'react';


class LocationBlock extends Component {

  imgBlockHtml = (className) => {
    return <div className={`location-block-image ${className}`}>
      <picture>
        <source srcSet={this.props.imageSrc375} media="(max-width: 464px)"/>
        <img className='location-img' src={this.props.imageSrc} alt='Location Armenia'/>
      </picture>
    </div>;
  };

  textBlockHtml = (className) => {
    return <div className={`location-block-text ${className}`}>
      <h1 className="location-h1 starmus-black-h1">{this.props.title}</h1>
      {this.props.text.map(value => (
        <div className='location-text-wrapper'>
          <p className="location-p starmus-black-p" dangerouslySetInnerHTML={{__html:value}}></p>
        </div>
      ))}
    </div>;
  };

  render() {
    let image;
    let content;

    image = this.imgBlockHtml('');
    content = this.textBlockHtml('');
console.log(content)

    return (
      <div className={"location-block "+(this.props.imageLeft?"revers":'')}>
        <div className="location-block-wrap">
          {image}
          {/*<div dangerouslySetInnerHTML={{__html:content}}></div>*/}
          {content}
        </div>
      </div>
    )
  }
};

export default LocationBlock;