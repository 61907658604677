import React, {Component} from 'react';
import {Player, ControlBar, BigPlayButton} from 'video-react';
import "video-react/dist/video-react.css"
import "./index.css"

import play from '../../assets/icons/play@3x.png'
import YouTube from "react-youtube";

class VideoModal extends Component {
    constructor(props) {
        super(props);
        this.fromYoutube = this.props.fromYoutube || false
    }
    state = {show: false};

    showModal = () => {
        document.getElementById("main").classList.remove("wrapper");
        this.setState({show: true})
    };

    hideModal = () => {
        if (this.state.show !== false) {
            this.setState({show: false});
            document.getElementById("main").classList.add("wrapper");
        }
    };

    render() {
        if (!this.state.show) {
            return null;
        }
        const opts = {
            playerVars: {
                autoplay: 1,
                rel:0,
            },
        };
        let video;
        if(this.fromYoutube){
             video = <YouTube containerClassName={'modal_video_bg_iframe_wrapper'} videoId={this.props.videoSrc} opts={opts}  />;
        }else{
             video = <Player autoPlay isFullscreen={false}>
                <BigPlayButton position="center"/>
                <source src={this.props.videoSrc}/>
                <ControlBar autoHide={false}/>
            </Player>
        }
        return (
            <div className='video_modal'>
                <div className='video_modal_video'>
                    {video}

                </div>
                <a href="javascript:void(0)" className="video_modal_close_button" onClick={this.hideModal}/>
            </div>
        )
    }
}

class VideoBg extends Component {
    constructor(props) {
        super(props);
        this.videoBgClassname = this.props.videoBgClassname || '';
        this.videoType = this.props.videoType || 'video/mp4';
        this.fromYoutube = this.props.fromYoutube || false
    }

    render() {
        const opts = {
            playerVars: {

                // https://developers.google.com/youtube/player_parameters
                loop:1,
                mute:1,
                autoplay: 1,
                rel:0,
                controls:0
            },
        };
        if (this.fromYoutube) {
            return (
                <YouTube containerClassName={'video_bg_iframe_wrapper'} className={'video_bg_iframe'} videoId={this.props.videoSrc} opts={opts}  />
            );
        } else {
            return (
                <video className={`video ${this.videoBgClassname}`} autoPlay={true} loop muted width='100%'>
                    <source src={this.props.videoSrc} type={this.videoType}/>
                </video>
            );
        }

    }
}

class VideoPlayButton extends Component {

    render() {
        return (
            <div className='watch_video_button_block'>
                <div className='play_video_oval' onClick={this.props.playClick}>
                    <img src={play} className='play' alt='Play video'/>
                    <p className='play_video_watch_video starmus-p'>Watch video</p>
                </div>
            </div>
        )
    }
};

export {VideoModal, VideoPlayButton, VideoBg};