import React, {Component} from "react";
import Configs from "../configs";
import css_classes from "./style.module.css"
import {Section1} from "./Components/Section1";
import {TemplateSimple} from "./Components/TemplateSimple";
import TemplateCarousel from "./Components/TemplateCarousel";
import {TemplateText} from "./Components/TemplateText";
import TemplateVideo from "./Components/TemplateVideo";
import {Template2ColumnText} from "./Components/Template2ColumnText";
import {PersonSlider} from "../components/PersonSlider";
import {Template5Img} from "./Components/Template5Img";


export class Festival extends Component {
    constructor(props) {
        super();
        this.state = {}
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.id !== this.props.match.params.id) {
            const id = nextProps.match.params.id;
            this.getData(id);
        }
    }
    componentDidMount() {

        const {match: {params: {id}}} = this.props;
        this.getData(id);

    }
    getData(id){
        // Loader.startLoading();
        // window.Loader.startLoading();
        fetch(`${Configs.api_url}data/get-festival?id=${id}`)
            .then(response => response.json())
            .then(data => {
                    // window.Loader.stopLoading();
                    this.setState({
                        fest: data.data.fest,
                        content: data.data.content,
                        artists: data.data.artists,
                        speakers: data.data.speakers,
                    })
                }
            );
    }

    renderContent = (content) => {
        switch (content.template_slug) {
            case 'simple':
                return <TemplateSimple content={content}/>;
                break;
            case 'img_carousel':
                return <TemplateCarousel content={content}/>;
                break;
            case 'text_2_column':
                return <Template2ColumnText content={content}/>;
                break;
            case 'text':
                return <TemplateText content={content}/>;
                break;
            case 'video':
                return <TemplateVideo content={content}/>;
                break;
            case '5_image_grid':
                return <Template5Img content={content}/>;
                break;

        }
        return '';
    };

    render() {
        if (this.state.fest !== undefined) {
            let content = this.state.content.map(this.renderContent);
            return <React.Fragment>
                <div key ={this.state.fest.id} className={"am_wrapper page_main"}>
                    <Section1  fest={this.state.fest}/>

                    {content}
                    {this.state.speakers.length>0? <div className={'section_light_blue section_60'}>
                        <div className={'am_container '}>
                            <div className={'section_title black '}>SPEAKERS</div>
                            <PersonSlider key={this.state.fest.id} data={this.state.speakers}/>
                        </div>
                    </div>:''}

                    {this.state.speakers.length>0? <div className={' section_60'}>
                        <div className={'am_container '}>
                            <div className={'section_title black '}>Artists</div>
                            <PersonSlider  key={this.state.fest.id} data={this.state.artists}/>
                        </div>
                    </div>:''}
                </div>
            </React.Fragment>
        }
        return '';


    }
}
