import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class TemplateSimple extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }
    render() {
        return <div className={'am_container section_60 '+css_classes.template_1_wraper}>
            <div className={css_classes.template_1_left}>
                <div className={"section_title black "+ css_classes.template_1_title}>
                    {this.content.title}
                    <span className={"section_title puf"}> {this.content.sub_title}</span>
                </div>
                <div className={"section_content"} dangerouslySetInnerHTML={{__html:this.content.text}}>
                </div>
            </div>
            <div className={css_classes.template_1_right}>
                <img src={this.content.file}/>
            </div>
        </div>
    }
}