import React,{Component} from "react";
import css_classes from './style.module.css'
import {Link} from "react-router-dom";
export class BannerItem extends Component{
    constructor(props) {
        super();
        this.data=props.data;
        this.style=props.style;
    }
    render() {
        const description =(this.data.description)?<div className={css_classes.banner_item_desc} dangerouslySetInnerHTML={{__html: this.data.description}}></div>:'';
        const location = (this.data.location)?<div className={css_classes.banner_item_location}>{this.data.location}</div>:'';
        return <Link to ={'/banner-content/'+this.data.slug} className={css_classes.banner_item_inner }>
            <img src={this.data.banner_image} className={css_classes.banner_item_image}/>
            <div className={css_classes.banner_item_date}>
                <div className={css_classes.banner_item_day}>{this.data.date_part}</div>
                <div className={css_classes.banner_item_month} dangerouslySetInnerHTML={{__html:this.data.month_part}}></div>
            </div>
            <div className={css_classes.banner_item_info+' '+(this.style?css_classes[this.style]:'')}>
                <div className={css_classes.banner_item_title}>{this.data.title}</div>
                {description}
                {location}
                {this.style=='city_program_style'?<img className={css_classes.city_program_logo} src={this.data.cover_image}/>:''}
            </div>
        </Link>
    }
}