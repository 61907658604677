import React, {Component} from "react";
import css_classes from '../style.module.css';

import StarmusSlider from "../../components/Slider/StarmusSlider";

class Section7 extends Component {
    constructor(props) {
        super();
        this.data= props.data;
    }

    render(props) {
        let slides = [];
        for(let i=-7;i<=20;i++){
                slides.push()
        }
        return <div className={css_classes.section_7_slider+' contain_slider'}>

            <StarmusSlider>
                {this.data.files.map((item,index)=> {
                    return <img src={item.url} alt={'wmc_'+index}/>
                })}
            </StarmusSlider>
        </div>;
    }
}

export default Section7;