import React, {Component} from "react";
import css_classes from '../style.module.css';
class Section1 extends Component{
    info_list=[
        'Our aim is to inspire and educate the next generation of explorers and regenerate the spirit of discovery',
        'Starmus is a global festival of science communication and art that brings together the most brilliant minds on the planet',
        'Starmus combines art, music and science to enhance the science communication.',
        'We want to engage humanity in the biggest questions of our time'
    ];
    constructor(props) {
        super();
        this.name=props.name
    }
    render(props){
        let section_info =[];
        this.info_list.map((e)=>(section_info.push(<div className={css_classes.section_info_list_item}>{e}</div> )))
        return <div className={css_classes.section1}>
            <div className={"am_container "+css_classes.section1_inner}>
                <div className={css_classes.section1_inner_title}>

                </div>
                <div className={css_classes.section_info_list}>
                    <div className={"section_title section_title_57"}>WHAT IS STARMUS?</div>
                    {section_info}
                </div>
            </div>
        </div>;
    }
}
export default Section1;