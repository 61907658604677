import React, {Component} from "react";
import css_classes from './style.module.css'
import "./program_style.css";
import Configs from "../configs";
import {ProgramItem} from "./ProgramItem";
import {BannerItem} from "./BannerItem";
import city_program_img from "./images/city_program_img.png"
import {Link} from "react-router-dom";

export class CityProgram extends Component {
    constructor(props) {
        super();
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        document.getElementById('main').scrollTo(0,0);
        this.getData();
    }

    getData() {
        // Loader.startLoading();
        fetch(`${Configs.api_url}data/get-city-program`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        data: data.data,
                    })
                }
            );
    }

    render() {
        if (this.state.data.length == 0) {
            return '';
        }
        let list = [];
        let index='';
        for(let key in this.state.data) {
            let value = this.state.data[key]
            let banner = <div className={css_classes.banner_item_wrap}>
                <BannerItem key={'bi_' + index} data={value} style={'city_program_style'}/></div>;
            list.push(banner)
            index++;
        }

        return (<React.Fragment>
            <div className={"am_wrapper page_main new_style"}>

                <div className={'header_section contact_header_section'}>
                    <div className={'am_container'}>
                        <div className={"section_title"}>Starmus Armenia Outreach Program</div>
                    </div>
                </div>
                <div className={css_classes.banners_list+' border_box'}>
                    {list}
                </div>
            </div>
        </React.Fragment>);
    }
}