import React, {Component} from 'react'

import '../css/mystery_of_red_planet.css'


class MysteryOfRedPlanet extends Component {
  render() {
    return (
      <section className='mystery-of-red-planet-section'>
        <div className='container'>
          <div className="mystery-of-red-planet-block starmus-block">
            <div className="mystery-of-red-planet-block-text">
              <h1 className="mystery-of-red-planet-title starmus-h1">
                Explore the mysteries of the Red Planet
              </h1>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default MysteryOfRedPlanet