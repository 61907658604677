import React, {Component} from 'react';
import MainMenu from "../../components/Menu";
import video from "../../assets/videos/armenia.mp4";
import '../css/location_main_banner.css';
import {VideoBg, VideoModal, VideoPlayButton} from "../../components/VideoBg";
import armeniaPoster from "../../assets/images/poster_vi.jpg";

class LocationMainBanner extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.playVideo = this.playVideo.bind(this);
  }

  playVideo = () => {
    this.videoRef.current.showModal();
  };

  render() {
    return (
      <section className="location-section">
        <div className='container'>
          <div className='armenia_video_block'>
            <VideoModal ref={this.videoRef} videoSrc={video}/>
            <VideoBg videoSrc={video}/>
            <div className="armenia_video_block_text">
              <div className="starmus-location-p starmus-date-location starmus-p">
                SEPTEMBER 5-10 2022, <span className="starmus-about-highlighted">YEREVAN, ARMENIA</span>
              </div>
              <h1 className='starmus-h2'>STARMUS VI</h1>
              <h1 className='starmus-h2'>This is Armenia</h1>
              <VideoPlayButton playClick={this.playVideo}/>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='armenia_poster_block'>
            <div>
              <h1 className='starmus-black-h1'>Welcome to Armenia</h1>
            </div>
            <div>
              <picture>
                <source srcSet={armeniaPoster} media="(max-width: 464px)"/>
                <img className='location-img' src={armeniaPoster} alt='Poster Armenia'/>
              </picture>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default LocationMainBanner;