import React, {Component} from 'react';
import css_classes from "../inner.module.css"
import partners_classes from "./partners.module.css"
import {Link} from "react-router-dom";

class S7PartnerDesc extends Component {


    constructor(props) {
        super();
        this.data= props.data;
    }
    componentDidMount() {
    }

    getData() {


    }
    openPartner(data){

    }


    render() {
        return (
            <div className={css_classes.ripa_container}>
                <div className={partners_classes.partner_section}>
                    <div className={partners_classes.partner_section_left}>

                        <div className={css_classes.section_title+' '+css_classes.color_blue}>
                            {this.data.type}
                        </div>
                        <img className={partners_classes.partner_logo} src={this.data.logo}/>
                        <div className={partners_classes.partner_text}>
                            <span dangerouslySetInnerHTML={{__html:this.data.text.substring(0,this.data.desc_length)}}></span>
                            <Link to={'/s7-partner/'+this.data.id} className={css_classes.color_blue} > more...</Link>
                        </div>
                    </div>
                    <div className={partners_classes.partner_section_img} >
                        <img src={this.data.image}/>
                    </div>

                </div>
            </div>
        );
    }
}

export default S7PartnerDesc;
