import React,{Component} from "react";
import Configs from "../configs";
import success_icon from "./images/success_icon.png"

export class SponsorForm extends Component {
    constructor(props){
        super(props);
        this.handleClose=props.handleClose;
        this.state = {
            fields: {},
            errors: {},
            success_message:props.success_send
        }
    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["company"]){
            formIsValid = false;
            errors["company"] = "Cannot be empty";
        }
        if(!fields["name"]){
            formIsValid = false;
            errors["name"] = "Cannot be empty";
        }

        //Email
        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }
        if(typeof fields["email"] !== "undefined"){
            if(!fields["email"].match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/)){
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }
        if(!fields["phone_number"]){
            formIsValid = false;
            errors["phone_number"] = "Cannot be empty";
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    contactSubmit(e){
        e.preventDefault();
        if(this.handleValidation()){
            this.setState({success_message:'Success sent',errors:{},fields:{name:'',email:'',subject:'',message:''}});
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(this.state.fields)
            };
            fetch(`${Configs.api_url}mailer/to-sponsor`, requestOptions)
                .then(response => response.json())
                .then(data => this.setState({ postId: data.id }));
        }else{
            this.setState({success_message:false});
        }

    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    render(){
        return (
                (!this.state.success_message)?(
                <form name="contactform" className="sponsorsForm" onSubmit= {this.contactSubmit.bind(this)}>
                    <div className={"section_title"}>Become A Sponsor</div>
                    <div className={"sponsorsForm_text"}>For sponsorship opportunities, please fill out the form
                        bellow to connect with the Starmus team</div>
                    <input className={this.state.errors["company"]?'error':''} type="text" size="30" placeholder="Company *" onChange={this.handleChange.bind(this, "company")} value={this.state.fields["company"]}/>
                    <input className={this.state.errors["name"]?'error':''} type="text" size="30" placeholder="Name *" onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"]}/>
                    <input className={this.state.errors["email"]?'error':''} type="text" size="30" placeholder="Email *" onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/>
                    <input className={this.state.errors["phone_number"]?'error':''}  type="text" size="30" placeholder="Phone number *" onChange={this.handleChange.bind(this, "phone_number")} value={this.state.fields["phone_number"]}/>

                    <input type={"submit"} className={'submit_button'} value={"Send"}/>



                </form>):( <div className="sponsorsForm form_success">
                    <div className={"section_title"}>Our team will contact you shortly!</div>
                    <div className={"success_icon_wrap"}>
                        <img src={success_icon} />
                        <div className={"sponsorsForm_text"}>The form was successfully sent!</div>
                    </div>

                    <input type={"submit"} onClick={this.handleClose} value={"CLOSE"}/>
                </div>)

        )
    }
}
