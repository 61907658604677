import React, {Component} from "react";
import css_classes from '../style.module.css';
import {VideoBg, VideoModal, VideoPlayButton} from "../../components/VideoBg";
import longVideo from "../../assets/videos/Starmus_long_video.mp4";
import shortVideo from "../../assets/videos/Starmus_short_video.mp4";
import ScrollDown from "../../components/Scroll/ScrollDown";
class TemplateVideo extends Component{
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.playVideo = this.playVideo.bind(this);
        this.content = props.content
    }

    playVideo = () => {
        this.videoRef.current.showModal();
    };
    render(props){
        if(0 && this.content.festival_id==6 ){
            return <section className='mars-video-section'>
                <div className='container'>
                    <VideoModal ref={this.videoRef} videoSrc={longVideo}/>
                    <div className='mars_video_block'>
                        <VideoBg videoSrc={shortVideo}/>
                        <div className="mars_video_block_text">
                            <h1 className='mars_video_h1 starmus-h2'>FROM STARMUS TO MARS</h1>
                            <p className='mars_video_block_description starmus-p'>The sixth Starmus will be dedicated to Mars</p>
                            <VideoPlayButton playClick={this.playVideo}/>
                        </div>
                    </div>
                    <ScrollDown to='mars-facts-section' containerId="main"/>
                </div>
            </section>
        }
        let video=this.content.text;
        return <div className={"video_bg_section " + css_classes.template_video_wrap}>
            <div className={"video_bg_button"}>
                <div className={"section_title " }>{this.content.title}</div>
                {/*<div className={}></div>*/}
                <VideoPlayButton playClick={this.playVideo}/>
            </div>
            <VideoBg videoSrc={video} fromYoutube={true}/>
            <VideoModal ref={this.videoRef} videoSrc={video} fromYoutube={true} />

        </div>
    }
}
export default TemplateVideo;