import React, {Component} from 'react'
import ScrollDown from "../../components/Scroll/ScrollDown";
import '../css/missions_to_mars.css'

class MissionsToMars extends Component {
  render() {
    return (
      <section className="section parallax missions-section">
        <div className='container'>
          <div className="missions-block starmus-block">
            <div className="missions-block-text">
              <h1 className="starmus-h2">
                Missions to Mars
              </h1>
              <div className='missions-text'>
                <p className='starmus-p'>
                  Beginning with the USSR’s Marsnik 1 in 1960, only <span>26</span> of
                  the <span>56</span> Mars missions have been successful.
                </p>
                <p className='starmus-p'>
                  This shows how difficult it is to send missions to the Red Planet. But humanity strives
                  to overcome that challenge.
                </p>
                <p className='starmus-p'>
                  The four rovers (NASA/JPL, USA) that have already gone to Mars are: Sojourner, Spirit,
                  Opportunity and Curiosity.
                </p>
              </div>
            </div>
            <ScrollDown to='mars3-section' containerId="main"/>
          </div>
        </div>
      </section>
    )
  }
}

export default MissionsToMars