import React,{Component} from "react";
import css_classes from '../style.module.css'
export class Section1 extends Component{
    constructor(props) {
        super();
        this.fest = props.fest;
        console.log(this.fest);
    }
    render() {
        return <div className={'section_with_bg '+css_classes.section_1}>

            <img className={'img_as_bg'} src={this.fest.image}/>
            <div className={'flex am_container height_100 '+css_classes.fest_header}>
                <div className={"flex align_items_center "+css_classes.fest_header_left}>
                    <div>
                        <div className={"section_title section_title_big"}>{this.fest.title}</div>
                        <div className={"section_title festival_slogan_"+this.fest.id} dangerouslySetInnerHTML={{__html:this.fest.slogan}}></div>
                    </div>
                </div>
                <div className={"flex align_items_bottom "+css_classes.festival_date_place}>
                    <div>{this.fest.date}, {this.fest.year}</div>
                    <div className={css_classes.festival_place}>{this.fest.place}</div>

                </div>
            </div>
        </div>
    }
}