import React,{Component} from "react";
import css_classes from '../style.module.css';

export  class PageTemplate4 extends Component{

    constructor(props) {
        super();
        this.content = props.content;
    }
    render() {
        return <div className={css_classes.black_bg_section + ' ' + css_classes.flex_column+' '+css_classes.h100}>
            <div className={css_classes.h100+' '+css_classes.template_4_title_bg+' '+css_classes.centered_info_wrap} style={{backgroundImage:`url(${this.content.image})`}}>

                <div className={'text_center ' + css_classes.title + ' ' + css_classes.template_4_text_title}   dangerouslySetInnerHTML={{__html: this.content.title}}></div>
            </div>
            <div className={'am_container'}>

                <div className={css_classes.template_4_text_container}
                     dangerouslySetInnerHTML={{__html: this.content.content}}>
                </div>

            </div>
        </div>
    }
}