import React, {Component} from "react";
import css_classes from './style.module.css'
import "./program_style.css";
import Configs from "../configs";
import {ProgramItem} from "./ProgramItem";
import {BannerItem} from "./BannerItem";
import city_program_img from "./images/city_program_img.png"
import {Link} from "react-router-dom";

export class Banners extends Component {
    constructor(props) {
        super();
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        // Loader.startLoading();
        fetch(`${Configs.api_url}data/get-banners`)
            .then(response => response.json())
            .then(data => {
                    this.setState({
                        data: data.data,
                    })
                }
            );
    }

    render() {
        if (this.state.data.length == 0) {
            return '';
        }
        let list = [];
        let index='';
        for(let key in this.state.data) {
            let value = this.state.data[key]
            let banner = <div className={css_classes.banner_item_wrap}><BannerItem key={'bi_' + index} data={value}/></div>;
            list.push(banner)
            index++;
        }

        return (<React.Fragment>
            <div className={css_classes.banners_list+' border_box'}>
                {list}
                <div className={css_classes.banner_item_wrap}>
                    <Link to={"/city-program"} className={css_classes.banner_item_inner}>
                        <img src={city_program_img}/>
                        <div className={css_classes.banner_item_info+' '+css_classes.city_program_title}>
                            <div className={css_classes.banner_item_title}>Starmus Armenia Outreach Program</div>
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>);
    }
}