import React,{Component} from "react";
import css_classes from '../style.module.css';
import styles from "../../Starmus8/style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
import Configs from "../../configs";

export  class Partners extends Component{

    constructor(props) {
        super();
        this.festival_id = props.festival_id;
        this.state = {pagePartners:[]}
    }
    componentDidMount() {
        this.getData(this.festival_id)
    }
    getData(festival_id){
        // Loader.startLoading();
        // window.Loader.startLoading();
        fetch(`${Configs.api_url}data/get-page-partners?festival_id=${festival_id}`)
            .then(response => response.json())
            .then(data => {

                    // window.Loader.stopLoading();
                    this.setState({
                        pagePartners:data
                    })
                }
            );
    }
    renderPartnerGroup(group,index){
        return <div className={css_classes.partnersGroupItem}>
            <div className={css_classes.partnersGroupItemInner}>
                <div className={css_classes.partnersGroupName}>{group.group} </div>
                <div className={css_classes.partnersGroupLogos}>
                    {group.partners.map((partner) => {
                        return <img className={css_classes.partnerLogo} src={partner.logo}/>
                    })}
                </div>
            </div>

        </div>
    }

    render() {
        return <div className={css_classes.partners_wrap+' '+css_classes.page}>
            <div className={'am_container'}>
                {this.state.pagePartners.length && <div className={css_classes.partnersGroups}>
                    {this.state.pagePartners.map(this.renderPartnerGroup)}
                </div>}
            </div>
        </div>
    }
}