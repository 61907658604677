import React, {Component} from 'react'
import {Link} from 'react-scroll'

import './scroll-down.css'

class ScrollDown extends Component {
  render() {
    return (
      <div className='scroll-item-container'>
        <div className='scroll-item'>
          <Link activeClass="active" to={this.props.to} spy={true} smooth={true} duration={500}
                containerId={this.props.containerId}>
            <img src={require("../../assets/icons/computing.png")} alt='Scroll Down'/>
          </Link>
          <div className='scroll-down'>Scroll down</div>
        </div>
      </div>

    )
  }
}

export default ScrollDown